import React from "react"
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap"
import { isEmpty, map } from "lodash"
import moment from 'moment'
import SimpleBar from "simplebar-react"

const Activities = (props) => {
  const { userLogs } = props


  return (
    <>
      <Card style={{ minHeight: "500px", maxHeight: "500px", overflow: "auto" }}>
        <CardBody>
          <CardTitle className="mb-4">Logs</CardTitle>
          {userLogs?.length != 0 ?

            <div>
              <SimpleBar style={{ maxHeight: "400px" }}>
                <ul className="verti-timeline list-unstyled">
                  {map(userLogs, (log, index) => (
                    <li key={index} className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="media">
                        <div className="mr-3">
                          <div className="tooltip_custom" >
                            <small >
                              {moment(log.date).format("DD MMM")}{" "}{" "}</small>
                            <span className="tooltiptext">{moment(log.date).format("DD MMM YYYY, h:mm:ss a")}</span>
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2" />
                          </div>
                        </div>
                        <div className="media-body">
                          <div><b>{log.action}</b> <br></br>
                            <small><i className="bx bx-time" /> {moment(log.date).format("h:mm:ss a")} &nbsp; &nbsp; {" "}

                              {!isEmpty(log.userId) &&
                                <>
                                  <span>by:</span> {log.userEmail}
                                </>
                              }


                            </small> <br></br>
                            {log.email &&

                              <>

                                {log.email?.isSuccessful === true ?

                                  <small className="list-inline-item text-muted mb-1"><b style={{ "color": "#34C38F" }}>Successfully Notified: </b></small>
                                  :
                                  <small className="list-inline-item text-muted mb-1" ><b style={{ "color": "#E5006B" }}>Email Not Sent: </b></small>
                                }

                                <small>TO: {log.email?.to} <br></br>CC: {log.email?.cc?.join(' ')}</small>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </SimpleBar></div>


            :
            <Row>
              <Col>
                <p>No payment log.</p>
              </Col>
            </Row>

          }


        </CardBody>
      </Card>
    </>
  )
}


export default Activities
