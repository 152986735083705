import React, { useState, useEffect } from "react"
import CreditCard from '../CardInput/CreditCard'
import DebitCard from '../CardInput/DebitCard'
import Ooredoo from '../CardInput/Ooredoo'
import Paypal from '../CardInput/Paypal'
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,

} from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import CardForm from "../CardInput/CardForm"
import CardForm_MF from "../CardInput/CardForm_MyFatoorahEmbedded"
import CardForm_MyFatoorahEmbedded from "../CardInput/CardForm_MyFatoorahEmbedded"

const PaymentMethodCardForm = (props) => {


  const { tokenizationDetails, 
    paymentRequestDetails, 
    retrievedTokenDetailsCustom, 
    retrievedTokenDetailsRedirect,
    myfatoorahInitiateSession, } = props


  const [activeTabJustify, setactiveTabJustify] = useState("5")

  const [showInformation, setShowInformation] = useState(false)

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }

useEffect(() => {

}, [showInformation])
  
  return (
    <>



      <Col lg={12}>

        {props.paymentRequestDetails != null && props.paymentRequestDetails?.generatedLinkStatus === "Active" &&

         <>

              <Nav tabs className="nav-tabs-custom nav-justified">


                {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("credit") &&


                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "5",
                      })}
                      onClick={() => {
                        toggleCustomJustified("5")
                      }}
                    >
                      <span>{props.t("CREDIT CARD")}</span>
                    </NavLink>
                  </NavItem>
                }



                {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("debit") &&


                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "6",
                      })}
                      onClick={() => {
                        toggleCustomJustified("6")
                      }}
                    >
                       <span onMouseOver={() => setShowInformation(true)} onMouseOut={() => setShowInformation(false)} >{props.t("NAPS DEBIT CARD")} </span>
                    </NavLink>

                    {showInformation && <small className="color-danger">{props.t("Qatar issued bank cards")}</small>}

                  </NavItem>
                }


                {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("ooredoo") &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "7",
                      })}
                      onClick={() => {
                        toggleCustomJustified("7")
                      }}
                    >
                      <span>OOREDOO</span>
                    </NavLink>
                  </NavItem>
                }



                {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("paypal") &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "8",
                      })}
                      onClick={() => {
                        toggleCustomJustified("8")
                      }}
                    >
                      <span >PAYPAL</span>
                    </NavLink>
                  </NavItem>
                }



              </Nav>


              <TabContent activeTab={activeTabJustify}>

                {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("credit") &&
                  <TabPane tabId="5" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          {/* <CreditCard/> */}

                          {myfatoorahInitiateSession &&
                          
                          <CardForm_MyFatoorahEmbedded
                          paymentRequestDetails={paymentRequestDetails}
                          myfatoorahInitiateSession ={myfatoorahInitiateSession}
                          />
                          
                          }

                          {/* {retrievedTokenDetailsCustom &&
                          <CardForm
                            tokenizationDetails={tokenizationDetails}
                            paymentRequestDetails={paymentRequestDetails}
                            retrievedTokenDetailsCustom={retrievedTokenDetailsCustom}
                            retrievedTokenDetailsRedirect={retrievedTokenDetailsRedirect} />
                          } */}
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                }

                {props.paymentRequestDetails?.paymentRequestSettings.paymentType?.toLowerCase().includes("debit") &&
                  <TabPane tabId="6" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          <DebitCard retrievedTokenDetailsRedirect={retrievedTokenDetailsRedirect}/>
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>}

                {props.paymentRequestDetails?.paymentRequestSettings.paymentType?.toLowerCase().includes("ooredoo") &&
                  <TabPane tabId="7" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          <Ooredoo />
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>}

                {props.paymentRequestDetails?.paymentRequestSettings.paymentType?.toLowerCase().includes("paypal") &&
                  <TabPane tabId="8" className="p-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          <Paypal />
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>}
              </TabContent>

           </>
        }

        {props.paymentRequestDetails != null && props.paymentRequestDetails?.generatedLinkStatus === "Inactive" &&

          <Card>
            <CardBody>
              <p><b>Payment Status : </b></p>
              {props.paymentRequestDetails?.paymentStatus}

            </CardBody>
          </Card>
        }
      </Col>


    </>
  )
}


PaymentMethodCardForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(PaymentMethodCardForm))


// IMPORTANT NOTE:

// INACTIVE LINK 
