import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  SET_PAYMNET_GATEWAY,
  GET_PAYMENT_GATEWAY_LIST,
  UPDATE_PAYMENT_GATEWAY,
  UPDATE_STATUS_CHANGE,
  GET_PAYMENT_GATEWAY_DETAIL
} from "./actionTypes"

import {
  setPaymentGatewaySuccess,
  setPaymentGatewayFail,
  getPaymentGatewayListSuccess,
  getPaymentGatewayListFail,
  updatePaymentGatewaySuccess,
  updatePaymentGatewayFail,
  updateStatusChangeSuccess,
  updateStatusChangeFail,
  getPaymentGatewayDetailSuccess,
  getPaymentGatewayDetailFail,
  restartState
} from "./actions"

//Include Both Helper File with needed methods
import {
  setPaymentGateway,
  getPaymentGatewayList,
  updatePaymentGateway,
  getPaymentGatewayDetail
} from "helpers/fakebackend_helper"

function* _setPaymentGateway({ payload: paygate }) {
  try {
    const response = yield call(setPaymentGateway, paygate)
    
    if (response) {
      yield put(setPaymentGatewaySuccess(response))
    } else {
      yield put(setPaymentGatewayFail(response))
    }
    yield put(restartState())
  }
  catch (error) {
    yield put(setPaymentGatewayFail(response))
  }
}
function* _getPaymentGatewayList({ payload: paygate }) {
  try {
    const response = yield call(getPaymentGatewayList, paygate)
    if (response) {
      yield put(getPaymentGatewayListSuccess(response))
    }
    else {
      yield put(getPaymentGatewayListFail(response))
    }
  }
  catch (error) {
    yield put(getPaymentGatewayListFail(error))
  }
}

function* _updatePaymentGateway ({payload:paygate}){
  try{
    const response = yield call(updatePaymentGateway,paygate)
    if(response){
      yield put(updatePaymentGatewaySuccess(response))
    }
    else{
      yield put(updatePaymentGatewayFail(response))
    }
    yield put(restartState())
  }
  catch(error){
    yield put(updatePaymentGatewayFail(error))
  }
}

function* _updateStatusChange({payload:paygate}){
  try{
    const response = yield call(updatePaymentGateway,paygate)
    if(response){
      yield put(updateStatusChangeSuccess(response))
    }
    else{
      yield put(updateStatusChangeFail(response))
    }
    yield put(restartState())
  }
  catch(error){
    yield put(updateStatusChangeFail(error))

  }
}

function* _getPaymentGatewayDetail({payload:paygate}){
  try{
    const response = yield call(getPaymentGatewayDetail,paygate)
    if(response){
      yield put(getPaymentGatewayDetailSuccess(response))
    }
    else{
      yield put(getPaymentGatewayDetailFail(response))
    }
  }
  catch(error){
    yield put(getPaymentGatewayDetailFail(error))
  }
}

function* paymentGatewaySaga() {
  yield takeEvery(SET_PAYMNET_GATEWAY, _setPaymentGateway)
  yield takeEvery(GET_PAYMENT_GATEWAY_LIST, _getPaymentGatewayList)
  yield takeEvery(UPDATE_PAYMENT_GATEWAY, _updatePaymentGateway)
  yield takeEvery(UPDATE_STATUS_CHANGE,_updateStatusChange)
  yield takeEvery(GET_PAYMENT_GATEWAY_DETAIL,_getPaymentGatewayDetail)
}

export default paymentGatewaySaga
