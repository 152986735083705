import React, { useState } from "react"
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import { setCustomer } from "../../store/actions.js"

import validator from "validator"
import { isEmail } from "components/Validation/EmailValidation.js"
function CustomerAddPopUp(props) {
  const dispatch = useDispatch()
  const [
    { Name, Email, Mobile, MobilePlus, Type },
    setPaymentRequest,
  ] = useState({
    MobilePlus: "+974",
    Name: "",
    Email: "",
    Mobile: "",
    SearchCustomer: "",
    Type: "New",
    modal_large: false,
  })
  const [user, setUser] = useState(AuthUser())
  const { register, handleSubmit, errors } = useForm()

  const storeDataOnForm = event => {
    const { name, value } = event.target
    setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
  }
  //Function for creating customer
  const handleCreateCustomer = () => {
    const customerDetails = {
      Name,
      Email,
      Mobile: MobilePlus + "-" + Mobile.toString(),
      RegistrationDate: "2020-01-01T01:01:01",
      Type,
      MerchantId: user.merchantId,
      IsActive: true,
    }
    if (Name != "" && Email != "" && Mobile != "" && Type === "New") {
      dispatch(setCustomer(customerDetails))

      props.onChange(formRequest => ({ ...formRequest, modal_large: false }))
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(handleCreateCustomer)}
      className=" mx-auto my-auto"
    >
      <Row className="form-group mx-3 my-3">
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <Label className="input-group-text input-text-size">
              CUSTOMER NAME
            </Label>
          </InputGroupAddon>
          <input
            id="Name"
            name="Name"
            value={Name}
            type="text"
            className="form-control form-control-lg input-text-size"
            ref={register({ required: true })}
            onChange={event => storeDataOnForm(event)}
          />
        </InputGroup>
        {errors.Name && errors.Name.type === "required" && (
          <p className="color-danger">This is required</p>
        )}

        <InputGroup className="">
          <InputGroupAddon addonType="prepend">
            <Label className="input-group-text input-text-size">
              CUSTOMER EMAIL
            </Label>
          </InputGroupAddon>
          <input
            id="Email"
            name="Email"
            value={Email}
            type="email"
            ref={register({ required: true })}
            className="form-control form-control-lg input-text-size"
            onChange={event => storeDataOnForm(event)}
          />
        </InputGroup>
        {errors.Name && errors.Name.type === "required" && (
          <p className="color-danger">This is required</p>
        )}
      </Row>
      <Row className="form-group mx-1 my-1">
        <Col>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">MOBILE</Label>
            </InputGroupAddon>
            <select
              className="form-control  custom-select input-text-size"
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option value=" +974" hidden="hidden">
                +974
              </option>
              {props.countryCodes?.map((cc, i) => {
                return (
                  <option value={"+" + cc.countryCode}>
                    +{cc.countryCode}
                  </option>
                )
              })}
            </select>
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col>
          <InputGroup>
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              type="number"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="">
        <Col className="item-center mb-3">
          <Button
            color="success"
            type="submit"
            className=" waves-effect waves-light mt-3 btn-lg"
          >
            <i className="mdi mdi-account-multiple-plus mr-1" />
            Enter New Customer
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default CustomerAddPopUp
