import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Input
} from "reactstrap"
import DebitCard from "./DebitCard";



const CardNotificationModal = (props) => {
  
    const {isOpen, toggle, retrievedTokenDetailsRedirect} = props

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      
      <ModalBody>
      <div className="modal-content">

       <p className="lead">You are using a Qatar issued bank card. Please click here to proceed.</p>

       <DebitCard retrievedTokenDetailsRedirect={retrievedTokenDetailsRedirect}/>


      </div>
      </ModalBody>
    </Modal>
  )
}

CardNotificationModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default CardNotificationModal
