import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { GET_USERS_LIST_ARCHIVED, PATCH_USER_ARCHIVE } from "./actionTypes"
import {
  getUsersListArchivedSuccess,
  getUsersListArchivedFail,
  patchUserArchiveSuccess,
  patchUserArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getUsersListArchived,
  patchUserArchive,
} from "helpers/fakebackend_helper"

function* _getUsersListArchived({ payload: data }) {
  try {
    const response = yield call(getUsersListArchived, data)
    if (response) {
      yield put(getUsersListArchivedSuccess(response))
    } else {
      yield put(getUsersListArchivedFail(response))
    }
  } catch (error) {
    yield put(getUsersListArchivedFail(error))
  }
}

function* _patchUserArchive({ payload: data }) {
  try {
    const response = yield call(patchUserArchive, data)
    if (response) {
      yield put(patchUserArchiveSuccess(response))
    } else {
      yield put(patchUserArchiveFail(response))
    }
  } catch (error) {
    yield put(patchUserArchiveFail(error))
  }
  yield put(returnState())
}

function* paymentrequestsArchiveSaga() {
  yield takeEvery(GET_USERS_LIST_ARCHIVED, _getUsersListArchived)
  yield takeEvery(PATCH_USER_ARCHIVE, _patchUserArchive)
}

export default paymentrequestsArchiveSaga
