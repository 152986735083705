import { call, put, takeEvery, takeLatest } from "redux-saga/effects"


import {
    MAIN_SEARCH_FILTER_DATE_RANGE,
    MAIN_SEARCH_TEXT,
    RESET_FILTER_VALUES,
    MAIN_SEARCH_FILTER_DROPDOWN,
    MAIN_SEARCH_LIST_RESET

} from "./actionTypes"
import {
    mainSearchDateRangeSuccess,
    mainSearchDateRangeFail,
    mainSearchTextSuccess,
    mainSearchTextFail,
    resetFilterValuesSuccess,
    resetFilterValuesFail,
    mainSearchDropdownSuccess,
    mainSearchDropdownFail,
    mainSearchListResetSuccess,
    mainSearchListResetFail,
    resetValue
} from "./actions"

//Include Both Helper File with needed methods


function* _mainSearchDateRange({payload:search}) {
  try {
    if(search !== undefined){
        yield put(mainSearchDateRangeSuccess(search))
    }
    else{
        yield put(mainSearchDateRangeFail(search))
    }

  } catch (error) {
    yield put(mainSearchDateRangeFail(search))
  }
}

function* _mainSearchText({payload:search}) {
    try {
      if(search !== undefined){
          yield put(mainSearchTextSuccess(search))
      }
      else{
          yield put(mainSearchTextFail(search))
      }
  
    } catch (error) {
      yield put(mainSearchTextFail(search))
    }
  }
  function* _resetFilterValues() {
    try {
        // console.log("reset")
      
          yield put(resetFilterValuesSuccess("Values cleared."))
  
    } catch (error) {
        yield put(resetFilterValuesFail(error))
    }
  }

  function*_mainSearchDropDown({payload:search}){
    try {
      if(search !== undefined){
          yield put(mainSearchDropdownSuccess(search))
      }
      else{
          yield put(mainSearchDropdownFail(search))
      }
  
    } catch (error) {
      yield put(mainSearchDropdownFail(error))
    }
  }

  function* _mainSearchListReset({payload:search}){
    try {
      if(search !== undefined){
          yield put(mainSearchListResetSuccess(search))
      }
      else{
          yield put(mainSearchListResetFail(search))
      }
      // yield put(resetValue())
    } catch (error) {
      yield put(mainSearchListResetFail(error))
    }
  }

function* mainsearchSaga() {
  yield takeEvery(MAIN_SEARCH_FILTER_DATE_RANGE, _mainSearchDateRange)
  yield takeEvery(MAIN_SEARCH_TEXT, _mainSearchText)
  yield takeEvery(RESET_FILTER_VALUES,_resetFilterValues)
  yield takeEvery(MAIN_SEARCH_FILTER_DROPDOWN, _mainSearchDropDown)
  yield takeEvery(MAIN_SEARCH_LIST_RESET, _mainSearchListReset)
}

export default mainsearchSaga
