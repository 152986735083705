import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_BY_MERCHANTID,
  GET_USER_LIST_BY_MERCHANTID_SUCCESS,
  GET_USER_LIST_BY_MERCHANTID_FAIL,
  SET_USER,
  SET_USER_SUCCESS,
  SET_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  SET_NEW_USER_PASSWORD,
  SET_NEW_USER_PASSWORD_SUCCESS,
  SET_NEW_USER_PASSWORD_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  RETURN_STATE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_ADMIN_USER_BY_MERCHANTID,
  GET_ADMIN_USER_BY_MERCHANTID_SUCCESS,
  GET_ADMIN_USER_BY_MERCHANTID_FAIL,
} from "./actionTypes"
//TODO: Cleanup Logs na lng
export const getUserList = () => ({
  type: GET_USER_LIST,
})

export const getUserListSuccess = users => ({
  type: GET_USER_LIST_SUCCESS,
  payload: users,
})

export const getUserListFail = users => ({
  type: GET_USER_LIST_FAIL,
  payload: users,
})

export const getAdminUserByMerchantId = id => ({
  type: GET_ADMIN_USER_BY_MERCHANTID,
  payload: id,
})

export const getAdminUserByMerchantIdSuccess = user => ({
  type: GET_ADMIN_USER_BY_MERCHANTID_SUCCESS,
  payload: user,
})

export const getAdminUserByMerchantIdFail = error => ({
  type: GET_ADMIN_USER_BY_MERCHANTID_FAIL,
  payload: error,
})

export const getUserListByMerchantId = merchantId => ({
  type: GET_USER_LIST_BY_MERCHANTID,
  payload: merchantId,
})

export const getUserListByMerchantIdSuccess = users => ({
  type: GET_USER_LIST_BY_MERCHANTID_SUCCESS,
  payload: users,
})

export const getUserListByMerchantIdFail = error => ({
  type: GET_USER_LIST_BY_MERCHANTID_FAIL,
  payload: error,
})

export const setUser = user => ({
  type: SET_USER,
  payload: user,
})

export const setUserSuccess = user => ({
  type: SET_USER_SUCCESS,
  payload: user,
})

export const setUserFail = user => ({
  type: SET_USER_FAIL,
  payload: user,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = user => ({
  type: DELETE_USER_FAIL,
  payload: user,
})

export const deactivateUser = user => ({
  type: DEACTIVATE_USER,
  payload: user,
})
export const deactivateUserSuccess = user => ({
  type: DEACTIVATE_USER_SUCCESS,
  payload: user,
})

export const deactivateUserFail = user => ({
  type: DEACTIVATE_USER_FAIL,
  payload: user,
})
export const updateNewUserPass = user => ({
  type: SET_NEW_USER_PASSWORD,
  payload: user,
})

export const updateNewUserPassSuccess = user => ({
  type: SET_NEW_USER_PASSWORD_SUCCESS,
  payload: user,
})

export const updateNewUserPassFail = user => ({
  type: SET_NEW_USER_PASSWORD_FAIL,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = user => ({
  type: UPDATE_USER_FAIL,
  payload: user,
})

export const updateUserPass = user => ({
  type: UPDATE_PASSWORD,
  payload: user,
})

export const updateUserPassSuccess = user => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: user,
})

export const updateUserPassFail = user => ({
  type: UPDATE_PASSWORD_FAIL,
  payload: user,
})

export const getUserDetail = user => ({
  type: GET_USER,
  payload: user,
})

export const getUserDetailSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
})

export const getUserDetailFail = user => ({
  type: GET_USER_FAIL,
  payload: user,
})

export const returnState = user => ({
  type: RETURN_STATE,
  payload: user,
})
