import React, { useContext } from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Col,
  Button
} from "reactstrap"

import { PaymentMethodContext } from '../index'

const CreditCardButton = (props) => {

  const { handleCreditCard } = useContext(PaymentMethodContext)

  return (
    <>

      <Col lg="12" >
        <Col style={{ "marginTop": "1rem" , alignItems: "left"}}></Col>
        <Button className="paymethod " 
        // style={{ width: "100%", padding: "15px", "fontSize": "1rem", background: "#34C38F", border: "none" }}
          onClick={handleCreditCard}>
          {/* <i className="fas fa-credit-card " /> */}

          <img
            src="../credit.png"
            alt=""
            className="img-fluid img_credit"
        
          />

          {" "}{props.t("Credit Card")}</Button>
      </Col>

    </>
  )


}

CreditCardButton.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CreditCardButton))
