import { call, put, takeEvery,takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {

  GET_PAYMENTRESPONSE_DETAIL,
  GET_PAYMENTRESPONSE_DETAIL_FAIL,
  GET_PAYMENTRESPONSE_DETAIL_SUCCESS,

} from "./actionTypes"

import {
  getPaymentResponseDetailSuccess,
  getPaymentResponseDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {  getPaymentResponseDetail } from "helpers/fakebackend_helper"


function* fetchPaymentResponseDetail({ trackId }) {
  try {
    const response = yield call(getPaymentResponseDetail, trackId)

    if(response){
    yield put(getPaymentResponseDetailSuccess(response))}
  } catch (error) {
    yield put(getPaymentResponseDetailFail(error))
  }
}


function* paymentResponsesSaga() {
  yield takeEvery(GET_PAYMENTRESPONSE_DETAIL, fetchPaymentResponseDetail)

}

export default paymentResponsesSaga
