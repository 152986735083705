import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Badge,
  Row,
  Col,
} from "reactstrap"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const CustomerArchiveCol = (merchantList, handleArchiveCustomer) => {
  return [
    {
      dataField: "merchantID",
      text: "Merchant Id",
      sort: true,
    },
    {
      dataField: "",
      text: "Merchant Name",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            {merchantList?.map((merchant, i) => {
              if (merchant.merchantId === row.merchantID) {
                console.log(merchant)
                return <div key={i}>{merchant.merchantName} </div>
              }
            })}
          </>
        )
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      text: " Email",
      dataField: "email",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "Recurring") {
          return (
            <Badge
              className={"font-size-12 badge-soft-primary"}
              color="primary"
              pill
            >
              {cellContent}
            </Badge>
          )
        }
        if (cellContent === "New") {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="sucess"
              pill
            >
              {cellContent}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "registrationDate",
      text: "Registration Date",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormat(cellContent)}</span>
      },
    },
    {
      dataField: "isActive",
      text: "Active",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === true) {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
        if (cellContent === false) {
          return (
            <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
      },
    },

    {
      dataField: "_id",
      text: "Action",
      csvExport: false,
      formatter: (cellContent, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => handleArchiveCustomer(cellContent)}>
                <i className="fas fa-sync text-success mr-1" />
                Restore Customer
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      },
    },
  ]
}

export default CustomerArchiveCol
