import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const AcceptanceGraph = props => {
  const { prSummary } = props

  const series = [
    {
      name: "Paid",
      // data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
      data: prSummary?.monthStats?.paid,
    },
    {
      name: "Unpaid",
      // data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
      data: prSummary?.monthStats?.unpaid,
    },
    {
      name: "Failed",
      // data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
      data: prSummary?.monthStats?.failed,
    },
  ]

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
      },
    },
    colors: ["#34c38f", "#f1b44c", "#E5006B"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <Col lg="12" key="">
        <Card className="mini-stats-wid">
          <CardBody>
            <CardTitle>Transaction Summary</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="359"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default AcceptanceGraph
