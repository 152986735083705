import React, { useState } from "react"
import { Table, Card, CardBody, Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import Amadeuslogo from "../../assets/images/images/amadeuslogo.png"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const path = "/merchant/"

const PaymentResultDetails = props => {
  const { response } = props
  const [col2, setcol2] = useState(false)

  return (
    <Card>
      <CardBody>
        <div style={{ padding: "10px" }}>
          <Row>
            <Col md={4}>
              {/* <div className=" float-left mr-5">
                <img src={merchant?.logo}
                  className="merchant-logo" />

               
              </div> */}

              <img
                src={response?.logo}
                alt=""
                className="img-fluid"
                height="auto"
                maxwidth="80%"
                width="60%"
              />
            </Col>
            <Col md={4}></Col>
            <Col md={4}>
              <br></br>

              <b>{dateFormat(response?.paymentDate)}</b>
            </Col>
          </Row>
          <hr></hr>
          {/* 
          <Row><Col md="12" style={{ 'marginTop': "3rem" }}></Col></Row> */}

          <Row>
            <Col md={5}>
              <Row>
                <Table
                  style={{ textAlign: "left" }}
                  className="table table-sm  table-borderless"
                >
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <small>
                          {" "}
                          {response.isSuccessful
                            ? "Amount Paid"
                            : "Amount Due"}{" "}
                        </small>
                      </td>

                      <td>
                        {" "}
                        <small>
                          <b>
                            {response?.amount} {response?.currency}
                          </b>{" "}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <small>Track Id </small>
                      </td>
                      <td>
                        {" "}
                        <small>{response?.trackId} </small>
                      </td>
                    </tr>

                    {/* <tr>


                      <td colSpan="2"> <small ></small>

                        <small onClick={() => { setcol2(!col2) }} style={{ cursor: "pointer", color: "rgb(52, 195, 143)" }}>
                          {" "} <b > View more details</b> {" >> "}
                        </small>

                      </td>
                    </tr> */}

                    <tr>
                      <td>
                        {" "}
                        <small>Trans Id </small>
                      </td>
                      <td>
                        {" "}
                        <small>{response?.transId}</small>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <small>Transaction Result</small>
                      </td>
                      {/* <td> <small >{response?.paymentResponseResult} - {response?.additionalPaymentInfo} </small></td> */}
                      {response?.paymentResponseCode == "10785" ? (
                        <td>
                          {" "}
                          <small>
                            {response?.paymentResponseCode} - {" "} Transaction Declined. Contact Support.{" "}
                          </small>
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <small>
                            {response?.paymentResponseCode} -{" "}
                            {response?.paymentResponseResult}{" "}
                          </small>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <small>Payment Type</small>
                      </td>
                      <td>
                        {" "}
                        <small>{response?.paymentType} </small>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                {/* <Collapse isOpen={col2} >
                  <Table style={{ 'textAlign': "left" }} className="table table-sm  table-borderless" >
                    <tbody>
                      <tr>
                        <td> <small >PR Id </small></td>

                        <td> <small ><b>{response?.paymentRequestId}</b> </small></td>

                      </tr>


                      <tr>
                        <td> <small >Trans Id </small></td>
                        <td> <small ><b>{response?.transId}</b> </small></td>
                      </tr>
                      <tr>
                        <td> <small >Transaction Result</small></td>
                        <td> <small >{response?.paymentResponseResult} - {response?.additionalPaymentInfo} </small></td>
                        
                      </tr>
                      <tr>
                        <td> <small >Transaction Code</small></td>
                        <td> <small >{response?.paymentResponseCode} </small></td>
                      </tr>
                      <tr>
                        <td> <small >Payment Type</small></td>
                        <td> <small >{response?.paymentType} </small></td>
                      </tr>
                    </tbody>
                  </Table>
                </Collapse> */}
              </Row>
            </Col>
            <Col md={2}></Col>
            <Col
              md={5}
              className="payment-resultcode rounded justify-content-center container-alignment"
            >
              <div style={{ padding: "15px" }}>
                {/* TRANSACTION RESULT
                <br /> <br />
                <h3>{response?.paymentResponseCode}</h3>

                <p>{response?.paymentResponseResult} - {response?.additionalPaymentInfo}</p>  */}

                {response?.isSuccessful ? (
                  <div>
                    <Link
                      to={"/invoice/" + response?.paymentRequestId}
                      style={{ width: "auto" }}
                      className=" btn invoice-button btn-lg waves-effect mb-3 card-title-desc"
                    >
                      <small>VIEW INVOICE</small>{" "}
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Link
                      to={"/invoice/" + response?.paymentRequestId}
                      style={{ width: "auto" }}
                      className=" btn invoice-button-failed btn-lg waves-effect mb-3 card-title-desc"
                    >
                      {" "}
                      <small>PAY AGAIN</small>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12" style={{ marginTop: "0.5rem" }}></Col>
          </Row>

          <Row>
            <Col md="12">
              {response?.isSuccessful ? (
                <div>
                  {/* <h3>Thank you for your business. </h3> */}
                  <p>You can send this screenshot as proof of payment.</p>
                </div>
              ) : (
                <div>
                  <h4>Please Try Again.</h4>
                  <p>
                    For support, please see invoice for the contact details.
                  </p>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <img
                src="/logo_blue.png"
                alt="PayAllLink Logo"
                className="img-fluid"
                height="auto"
                maxwidth="60%"
                width="150px"
              />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}
export default PaymentResultDetails
