import React, { useState, useEffect } from "react"
import { isEmail } from "components/Validation/EmailValidation"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"
const Contacts = props => {
  const [isEmailValid, setIsEmailValid] = useState()
  const {
    contact,
    handleContactChange,
    handleContactDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
  } = props
  useEffect(() => {
    //console.log(isEmailValid)
  }, [isEmailValid])
  // const { register, handleSubmit, errors , reset, watch } = useForm();
  // const onSubmit = data => console.log(data);

  function handleChange(changes) {
    //console.log(changes)
    handleContactChange(contact.id, { ...contact, ...changes })
  }

  const inputName = "Name" + index
  const inputMobile = "Mobile" + index
  const inputEmail = "Email" + index
  const inputPosition = "Position" + index

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="3">
            <FormGroup>
              {/* <label htmlFor="subject">Name </label> */}
              <input
                value={contact.Name}
                name={inputName}
                onChange={e => handleChange({ Name: e.target.value })}
                type="text"
                className="form-control"
                placeholder="Enter Name"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputName] && errors[inputName].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              {/* <label htmlFor="subject">Mobile </label> */}

              <input
                value={contact.Mobile}
                type="text"
                name={inputMobile}
                onChange={e => handleChange({ Mobile: e.target.value })}
                className="form-control"
                placeholder="Enter Mobile"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputMobile] && errors[inputMobile].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              {/* <label htmlFor="subject">Email </label> */}
              <input
                value={contact.Email}
                type="email"
                name={inputEmail}
                onChange={e => handleChange({ Email: e.target.value })}
                className="form-control"
                placeholder="Enter Email"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputEmail] && errors[inputEmail].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              {/* <label htmlFor="subject">Position </label> */}
              <select
                className="custom-select"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
                value={contact.Position}
                name={inputPosition}
                onChange={e => handleChange({ Position: e.target.value })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="General Manager">General Manager</option>
                <option value="Accounts">Finance Manager</option>
                <option value="Operations Manager">Operations Manager</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Others">Others</option>
              </select>
              <small className="color-danger">
                {errors[inputPosition] && errors[inputPosition].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              {/* <label style={{ color: "white" }} htmlFor="subject">Delete </label> */}

              <Button
                onClick={() => handleContactDelete(contact.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Contacts
