import React from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
    Col,
    Row,
} from "reactstrap"

const PaymentDetails = (props) => {


    return (
        <>

            <Col lg="12">


                {/* <Row>
                    <Col style={{ "marginTop": "0.2rem" }}></Col>
                </Row> */}

                <Row>
                    <Col lg="2">

                    </Col>
                    <Col lg="8">
                        <div style={{
                            background: "#EAEFF5",
                            borderRadius: "25px",
                            padding: "2%",
                            "textAlign": "center"
                        }}>
                            <div> <small><b>{props.t("AMOUNT DUE")}</b></small> </div>
                            <div >
                                <h4 className="invoiceAmount" style={{ color: "#D80065" }}>
                                    {props.t(props.currency)} {props.amount}</h4>
                            </div>
                            <div><small><a href={props.termsConditionUrl} rel="noopener noreferrer"  target="_blank">{props.t("By paying, I agree to the TERMS & CONDITION")}</a></small></div>
                            {/* <div><small>{props.t("Only Qatar Banks are accepted")}</small></div> */}
                        </div>
                    </Col>
                    <Col lg="2">

                    </Col>
                </Row>

            </Col>

        </>
    )
}

PaymentDetails.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(PaymentDetails))

