import React, { useEffect } from "react"
import { Button } from "reactstrap"
import styles from "assets/scss/tableFooter.scss"
const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1)
    }
  }, [slice, page, setPage])
  return (
    <div className="tableFooter">
      {range.map((el, index) => (
        <Button
          color="primary"
          key={index}
          //   className={`${styles.button} ${
          //     page === el ? styles.activeButton : styles.inactiveButton
          //   }`}
          className="button"
          onClick={() => setPage(el)}
        >
          {el}
        </Button>
      ))}
    </div>
  )
}

export default TableFooter
