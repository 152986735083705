import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Badge,
  Row,
  Col,
} from "reactstrap"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const CustomerColumns = (
  handleActionTableCustomer,
  merchantList,
  user,
  handleArchiveCustomer
) => {
  const isHidden = () => {
    if (user.role !== "SuperAdmin") return true
    else return false
  }

  return [
    {
      dataField: "merchantID",
      text: "Merchant Id",
      sort: true,
      hidden: isHidden(),
      csvExport: !isHidden(),
    },
    {
      dataField: "merchant.merchantName",
      text: "Merchant Name",
      sort: true,
      hidden: isHidden(),
      csvExport: !isHidden(),
      formatter: (cellContent, row) => {
        return (
          <>
            {merchantList.map((merchant, i) => {
              if (row.merchantID === merchant.merchantId) {
                return <div key={i}>{merchant.merchantName}</div>
              }
            })}
          </>
        )
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      text: " Email",
      dataField: "email",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === "Recurring") {
          return (
            <Badge
              className={"font-size-12 badge-soft-primary"}
              color="primary"
              pill
            >
              {cellContent}
            </Badge>
          )
        }
        if (cellContent === "New") {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="sucess"
              pill
            >
              {cellContent}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "registrationDate",
      text: "Registration Date",
      sort: true,
      formatter: (cellContent, row) => {
        return <span>{dateFormat(cellContent)}</span>
      },
    },
    {
      dataField: "isActive",
      text: "Active",
      sort: true,
      formatter: (cellContent, row) => {
        if (cellContent === true) {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
        if (cellContent === false) {
          return (
            <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      hidden: !isHidden(),
      csvExport: false,
      formatter: (cellContent, row) => {
        if (row.isActive === true) {
          return (
            <Row>
              <Col onClick={() => handleActionTableCustomer("update")}>
                <i
                  className="mdi mdi-pencil font-size-18 mr-3 color-primary"
                  id="edittooltip"
                />
              </Col>
              {/* //TODO:change here */}
              <Col onClick={() => handleActionTableCustomer("deactivate")}>
                <i
                  className="mdi mdi-close font-size-18 mr-3 color-danger"
                  id="deletetooltip"
                />
              </Col>
            </Row>
          )
        }
        if (row.isActive === false) {
          return (
            <Row>
              <Col onClick={() => handleActionTableCustomer("update")}>
                <i
                  className="mdi mdi-pencil font-size-18 mr-3 color-primary"
                  id="edittooltip"
                />
              </Col>
              <Col onClick={() => handleActionTableCustomer("activate")}>
                <i
                  className="fas fa-plus font-size-15 mr-3 color-active"
                  id="deletetooltip"
                />
              </Col>
            </Row>
          )
        }
      },
    },
    {
      dataField: "_id",
      text: "Action",
      hidden: isHidden(),
      csvExport: false,
      formatter: (cellContent, row) => {
        if (row.isActive === true) {
          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => handleActionTableCustomer("update")}
                >
                  <i
                    className="mdi mdi-pencil font-size-18 mr-2 color-primary"
                    id="edittooltip"
                  />
                  Update Customer
                </DropdownItem>

                <DropdownItem
                  onClick={() => handleActionTableCustomer("deactivate")}
                >
                  <i
                    className="mdi mdi-close font-size-18 mr-2 color-danger"
                    id="deletetooltip"
                  />
                  Deactivate Customer
                </DropdownItem>

                <DropdownItem
                  onClick={() => handleActionTableCustomer("delete")}
                >
                  <i
                    className="fas fa-trash-alt font-size-18 mr-2 color-disable"
                    id="deletetooltip"
                  />
                  Delete Customer
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleArchiveCustomer(cellContent)}
                >
                  <i className="fas fa-sync text-success mr-1" />
                  Archive Customer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        if (row.isActive === false) {
          return (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => handleActionTableCustomer("update")}
                >
                  <i
                    className="mdi mdi-pencil font-size-18 mr-2 color-primary"
                    id="edittooltip"
                  />
                  Update Customer
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleActionTableCustomer("activate")}
                >
                  <i
                    className="mdi mdi-close font-size-18 mr-2 color-danger"
                    id="deletetooltip"
                  />
                  Activate Customer
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleActionTableCustomer("delete")}
                >
                  <i
                    className="fas fa-trash-alt font-size-15 mr-2 color-disable"
                    id="deletetooltip"
                  />
                  Delete Customer
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleArchiveCustomer(cellContent)}
                >
                  <i className="fas fa-sync text-success mr-1" />
                  Archive Customer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      },
    },
  ]
}

export default CustomerColumns
