import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import axios from "axios"
import { connect, useDispatch } from "react-redux"
// import { setMerchant } from "store/actions"
// import { setUser } from "../../store/actions.js"
import classnames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { FirstMerchantInfo } from "./Steps/FirstMerchantInfo"
import { SecondConfigurations } from "./Steps/SecondConfigurations"
//import { ThirdPaymentGateway } from "./Steps/ThirdPaymentGateway"
import FourthUser from "./Steps/FourthUser"
// import FifthSummary from "./Steps/FifthSummary"
import PayAllLinkLogo from "../../assets/images/images/payalllink-logo@2x.png"
import LoadingModal from "./LoadingModal.js"
import { setUser, registerMerchant, getPaymentGatewayList } from "store/actions"
import { returnState, returnStateMerchant } from "store/actions"
import ResultModal from "./ResultModal.js"
export const MerchantCreateContext = React.createContext()
import handleSubmit from "./Steps/FirstMerchantInfo"

const initialUserState = {
  UserId: null,
  MerchantId: "",
  RegistrationDate: new Date().toISOString(),
  Title: "",
  FirstName: "",
  LastName: "",
  Mobile: "",
  UserName: "",
  Password: "",
  Email: "",
  Role: "Admin",
  IsActive: false,
  Department: "",
}

const initialState = {
  MerchantId: null,
  RegistrationDate: new Date().toISOString(),
  Type: "Travel Agency",
  MerchantName: "",
  BusinessRegNumber: "",
  TaxRegNumber: "",
  Address: "",
  City: "",
  Country: "",
  PoBox: "",
  PhoneNumber: "",
  Website: "",
  TermsConditionUrl: "",
  Logo: "",
  Banner: "",
  PaymentGatewayEnrollments: [
    {
      id: uuidv4(),
      PaymentGateway: "",
      TerminalId: "",
      TerminalPassword: "",
      MerchantId: "",
      CommissionBasedOn: "",
      IsActive: false,
      PaymentGatewayId: "",
      CommissionSettings: [
        {
          id: uuidv4(),
          Basis: "",
          Value: 1,
          Type: "",
        },
      ],
    },
  ],
  PaymentGatewaySettings: {
    LinkValidity: 24,
  },
  Contacts: [
    {
      id: uuidv4(),
      Position: "",
      Name: "",
      Email: "",
      Mobile: "",
    },
  ],
  NotificationEmails: [
    {
      id: uuidv4(),
      Email: "",
      Date: new Date().toISOString(),
    },
  ],
  Services: [
    {
      id: uuidv4(),
      Service: "",
    },
  ],
  OfficeIds: [
    {
      id: uuidv4(),
      OfficeId: "",
      Date: new Date().toISOString(),
    },
  ],
  IsActive: null,
}

const MerchantRegistrationExternal = props => {
  const {
    merchantDetail,
    userDetail,
    loadingUser,
    loadingMerchant,
    PaymentGatewayList,
    onGetPaymentGateways,
    onRegisterMerchant,
    onRegisterUser,
    errorMerchant,
    registerMerchantDetails,
    userCreated,
  } = props

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(33)
  const [merchantDetails, setMerchantDetails] = useState(initialState)
  const [userAdmin, setuserAdmin] = useState(initialUserState)
  const [returnedMerchantDetail, setReturnedMerchantDetail] = useState()
  const [modal, setModal] = useState(false)
  const [errorMerchantMessage, setErrorMerchantMessage] = useState()
  const [userCreatedResponse, setUserCreatedResponse] = useState("")
  const [sweetAlertMsg, setSweetAlertMsg] = useState(false)
  const [firstStepError, setFirstStepError] = useState()
  const [secondStepError, setSecondStepError] = useState()
  const [thirdStepError, setThirdStepError] = useState()
  const [fourthStepError, setFourthStepError] = useState()
  const [isMerchantLoaded, setIsMerchantLoaded] = useState(undefined)
  const [isUserLoaded, setIsUserLoaded] = useState(undefined)
  const firstStepRef = useRef()
  const [dispatched, setDispatched] = useState(undefined)
  const dispatch = useDispatch()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  useEffect(() => {
    // checkMandatory(1)
  }, [])

  useEffect(() => {
    setReturnedMerchantDetail(merchantDetail)
  }, [merchantDetail])

  useEffect(() => {
    // console.log(userCreated)
    if (userCreated !== undefined) {
      setUserCreatedResponse(userCreated)
    }
  }, [userCreated])

  useEffect(() => {
    // setUserCreatedResponse(userCreated)
    // console.log(userCreatedResponse)
    if (userCreatedResponse?.message === "User Exist") {
      showToastError("User Already Existed! Use unique email address.")
      resetState()
      sleep(5000)
      //window.location.reload()
    }

    if (userCreatedResponse === true && isUserLoaded === false) {
      showToastSuccess("User Created Successfully!")
      resetState()
      sleep(5000)
      //window.location.reload()
    }
  }, [userCreatedResponse])

  // useEffect(() => {
  //   onGetPaymentGateways()
  // }, [])

  useEffect(() => {
    setErrorMerchantMessage(errorMerchant)
  }, [errorMerchant])

  useEffect(() => {
    setIsMerchantLoaded(loadingMerchant)
  }, [loadingMerchant])

  useEffect(() => {
    setIsUserLoaded(loadingUser)
  }, [loadingUser])

  useEffect(() => {}, [userDetail])

  useEffect(() => {}, [userAdmin])

  useEffect(() => {}, [merchantDetails, returnedMerchantDetail])

  useEffect(() => {}, [
    firstStepError,
    secondStepError,
    //thirdStepError,
    fourthStepError,
  ])

  useEffect(() => {
    if (registerMerchantDetails !== undefined) {
      // if (isMerchantLoaded === false) {
      //   if (!isEmpty(errorMerchant)) {
      //     showToastError("Merchant Not Registered! Contact Dev Team")
      //   } else {
      //     showToastSuccess("Merchant Registered Successfully!")
      //     // setSweetAlertMsg(true)
      //     setsuccess_dlg(true)
      //     setdynamic_title("Registration Successful")
      //     setdynamic_description(
      //       "We have recieved your request for registration. We will get back to you upon verifying your account."
      //     )
      //   }
      // }
      if (registerMerchantDetails === true) {
        let copyUser = { ...userAdmin }
        copyUser.MerchantId = merchantDetail?.merchantId
        // console.log(copyUser)
        onRegisterUser(copyUser)
        setsuccess_dlg(true)
        setdynamic_title("Registration Successful")
        setdynamic_description(
          "We have recieved your request for registration. We will get back to you upon verifying your account."
        )
      }
      if (registerMerchantDetails === false) {
        seterror_dlg(true)
        setdynamic_title("Registration Failed")
        setdynamic_description(
          "Your account registration has failed, please contact helpdesk for help."
        )
      }
      resetState()

      //dispatch(setUser(copyUser))
    }
  }, [registerMerchantDetails])

  useEffect(() => {
    if (sweetAlertMsg) {
      callInfoSweetAlert()
    }
  }, [sweetAlertMsg])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
      }
    }
  }

  function sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  function triggerSubmitButton(id) {
    document.getElementById(id).click()
  }

  // temporary deactivated : clickable header (not working)
  function checkMandatory(tab) {
    if (tab === 1) {
      triggerSubmitButton("my-form_submit_1")
      // firstStepRef.current.trigger() // using useref to call method from child (sample)
      toggleTabProgress(1)
    }

    if (tab === 2) {
      triggerSubmitButton("my-form_submit_2")
      toggleTabProgress(2)
    }

    if (tab === 3) {
      triggerSubmitButton("my-form_submit_3")
      toggleTabProgress(3)
    }

    // if (tab === 4) {
    //   triggerSubmitButton("my-form_submit_4")
    //   toggleTabProgress(4)
    // }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        // if (isEmpty(firstStepError) &&
        //   isEmpty(secondStepError) &&
        //   isEmpty(thirdStepError) &&
        //   isEmpty(fourthStepError)) {

        setactiveTabProgress(tab)

        if (tab === 1) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(33)
        }
        if (tab === 2) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(66)
        }
        if (tab === 3) {
          // triggerSubmitButton("my-form_submit_2")
          setprogressValue(100)
        }
        // if (tab === 4) {
        //   //  triggerSubmitButton("my-form_submit_3")
        //   setprogressValue(80)
        // }
        // if (tab === 5) {
        //   setprogressValue(100)
        // }
        // }
      }
    }
  }

  function resetState() {
    toggleTabProgress(1)
    setSweetAlertMsg(false)
    setMerchantDetails(initialState)
    setuserAdmin(initialUserState)
    setDispatched(false)
    dispatch(returnState({}))
    dispatch(returnStateMerchant({}))
  }

  const handleSubmit = async () => {
    // console.log(merchantDetails)
    if (!isEmpty(merchantDetails.MerchantName)) {
      if (
        merchantDetails.Logo !== "" &&
        merchantDetails.Banner !== "" &&
        merchantDetails.NotificationEmails[0].Email !== "" &&
        merchantDetails.Services[0].Service !== ""
      ) {
        // console.log(merchantDetail)
        toggleModal()
        onRegisterMerchant(merchantDetails)
        setDispatched(true)
      } else {
        seterror_dlg(true)
        setdynamic_title("Registration Failed")
        setdynamic_description(
          "Your account registration has failed, please contact helpdesk for help."
        )

        resetState()
      }
    }
  }

  function handleCheckNoErrors(errors) {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function handleMerchantChange(changes) {
    setMerchantDetails(changes)
  }

  function handleUserAdminChange(changes) {
    setuserAdmin(changes)
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const settingFirstStepError = errors => {
    setFirstStepError(errors)
  }
  const settingSecondStepError = errors => {
    setSecondStepError(errors)
  }
  const settingThirdStepError = errors => {
    setThirdStepError(errors)
  }
  const settingFourthStepError = errors => {
    setFourthStepError(errors)
  }

  const callErrorSweetAlert = (merchantResponse, userResponse) => {
    if (sweetAlertMsg) {
      return (
        <>
          <SweetAlert
            title="Something went wrong!"
            error
            confirmBtnBsStyle="success"
            onConfirm={() => {
              resetState()
            }}
          >
            Please contact Amadeus Support Team. <br></br>
            <b>
              {" "}
              Error: {merchantResponse?.message} {userResponse?.message}
            </b>
          </SweetAlert>
        </>
      )
    }
  }

  const callInfoSweetAlert = () => {
    return (
      <>
        <SweetAlert
          title="Need Assitance?"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            resetState()
          }}
        >
          Please contact Amadeus Support Team. <br></br>
        </SweetAlert>
      </>
    )
  }

  const callSuccessSweetAlert = () => {
    if (sweetAlertMsg) {
      return (
        <>
          <SweetAlert
            title="Successfully Registered!"
            success
            confirmBtnBsStyle="success"
            confirmButtonText="Confirm"
            onConfirm={() => {
              window.location.reload()
            }}
          >
            Good Job , <b> {merchantDetails?.MerchantName} </b> has been
            created!
          </SweetAlert>
        </>
      )
    }
  }

  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }

  const showToastError = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.error(message)
  }

  const loadSubmitNowButton = () => {
    if (loadingMerchant === true) {
      return (
        <>
          <Button color="primary" style={{ cursor: "wait" }} onClick={null}>
            Processing ...
          </Button>
        </>
      )
    }

    if (loadingUser === true) {
      return (
        <>
          <Button color="primary" style={{ cursor: "wait" }} onClick={null}>
            Processing ...
          </Button>
        </>
      )
    }

    return (
      <>
        <Button type="submit" color="primary" onClick={() => handleSubmit()}>
          Submit Now
        </Button>
      </>
    )
  }

  const MerchantCreateContextValue = {
    settingFirstStepError,
    settingSecondStepError,
    settingThirdStepError,
    settingFourthStepError,
  }
  return (
    // <div className="page-content">
    <Container fluid={true} className="my-2 mx-2">
      <img className="logo " height="60" src={PayAllLinkLogo} />
      <Breadcrumbs title="Register" breadcrumbItem="Register new Merchant" />

      {/* loading dispalys when either of the merchant and user loading is true */}
      {(loadingMerchant === true || loadingUser === true) && (
        <>
          <LoadingModal isOpen={modal} toggle={toggleModal} />
        </>
      )}

      {/* empty merchantDetail & UserDetails plus loading of user is false means that they have fetched somethin */}
      {/* {(!isEmpty(merchantDetail) && !isEmpty(userDetail) && loadingUser === false)
            ?
            (!isEmpty(errorMerchantMessage) || userCreated?.message === "User Exist" || userCreated === null) //* Whenever either of the module returns error it will call the error sweetallert
              ?
              callErrorSweetAlert(errorMerchantMessage, userCreated)
              :
              callSuccessSweetAlert()

            :
            null
          } */}
      {success_dlg ? (
        <SweetAlert
          // timeout={4000}
          success
          showConfirm={true}
          title={dynamic_title}
          confirmBtnBsStyle="success"
          confirmButtonText="Confirm"
          onConfirm={() => {
            window.location.reload()
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {error_dlg ? (
        <SweetAlert
          // timeout={4000}
          error
          showConfirm={true}
          title={dynamic_title}
          confirmBtnBsStyle="danger"
          confirmButtonText="Confirm"
          onConfirm={() => {
            seterror_dlg(false)
            window.location.reload()
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div id="progrss-wizard" className="twitter-bs-wizard">
                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                  <NavItem style={{ color: "#b3b3b3" }}>
                    <NavLink
                      style={{ cursor: "default", color: "#b3b3b3" }}
                      className={classnames({
                        active: activeTabProgress === 1,
                      })}
                      // onClick={() => {
                      //   toggleTabProgress(1)
                      //   //   checkMandatory(1)
                      // }}
                    >
                      <span className="step-number mr-2">01</span>
                      Merchant Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "default", color: "#b3b3b3" }}
                      className={classnames({
                        active: activeTabProgress === 2,
                      })}
                      // onClick={() => {
                      //   //  checkMandatory(2)
                      //   toggleTabProgress(2)
                      // }}
                    >
                      <span className="step-number mr-2">02</span>
                      <span>Configurations</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "default", color: "#b3b3b3" }}
                      className={classnames({
                        active: activeTabProgress === 3,
                      })}
                      // onClick={() => {
                      //   //   checkMandatory(3)
                      //   toggleTabProgress(3)
                      // }}
                    >
                      <span className="step-number mr-2">03</span>
                      User
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                        <NavLink
                          style={{ cursor: "default", color: "#b3b3b3" }}
                          className={classnames({
                            active: activeTabProgress === 4,
                          })}
                          // onClick={() => {
                          //   //   checkMandatory(4)
                          //   toggleTabProgress(4)
                          // }}
                        >
                          <span className="step-number mr-2">04</span>
                          User
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "default", color: "#b3b3b3" }}
                          className={classnames({
                            active: activeTabProgress === 5,
                          })}
                          // onClick={() => {
                          //   toggleTabProgress(5)
                          // }}
                        >
                          <span className="step-number mr-2">05</span>
                          Confirm Details
                        </NavLink>
                      </NavItem> */}
                </ul>

                <div id="bar" className="mt-4">
                  <Progress
                    color="success"
                    striped
                    animated
                    value={progressValue}
                  />
                  <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
                </div>
                <MerchantCreateContext.Provider
                  value={MerchantCreateContextValue}
                >
                  <TabContent
                    activeTab={activeTabProgress}
                    className="twitter-bs-wizard-tab-content"
                  >
                    <TabPane tabId={1}>
                      <FirstMerchantInfo
                        ref={firstStepRef}
                        merchantDetails={merchantDetails}
                        handleMerchantChange={handleMerchantChange}
                        toggleTabProgress={toggleTabProgress}
                        loadSubmitNowButton={loadSubmitNowButton}
                        activeTabProgress={activeTabProgress}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <SecondConfigurations
                        merchantDetails={merchantDetails}
                        handleMerchantChange={handleMerchantChange}
                        toggleTabProgress={toggleTabProgress}
                        loadSubmitNowButton={loadSubmitNowButton}
                        activeTabProgress={activeTabProgress}
                      />
                    </TabPane>
                    {/* <TabPane tabId={3}>
                          <ThirdPaymentGateway
                            merchantDetails={merchantDetails}
                            handleMerchantChange={handleMerchantChange}
                            PaymentGatewayList={PaymentGatewayList}
                            toggleTabProgress={toggleTabProgress}
                            loadSubmitNowButton={loadSubmitNowButton}
                            activeTabProgress={activeTabProgress}
                          />
                        </TabPane> */}
                    <TabPane tabId={3}>
                      <FourthUser
                        userAdmin={userAdmin}
                        handleUserAdminChange={handleUserAdminChange}
                        toggleTabProgress={toggleTabProgress}
                        loadSubmitNowButton={loadSubmitNowButton}
                        activeTabProgress={activeTabProgress}
                      />
                    </TabPane>

                    {/* <TabPane tabId={5}>
                          <FifthSummary
                            handleSubmit={handleSubmit}
                            merchantDetails={merchantDetails}
                            userAdmin={userAdmin}
                            loadingMerchant={loadingMerchant}
                            loadingUser={loadingUser}
                            toggleTabProgress={toggleTabProgress}
                            loadSubmitNowButton={loadSubmitNowButton}
                            activeTabProgress={activeTabProgress}
                          />
                        </TabPane> */}
                  </TabContent>
                </MerchantCreateContext.Provider>

                {/* <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTabProgress === 1
                            ? "previous disabled"
                            : "previous"
                        }
                      >
                        <Button

                          color="primary"
                          onClick={() => {
                            toggleTabProgress(activeTabProgress - 1)
                          }}
                        >
                          Previous
                        </Button>
                      </li>
                      <li
                        className={
                          activeTabProgress === 5 ? "next" : "next"
                        }
                      >

                        {activeTabProgress === 5 ?
                          <>
                            {loadSubmitNowButton()}

                          </>
                          :
                          <Button

                            color="primary"
                            onClick={() => {
                              toggleTabProgress(activeTabProgress + 1)
                            }}
                          >
                            Next
                        </Button>
                        }

                      </li>
                    </ul> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    // </div>
  )
}
MerchantRegistrationExternal.propTypes = {
  merchantDetail: PropTypes.object,
  // PaymentGatewayList: PropTypes.any,
  // onGetPaymentGateways: PropTypes.func,
  onRegisterUser: PropTypes.func,
  onRegisterMerchant: PropTypes.func,
  setFileUpload: PropTypes.func,
}

const mapStateToProps = m => ({
  merchantDetail: m.Merchants.merchantDetail,
  loadingMerchant: m.Merchants.loading,
  loadingUser: m.Users.loading,
  userDetail: m.Users.user,
  userCreated: m.Users.userCreated,
  PaymentGatewayList: m.PaymentGateway.paymentGateways,
  errorMerchant: m.Merchants.error,
  registerMerchantDetails: m.Merchants.registerMerchant,
})

const mapDispatchToProps = dispatch => ({
  // onGetPaymentGateways: () => dispatch(getPaymentGatewayList()),
  onRegisterUser: data => dispatch(setUser(data)),
  onRegisterMerchant: data => dispatch(registerMerchant(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MerchantRegistrationExternal))
