import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import { connect, useDispatch } from "react-redux"
import classnames from "classnames"
import { v4 as uuidv4 } from "uuid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  setItem,
  setPaymentRequest,
  setWhatAppLog,
  sendPaymentRequestEmailNonExpress,
  getMerchantDetail,
  setPaymentTypes,
  // getPaymentGatewayDetail
} from "store/actions"
import FirstPaymentRequestDetails from "./Steps/FirstPaymentRequestDetails"
import SecondConfiguration from "./Steps/SecondConfiguration"
import ThirdSummary from "./Steps/ThirdSummary"
import OverviewPopup from "./OverviewPopup"
import AuthUser from "components/AuthUser/AuthUser"
import ReactTooltip from "react-tooltip"
import WhatsAppLogo from "../../assets/images/whatsapp.png"
import SweetAlert from "react-bootstrap-sweetalert"

const initialPrState = {
  TransactionReference: null,
  PaymentRequestId: "",
  Amount: parseFloat(1).toFixed(2),
  Currency: "QAR",
  Remarks: "",
  GeneratedLinkLong: "",
  GeneratedLinkShort: "",
  GeneratedLinkStatus: "",
  MerchantId: "",
  ItemId: "",
  ItemSource: "PORTAL",
  CreatedBy: "",
  //CreatedByEmail: "",
  PaymentRequestDate: new Date().toISOString(),
  LastPayment: "",
  PaymentStatus: "Unpaid",
  PaymentGatewayId: "",
  IsExpress: false,
  PaymentRequestSettings: {
    PaymentType: "",
    Language: "En",
    LinkValidUpto: new Date().toISOString(),
    LinkValidity: 24,
    TerminalId: "",
  },
  CustomerDetails: {
    Name: "",
    Mobile: "",
    Email: "",
    Type: "New",
  },
  EmailSettings: {
    SubjectLine: "Payment Request for",
    Attachment: [],
    SentTo: [],
  },
  PaidDetails: null,
  Commission: {
    Type: "",
    Value: "",
    TotalCommission: 0,
  },
  SellcoSettings: {
    CreatedBy: null,
  },
}

const initialStateItems = {
  ItemId: "",
  TrackId: "",
  Items: [
    {
      id: uuidv4(),
      Qty: 0,
      ItemName: "",
      Amount: 0.0,
    },
  ],
}

const PaymentRequestCreation = props => {
  const {
    onEmailSending,
    onSetItems,
    onSetPaymentRequest,
    onSetWhatsApp,
    onGetMerchant,
    onSetPaymentTypes,
    paymentTypes,
    // onGetPaymentGateway
  } = props

  const [user, setUser] = useState(AuthUser())
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(20)
  const [prDetails, setPrDetails] = useState(initialPrState)
  const [itemDetails, setItemDetails] = useState(initialStateItems)
  const [customerDetials, setCustomerDetails] = useState(props.selectedCustomer)
  // const [selectedFiles, setselectedFiles] = useState([])
  const [emailSend, setEmailSend] = useState(false)
  const [emailType, setEmailType] = useState("")
  const [emailStatus, setEmailStatus] = useState("")
  const [selectArray, setSelectArray] = useState([])
  const [getCommissionSettings, setGetCommissionSettings] = useState([])
  const [
    commissionSettingsPaymentTypes,
    setCommissionSettingsPaymentTypes,
  ] = useState([])
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [prLoading, setPrLoading] = useState(false)
  const [merchant, setMerchant] = useState()
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    onGetMerchant(user.merchantId)
  }, [])
  useEffect(() => {
    //console.log(prDetails)
  }, [prDetails])
  useEffect(() => {
    if (props.merchantDetail !== undefined) {
      setMerchant(props.merchantDetail)
    }
  }, [props.merchantDetail])

  useEffect(() => {
    // console.log(merchant)
    const cardtypesList = new Array()
    if (
      merchant !== undefined &&
      Array.isArray(merchant.paymentGatewayEnrollments)
    ) {
      //var newMerchantPGEnrollments = { ...merchant.paymentGatewayEnrollments }

      merchant.paymentGatewayEnrollments?.map((pgEnrollments, index) => {
        if (pgEnrollments.isActive === true) {
          pgEnrollments.commissionSettings.map((cs, i) => {
            if (cs.percentage + cs.fixed !== 0) {
              cardtypesList.push(cs.basis)
            }
          })
          setCommissionSettingsPaymentTypes(cardtypesList)
        }
      })
    }
  }, [merchant])
  useEffect(() => {}, [selectArray])
  useEffect(() => {
    //console.log(commissionSettingsPaymentTypes)
  }, [commissionSettingsPaymentTypes])
  useEffect(() => {
    //console.log(paymentTypes)
  }, [paymentTypes])

  useEffect(() => {
    // console.log(props.selectedCustomer)
    if (
      props.selectedCustomer?.name !== undefined ||
      props.selectedCustomer?.Name !== undefined
    ) {
      setCustomerDetails(props.selectedCustomer)
    }
  }, [props.selectedCustomer])

  useEffect(() => {
    if (props.prLoading !== undefined) {
      setPrLoading(props.prLoading)
    }
  }, [props.prLoading])

  useEffect(() => {}, [prLoading])

  useEffect(() => {
    //console.log(customerDetials?.Name, customerDetials?.name)
    if (
      customerDetials?.Name !== undefined ||
      customerDetials?.name !== undefined
    ) {
      setPrDetails(pr => ({
        ...pr,
        CustomerDetails: {
          Name:
            customerDetials.name !== undefined
              ? customerDetials.name
              : customerDetials.Name,
          Mobile:
            customerDetials.mobile !== undefined
              ? customerDetials.mobile
              : customerDetials.Mobile,
          Email:
            customerDetials.email !== undefined
              ? customerDetials.email
              : customerDetials.Email,
          Type:
            customerDetials.type !== undefined
              ? customerDetials.type
              : customerDetials.Type,
        },
      }))
    }

    if (
      customerDetials?.Name !== undefined &&
      customerDetials?.Type === "New" &&
      props.customerCreated === true
    ) {
      showToastSuccess(
        "Customer " + customerDetials.Name + " has been created."
      )
    }
    if (props.customerCreated === false) {
      showToastError("Customer already exists.")
    }
  }, [customerDetials, props.customerCreated])

  useEffect(() => {
    if (
      props.itemId !== "" &&
      props.itemId !== undefined &&
      props.itemCreated === true
    ) {
      showToastSuccess("Items Created.")
      setPrDetails(pr => ({
        ...pr,
        ItemId: props.itemId,
      }))
    }
  }, [props.itemId, props.itemCreated])

  useEffect(() => {
    //console.log(props.files, props.filesUploaded)
    if (
      props.files !== undefined &&
      props.files?.message !==
        "File type not allowed should be pdf, jpg, png, txt or jpeg" &&
      props.filesUploaded === true &&
      props.filesUploaded !== undefined
    ) {
      showToastSuccess("Files uploaded")
      setPrDetails(pr => ({
        ...pr,
        EmailSettings: {
          SubjectLine: pr.EmailSettings.SubjectLine,
          Attachment: props.files,
          SentTo: [],
        },
      }))
    }
    if (
      props.files?.message ===
      "File type not allowed should be pdf, jpg, png, txt or jpeg"
    ) {
      showToastError("File not uploaded.")
    }
  }, [props.files, props.filesUploaded])

  useEffect(() => {
    if (
      props.paymentRequestDeatils !== undefined &&
      props.paymentRequestCreated === true
    ) {
      showToastSuccess("Payment Request Created.")
      setEmailSend(false)
      if (props.paymentRequestDeatils._id !== undefined) {
        if (emailType === "all") {
          const data = {
            id: props.paymentRequestDeatils._id,
            recipient: null,
            actionType: "Initial Send of Payment Request",
          }
          onEmailSending(data)
        }
        if (emailType === "user") {
          const data = {
            id: props.paymentRequestDeatils._id,
            recipient: user.email,
            actionType: "Initial Send of Payment Request",
          }
          onEmailSending(data)
        }
        if (emailType === "whatsapp") {
          const data = {
            id: props.paymentRequestDeatils._id,
            recipient: null,
            actionType: "Initial Send of Payment Request",
          }
          onEmailSending(data)
          let mobile = customerDetials.mobile || customerDetials.Mobile
          let newMobile = mobile.replace("-", "")
          let name = customerDetials.name || customerDetials.Name
          let newName = name.split(" ").join("%20")
          let whatAppMessage =
            "Payment%20Request%20for%20" +
            newName +
            ".%20" +
            props.paymentRequestDeatils.generatedLinkShort
          let whatsAppUrl =
            "https://wa.me/" + newMobile + "?text=" + whatAppMessage
          const whatsApp = {
            PhoneNumber: newMobile,
            pr_id: props.paymentRequestDeatils._id,
            Message: whatAppMessage,
          }
          onSetWhatsApp(whatsApp)

          window.location.href = whatsAppUrl
        }
      }
    }
  }, [props.paymentRequestDeatils, props.paymentRequestCreated, emailType])

  useEffect(() => {
    if (props.emailStatusNonExpress === true) {
      setEmailStatus(props.emailStatusNonExpress)
      setmodal_xlarge(true)
    }
    if (props.emailStatusNonExpress === false) {
      setEmailStatus(props.emailStatusNonExpress)
      setmodal_xlarge(true)
    }
  }, [props.emailStatusNonExpress])

  useEffect(() => {
    if (props.itemId !== undefined && emailSend === true) {
      handleCreatePR(prDetails)
    }
  }, [prDetails, props.itemCreated, props.itemId, emailSend])

  useEffect(() => {
    //console.log(modal_xlarge)
  }, [modal_xlarge])

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(30)
        }
        if (tab === 2) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(60)
        }
        if (tab === 3) {
          // triggerSubmitButton("my-form_submit_2")
          setprogressValue(100)
        }
      }
    }
  }

  function handlePaymentRequestChange(changes) {
    setPrDetails(changes)
  }

  function handlePurchasedItemChange(changes) {
    setItemDetails(changes)
  }

  const handleSubmit = async value => {
    setEmailType(value)

    if (itemDetails.Items[0].Qty !== 0) {
      onSetItems(itemDetails)
    } else {
      onSetPaymentRequest(prDetails)
    }
    setEmailSend(true)
  }

  const handleCreatePR = async prToCreate => {
    //console.log(prToCreate)
    if (prToCreate.ItemId !== "" && prToCreate.ItemId !== undefined) {
      onSetPaymentRequest(prToCreate)
      setEmailSend(false)
    }
  }

  const loadSubmitNowButton = () => {
    //TODO: AND LOADING INDICATOR WHILE LOADING

    return (
      <>
        <Row className="item-center">
          <small>
            *To complete your request, select below options to send*
          </small>
        </Row>
        <Row>
          <ReactTooltip place="bottom" type="dark" effect="solid" />
          <div style={{ margin: "5px" }}>
            <Button
              data-tip="<div><small>Send invoice to customer Whatsapp.</small></div> "
              data-html={true}
              type="submit"
              className="color-whatsapp border-none"
              onClick={() => handleSubmit("whatsapp")}
            >
              <img src={WhatsAppLogo} className="whatsapplogo" />
              WhatsApp
            </Button>
          </div>
          <div style={{ margin: "5px" }}>
            <Button
              data-tip="<div><small>Sends out request</small> </div> <div><small>to your email without</small></div>  <div><small> notifying the customer.</small></div> "
              data-html={true}
              type="submit"
              color="primary"
              onClick={() => handleSubmit("user")}
            >
              SEND TO MY EMAIL
            </Button>
          </div>
          <div style={{ margin: "5px" }}>
            <Button
              data-tip="<div><small>Sends out request</small> </div> <div><small>to customer including you</small></div>  <div><small> and the registered</small></div>  <div><small> notification emails.</small></div> "
              data-html={true}
              type="submit"
              color="success"
              onClick={() => handleSubmit("all")}
            >
              SEND TO CUSTOMER
            </Button>
          </div>
        </Row>
      </>
    )
  }

  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }

  const showToastError = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.error(message)
  }

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal_xlarge}
        centered={true}
        // toggle={() => {
        //   tog_xlarge()
        // }}
      >
        {emailStatus ? (
          <div className="modal-header item-center bg-color-active">
            <Container fluid className="item-center">
              <Row>
                <Col md={12}>
                  <Label className="color-white text-size-notification  text-align-center">
                    <i className="fas fa-check text-size-notification-icon mr-2" />
                    REQUEST SUCCESSFULLY SENT
                  </Label>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="modal-header  bg-color-danger ">
            <Container fluid className="item-center">
              <Row>
                <Col md={12}>
                  <Label className="color-white text-size-notification mr-2 text-align-center">
                    <i className="mdi mdi-close-thick text-size-notification-icon" />
                    REQUEST FAILED TO SEND
                  </Label>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        <ModalBody>
          <OverviewPopup onChange={value => setmodal_xlarge(value)} />
        </ModalBody>
      </Modal>
      {disable === true ? (
        <SweetAlert
          warning
          confirmBtnText="Close"
          confirmBtnBsStyle="danger"
          title="Sorry, this service is temporarily unavailable."
          onConfirm={() => {
            window.location = "/dashboard"
          }}
          closeOnClickOutside={() => {
            window.location = "/dashboard"
          }}
        ></SweetAlert>
      ) : null}
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Payment Request"
            breadcrumbItem="Send Payment Request"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                      <NavItem style={{ color: "#b3b3b3" }}>
                        <NavLink
                          // TODO: if u want to enable the header navigation just comment our style and uncomment onclick , for now diabled lang muna let them use the next button
                          style={{ cursor: "default", color: "#b3b3b3" }}
                          className={classnames({
                            active: activeTabProgress === 1,
                          })}
                          // onClick={() => {
                          //   toggleTabProgress(1)
                          //   //   checkMandatory(1)
                          // }}
                        >
                          <span className="step-number mr-2">01</span>
                          Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "default", color: "#b3b3b3" }}
                          className={classnames({
                            active: activeTabProgress === 2,
                          })}
                          // onClick={() => {
                          //   //  checkMandatory(2)
                          //   toggleTabProgress(2)
                          // }}
                        >
                          <span className="step-number mr-2">02</span>
                          <span>Configurations</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "default", color: "#b3b3b3" }}
                          className={classnames({
                            active: activeTabProgress === 3,
                          })}
                          // onClick={() => {
                          //   //   checkMandatory(3)
                          //   toggleTabProgress(3)
                          // }}
                        >
                          <span className="step-number mr-2">03</span>
                          Summary
                        </NavLink>
                      </NavItem>
                    </ul>

                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                      <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
                    </div>
                    {prLoading ? (
                      <LoadingSpinnerThreeDots />
                    ) : (
                      <TabContent
                        activeTab={activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <FirstPaymentRequestDetails
                            commissionSettingsPaymentTypes={
                              commissionSettingsPaymentTypes
                            }
                            prDetails={prDetails}
                            itemDetails={itemDetails}
                            handlePaymentRequestChange={
                              handlePaymentRequestChange
                            }
                            handlePurchasedItemChange={
                              handlePurchasedItemChange
                            }
                            toggleTabProgress={toggleTabProgress}
                            activeTabProgress={activeTabProgress}
                            loadSubmitNowButton={loadSubmitNowButton}
                          />
                        </TabPane>
                        <TabPane tabId={2}>
                          <SecondConfiguration
                            prDetails={prDetails}
                            handlePaymentRequestChange={
                              handlePaymentRequestChange
                            }
                            toggleTabProgress={toggleTabProgress}
                            activeTabProgress={activeTabProgress}
                            loadSubmitNowButton={loadSubmitNowButton}
                            // onChange={value => setselectedFiles(value)}
                          />
                        </TabPane>

                        <TabPane tabId={3}>
                          <ThirdSummary
                            prDetails={prDetails}
                            itemDetails={itemDetails}
                            toggleTabProgress={toggleTabProgress}
                            activeTabProgress={activeTabProgress}
                            loadSubmitNowButton={loadSubmitNowButton}
                          />
                        </TabPane>
                      </TabContent>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
PaymentRequestCreation.propTypes = {}

const mapStateToProps = state => {
  return {
    selectedCustomer: state.Customer.customer,
    customerCreated: state.Customer.customerCreated,
    itemId: state.Items.ItemId,
    itemCreated: state.Items.itemCreated,
    paymentTypes: state.paymentRequests.paymentTypes,
    paymentRequestDeatils: state.paymentRequests.paymentRequestDetail,
    paymentRequestCreated: state.paymentRequests.paymentRequestCreated,
    emailStatusNonExpress: state.paymentRequests.emailStatusNonExpress,
    prLoading: state.paymentRequests.loading,
    files: state.paymentRequests.files,
    filesUploaded: state.paymentRequests.filesUploaded,
    merchantDetail: state.Merchants.merchantDetail,
    paymentGateway: state.PaymentGateway.paymentGateway,
  }
}

const mapDispatchToProps = dispatch => ({
  // onGetPaymentGateways: () => dispatch(getPaymentGatewayList()),
  // onSetUser: (data) => dispatch(setUser(data)),
  // onSetMerchant: (data) => dispatch(setMerchant(data))
  onSetItems: items => dispatch(setItem(items)),
  onSetPaymentRequest: data => dispatch(setPaymentRequest(data)),
  onEmailSending: data => dispatch(sendPaymentRequestEmailNonExpress(data)),
  onSetWhatsApp: data => dispatch(setWhatAppLog(data)),
  onGetMerchant: id => dispatch(getMerchantDetail(id)),
  onSetPaymentTypes: data => dispatch(setPaymentTypes(data)),
  // onGetPaymentGateway: (id) => dispatch(getPaymentGatewayDetail(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentRequestCreation))
