import React from "react"
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import dateFormat from '../../../helpers/commonhelpers/dateFormat'

const UserColumns = (user) => {
  const isHidden = () => {
 
    if(user.role !== "SuperAdmin") 
      return true
    else return false
  }
  
  const isUser = () => {
 
    if(user.role === "User") 
      return true
    else return false
  }
  
  return(
    [
      {
        dataField:"merchantId",
        text:"Merchant Id",
        sort:true,
        hidden:isHidden()
      },
      {
        dataField: "userId",
        text: "User Id",
        sort: true,
      },
      {
        dataField: "title",
        text: "Title",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "userName",
        text: "Username",
        sort: true,
      },
      {
        dataField:"password",
        text:"Password",
        sort:true,
        hidden:isHidden()
      },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "mobile",
        text: "Mobile",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "registrationDate",
        text: "Registration Date",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span>
            {dateFormat(cellContent)}
            </span>
          )
        }
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
      },
      {
        dataField: "department",
        text: "Department",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null || cellContent === "") {
            return <>N/A</>
          }
          else{
            return <>{cellContent}</>
          }
        }
      },
      {
        dataField: "isActive",
        text: "Active",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === true) {
            return <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          }
          if (cellContent === false) {
            return <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          }
        }
      },
    //   {
    //     dataField: "_id",
    //     text: "Action",
    //     hidden:isUser(),
    //     formatter: (cellContent, row) => {
    //       if (row.isActive === true) {
    //         return (
    //           <UncontrolledDropdown >
    //             <DropdownToggle href="#" className="card-drop" tag="i">
    //               <i className="mdi mdi-dots-horizontal font-size-18" />
    //             </DropdownToggle>
    //             <DropdownMenu right>
    //               <DropdownItem onClick={() => handleActionTableUser("update")}>
    //                 <i className="mdi mdi-pencil font-size-18 mr-1 color-primary" id="edittooltip" />
    //                Update User
    //               </DropdownItem>
               
    //                 <DropdownItem href={"/profile/"+row.userId}>
    //                   <i className="mdi mdi-eye font-size-18 text-success mr-1" />
    //                   View User
    //               </DropdownItem>
                
    //               <DropdownItem onClick={() => handleActionTableUser("delete")}>
    //                 <i className="mdi mdi-close font-size-18 mr-1 color-danger" id="deletetooltip" />
    //             Deactivate User
    //               </DropdownItem>
    
    //             </DropdownMenu>
    
    //           </UncontrolledDropdown>
    //         )
    //       }
    //       if (row.isActive === false) {
    //         return (
    //           <UncontrolledDropdown>
    //             <DropdownToggle href="#" className="card-drop" tag="i">
    //               <i className="mdi mdi-dots-horizontal font-size-18" />
    //             </DropdownToggle>
    //             <DropdownMenu right>
    //               <DropdownItem onClick={() => handleActionTableUser("update")}>
    //                 <i className="mdi mdi-pencil font-size-18 mr-1 color-primary" id="edittooltip" />
    //                Update User
    //               </DropdownItem>
    //               <DropdownItem href={"/profile/"+row.userId}>
    //                   <i className="mdi mdi-eye font-size-18 text-success mr-1" />
    //                   View User
    //               </DropdownItem>
    //               <DropdownItem onClick={() => handleActionTableUser("activate")}>
    //                 <i className="fas fa-plus font-size-15 mr-1 color-active" id="deletetooltip" />
    //             Activate User
    //               </DropdownItem>
    
    //             </DropdownMenu>
    
    //           </UncontrolledDropdown>
    //         )
    
    //       }
    
    //     },
    //   },
    ]
  )
}

export default UserColumns
