import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_CUSTOMER_LIST_ARCHIVED,
  PATCH_CUSTOMER_ARCHIVE,
} from "./actionTypes"
import {
  getCustomerListArchivedSuccess,
  getCustomerListArchivedFail,
  patchCustomerArchiveSuccess,
  patchCustomerArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getCustomerListArchived,
  patchCustomerArchive,
} from "helpers/fakebackend_helper"

function* _getCutomerListArchived({ payload: data }) {
  try {
    const response = yield call(getCustomerListArchived, data)
    if (response) {
      yield put(getCustomerListArchivedSuccess(response))
    } else {
      yield put(getCustomerListArchivedFail(response))
    }
  } catch (error) {
    yield put(getCustomerListArchivedFail(error))
  }
}

function* _patchCutomerArchive({ payload: data }) {
  try {
    const response = yield call(patchCustomerArchive, data)
    if (response) {
      yield put(patchCustomerArchiveSuccess(response))
    } else {
      yield put(patchCustomerArchiveFail(response))
    }
  } catch (error) {
    yield put(patchCustomerArchiveFail(error))
  }
  yield put(returnState())
}

function* cutomerArchiveSaga() {
  yield takeEvery(GET_CUSTOMER_LIST_ARCHIVED, _getCutomerListArchived)
  yield takeEvery(PATCH_CUSTOMER_ARCHIVE, _patchCutomerArchive)
}

export default cutomerArchiveSaga
