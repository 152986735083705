import React, { useEffect, useState } from "react"
import PRArchivedTable from "./PaymentRequestArchivedTable"
import { connect } from "react-redux"
import {
  getPaymentRequestListArchived,
  patchPaymentRequestArchive,
} from "store/actions"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SweetAlert from "react-bootstrap-sweetalert"
function index(props) {
  const {
    onGetPaymentRequestListArchived,
    loading,
    paymentRequestListArchived,
    onPatchPaymentRequestArchive,
    paymentRequestArchiveResult,
  } = props

  const [archivePR, setArchivePR] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  useEffect(() => {
    onGetPaymentRequestListArchived()
  }, [])
  useEffect(() => {
    // console.log(archivePR)
    if (archivePR && archivePR !== undefined) {
      onPatchPaymentRequestArchive(archivePR)
    }
  }, [archivePR])
  useEffect(() => {
    //console.log(paymentRequestListArchived)
  }, [paymentRequestListArchived])
  useEffect(() => {
    if (paymentRequestArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("User has been restored.")
    }
    if (paymentRequestArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("User is not restored.")
    }
  }, [paymentRequestArchiveResult])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Payment Requests Archived" />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
                window.location.reload()
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          <PRArchivedTable
            paymentRequestList={paymentRequestListArchived}
            onChange={value => setArchivePR(value)}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  paymentRequestListArchived: state.PRArchived.paymentRequestListArchived,
  loading: state.PRArchived.loading,
  paymentRequestArchiveResult: state.PRArchived.paymentRequestArchiveResult,
})

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestListArchived: () =>
    dispatch(getPaymentRequestListArchived()),
  onPatchPaymentRequestArchive: id => dispatch(patchPaymentRequestArchive(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
