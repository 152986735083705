import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MERCHANT_DETAIL,
  GET_MERCHANT_LIST,
  GET_MERCHANT_LIST_REPORT,
  GET_MERCHANT_IDS,
  SET_MERCHANT,
  GET_MERCHANT_DETAIL_EXTERNAL,
  REGISTER_MERCHANT,
} from "./actionTypes"

import {
  getMerchantDetailSuccess,
  getMerchantDetailFail,
  getMerchantListSuccess,
  getMerchantListFail,
  getMerchantListReportSuccess,
  getMerchantListReportFail,
  getAllMerchantIdsSuccess,
  getAllMerchantIdsFail,
  setMerchantSuccess,
  setMerchantFail,
  registerMerchantSuccess,
  registerMerchantFail,
  returnStateMerchant,
  getMerchantDetialExternalSuccess,
  getMerchantDetialExternalFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMerchantDetail,
  getMerchantList,
  getMerchantListReport,
  getMerchantIds,
  setMerchant,
  registerMerchant,
  getMerchantDetialExternal,
} from "helpers/fakebackend_helper"

function* fetchMerchantDetails({ merchantId }) {
  try {
    const response = yield call(getMerchantDetail, merchantId)
    if (response) {
      yield put(getMerchantDetailSuccess(response))
    }
  } catch (error) {
    yield put(getMerchantDetailFail(error))
  }
}

function* _getMerchantList() {
  try {
    const response = yield call(getMerchantList)
    if (response) {
      yield put(getMerchantListSuccess(response))
    }
  } catch (error) {
    yield put(getMerchantListFail(error))
  }
}

function* fetchMerchantIds() {
  try {
    const response = yield call(getMerchantIds)
    if (response) {
      yield put(getAllMerchantIdsSuccess(response))
    } else {
      yield put(getAllMerchantIdsFail(response))
    }
  } catch (error) {
    yield put(getAllMerchantIdsFail(error))
  }
}

function* _setMerchant({ payload: merchant }) {
  try {
    const response = yield call(setMerchant, merchant)
    if (response) {
      yield put(setMerchantSuccess(response))
    } else {
      yield put(setMerchantFail(response))
    }

    // returnStateMerchant()
  } catch (error) {
    yield put(setMerchantFail(error))
  }
}

function* _registerMerchant({ payload: merchant }) {
  try {
    const response = yield call(registerMerchant, merchant)
    if (response) {
      yield put(registerMerchantSuccess(response))
    } else {
      yield put(registerMerchantFail(response))
    }

    returnStateMerchant()
  } catch (error) {
    yield put(registerMerchantFail(error))
  }
}

function* _getMerchantListReport() {
  try {
    const response = yield call(getMerchantListReport)

    if (response) {
      yield put(getMerchantListReportSuccess(response))
    }
  } catch (error) {
    yield put(getMerchantListReportFail(error))
  }
}

function* _getMerchantDetailExternal({ payload: merchantDetail }) {
  try {
    const response = yield call(getMerchantDetialExternal, merchantDetail)
    if (response) {
      yield put(getMerchantDetialExternalSuccess(response))
    } else {
      yield put(getMerchantDetialExternalFail(response))
    }
  } catch (err) {
    yield put(getMerchantDetialExternalFail(err))
  }
}

function* paymentResponsesSaga() {
  yield takeEvery(GET_MERCHANT_DETAIL, fetchMerchantDetails)
  yield takeEvery(GET_MERCHANT_DETAIL_EXTERNAL, _getMerchantDetailExternal)
  yield takeEvery(GET_MERCHANT_LIST, _getMerchantList)
  yield takeEvery(GET_MERCHANT_IDS, fetchMerchantIds)
  yield takeEvery(SET_MERCHANT, _setMerchant)
  yield takeEvery(REGISTER_MERCHANT, _registerMerchant)
  yield takeEvery(GET_MERCHANT_LIST_REPORT, _getMerchantListReport)
}

export default paymentResponsesSaga
