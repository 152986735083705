import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  Label,
  Card,
  CardBody,
  CardTitle,
  Container,
  ModalHeader,
  Modal,
  ModalBody,
  CardHeader,
} from "reactstrap"
import QRCode from "qrcode.react"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import AuthUser from "components/AuthUser/AuthUser"
import { sendPaymentRequestEmailExpress } from "store/actions"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
function OverViewExpressPayRequest(props) {
  const { paymentRequestDetailExpress, isOpen, toggle, prExpress } = props
  //Constants------------------------------------------------------------------------------------------------------------
  // const [{ Amount, Currency, Name, Email, Remarks, shortLink, emailSendingStatusTrue, emailSendingStatusFalse, modal_large }, setPaymentRequest] = useState({
  //     Amount: props.Amount,
  //     Currency: props.Currency,
  //     Remarks: props.Remarks,
  //     Name: props.Name,
  //     Email: props.Email,
  //     shortLink: props.shortLink,
  //     emailSendingStatusTrue: false,
  //     emailSendingStatusFalse: false,
  //     modal_large: false

  // })
  // const [user, setUser] = useState(AuthUser())
  // const dispatch = useDispatch();

  //Use Effects--------------------------------------------------------------------------------------
  //Use Effect for reloading component when shortlink and paymentrequestid is in
  // useEffect(() => {
  // }, [props.shortLink])
  // useEffect(() => {

  //     if (props.boolEmailAlertTrue === true) {
  //         setPaymentRequest(formRequest => ({
  //             ...formRequest,
  //             emailSendingStatusTrue: props.boolEmailAlertTrue
  //         }))
  //     }
  //     if (props.boolEmailAlertFalse === true) {
  //         setPaymentRequest(formRequest => ({
  //             ...formRequest,
  //             emailSendingStatusFalse: props.boolEmailAlertFalse
  //         }))
  //     }
  // }, [props.boolEmailAlertTrue, props.boolEmailAlertFalse])

  useEffect(() => {
    //console.log(prExpress)
  }, [prExpress])

  useEffect(() => {
    //console.log(paymentRequestDetailExpress)
  }, [paymentRequestDetailExpress])
  //Functions----------------------------------------------------------------------------------------------

  //Function for copying shortLink
  const CopyTinyURL = () => {
    showToastInfo("Link copied.")
    navigator.clipboard.writeText(
      paymentRequestDetailExpress?.generatedLinkShort
    )
  }

  const showToastInfo = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.info(message)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="md"
    >
      <ModalHeader toggle={toggle}>
        Overview Express Payment Request
      </ModalHeader>
      <ModalBody>
        <Row className="form-group mx-1 item-center">
          <Col lg="12" className="mx-3">
            <ReactTooltip place="bottom" type="dark" effect="solid" />
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    {prExpress === true ? (
                      <Container className="item-center bg-color-active py-3">
                        <Label className="color-white text-size-notification  text-align-center">
                          <i className="fas fa-check text-size-notification-icon mr-2" />
                          REQUEST SUCCESS
                        </Label>
                      </Container>
                    ) : null}
                    {prExpress === false ? (
                      <Container className="item-center  bg-color-danger py-3">
                        <Label className="color-white text-size-notification mr-2 text-align-center">
                          <i className="mdi mdi-close-thick text-size-notification-icon" />
                          REQUEST FAILED
                        </Label>
                      </Container>
                    ) : null}
                  </CardHeader>
                  <CardBody>
                    <CardTitle className="text-align-center bg-color-secondary py-2 mx-auto">
                      <h1>
                        {parseFloat(
                          paymentRequestDetailExpress?.amount
                        ).toFixed(2)}{" "}
                        {paymentRequestDetailExpress?.currency}
                      </h1>
                      AMOUNT DUE FOR{" "}
                      {paymentRequestDetailExpress.customerDetials?.name} <br />{" "}
                      {paymentRequestDetailExpress.customerDetails?.email}
                    </CardTitle>
                    <Row>
                      <Col lg="12" className="item-center">
                        Remarks:{" "}
                        {paymentRequestDetailExpress?.remarks === "" ||
                        paymentRequestDetailExpress?.remarks === undefined
                          ? "N/A"
                          : paymentRequestDetailExpress?.remarks}
                      </Col>
                    </Row>
                    <Row className=" item-center">
                      <Col lg="12">
                        <div className="app-search  d-lg-block pointer">
                          <div
                            className="position-relative"
                            onClick={CopyTinyURL}
                          >
                            <input
                              type="text"
                              className="form-control pointer"
                              value={
                                paymentRequestDetailExpress?.generatedLinkShort ===
                                undefined
                                  ? "HTTPS://PAYALLLINK.COM"
                                  : paymentRequestDetailExpress?.generatedLinkShort
                              }
                              placeholder="HTTPS://PAYALLLINK.COM"
                              disabled
                            />
                            <span className="mdi mdi-content-copy"></span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={5} className="item-center">
                        <QRCode
                          value={
                            paymentRequestDetailExpress?.generatedLinkShort ===
                            undefined
                              ? "HTTPS://PAYALLLINK.COM"
                              : paymentRequestDetailExpress?.generatedLinkShort
                          }
                          className="qr-code-express  "
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="item-center">
                <div style={{ margin: "5px" }}>
                  <Button
                    type="submit"
                    color="danger"
                    className=" border-none"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => {
  // console.log(state.paymentRequest.emailStatusNonExpress,state.paymentRequest.emailStatusExpress)
  return {
    paymentRequestDetailExpress:
      state.paymentRequests.paymentRequestDetailExpress,
    //paymentRequestLoading: state.paymentRequests.loading,
  }
}

export default connect(mapStateToProps)(OverViewExpressPayRequest)
//  export default OverViewExpressPayRequest
