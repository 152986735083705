import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import LoadingSpinnerThreeDots from '../../helpers/commonhelpers/loadingSpinner'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Merchants from './MerchantList'
import { getMerchantList, getMerchantListReport } from 'store/actions'
import { CSVLink } from 'react-csv'
import AuthUser from "components/AuthUser/AuthUser"

export const MerchantList = (props) => {


    const {
        onGetMerchantList,
        onGetMerchantListReport,
        merchantList,
        merchantListReport,
        loading,
    } = props

    const csvLink = useRef()
    const [user, setUser] = useState(AuthUser())

    useEffect(() => {
        if(user.role === "SuperAdmin"){
            onGetMerchantList()}
    }, [])

    useEffect(() => {

        onGetMerchantListReport()
    }, [])

    useEffect(() => {
       
    }, [merchantListReport])


    const handleReportDownload = () => {

        if (!isEmpty(merchantListReport) && merchantListReport != undefined) {
            csvLink.current.link.click()
        }
       
    }

    return (


        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Overview " breadcrumbItem=" MERCHANT LISTS" />

                    <CSVLink

                        data={merchantListReport || []}
                        filename='Merchants.csv'
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />

                    <Row>
                        <Col lg="12">

                            {loading ?
                                <LoadingSpinnerThreeDots />
                                :
                                <Merchants merchantList={merchantList} handleReportDownload={handleReportDownload} />
                            }
                        </Col>

                    </Row>



                </Container>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = state => (

    {
        merchantList: state.Merchants.merchantList,
        merchantListReport: state.Merchants.merchantListReport,
        loading: state.Merchants.loading
    })

const mapDispatchToProps = dispatch => ({
    onGetMerchantList: () => dispatch(getMerchantList()),
    onGetMerchantListReport: () => dispatch(getMerchantListReport())
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantList)
