import React, { useState, useContext, useEffect } from "react"
import { Card, CardBody, Col, FormGroup, InputGroup, Label, Row } from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import Select from "react-select"
import makeAnimated from "react-select/animated"

//Toasttify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentRequestContext } from './index'
//UseForm
import { useForm } from "react-hook-form";

const animatedComponents = makeAnimated()

const optionGroup2 = [
  {
    label: "Payment Status",
    options: [
      { label: "Success", value: "Success" },
      { label: "Failed", value: "Failed" },
      { label: "Unpaid", value: "Unpaid" }
    ]
  },
  {
    label: "Source",
    options: [
      { label: "Portal", value: "PORTAL" },
      { label: "SellCo", value: "SELLCO" },
      { label: "POS", value: "POS" }
      
    ]
  },
  {
    label: "Link Status",
    options: [
      { label: "Active", value: "Active" },
      { label: "Expired", value: "Expired" }
    ]
  }
]




const whiteText = {
  color: 'white',
};

const multi = {

  filtered: [],
  select2: undefined

}

export default function SearchForm(props) {


  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();
  const { handleFilterActions, handleDownloadReport } = useContext(PaymentRequestContext)
  const [value, setValue] = useState("")

  useEffect(() => {

  }, [selectedMultiSelect, selectedDateRange])



  function onFilteredChangeCustom(value, accessor) {

    let filtered = selectedMultiSelect.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    setselectedMultiSelect({ filtered: filtered });
    
  };


  function handleDateRange(selectedDates) {

    setSelectedDateRange(selectedDates)
    
  }

  function handleReset() {
    //setselectedMultiSelect({select2: undefined})
    //document.getElementsByClassName("multiSelect__value-container--has-value").innerHtml = null
    // document.querySelectorAll(
    //   '.multiSelect__value-container div:not(.css-b8ldur-Input):not(.multiSelect__input)').forEach(s => s.remove())
    //document.getElementsByClassName("multiSelect__clear-indicator").onClick()
    //  document.querySelector('.multiSelect__clear-indicator').click()
    window.location.reload();
    handleFilterActions([], [])
    
  }



  return (

    <Card>
      <CardBody>



        <Row>

          <Col lg="4">

            <FormGroup className="mb-4">
              <Label>Select Payment Request Date Range</Label>
              <InputGroup>
                <Flatpickr
                  name="selectedDates"
                  className="form-control d-block"
                  placeholder="Select Date Range"
                  options={{
                    mode: "range",
                    altFormat: "Y-m-d",
                    dateFormat: "F j, Y"
                  }}
                  onChange={(selectedDates) => handleDateRange(selectedDates)}
                // ref={register({ required: true })}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="mb-0 templating-select select2-container">
              <label className="control-label">Select Filters</label>
              <Select
                name="multiSelect"
                id="multiSelect"
                value={selectedMultiSelect.select2}
                isMulti={true}
                // ref={register({ required: true })}
                onChange={(entry) => {

                  if (entry) {
                    setselectedMultiSelect({ select2: entry })
                    //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                    onFilteredChangeCustom(
                      entry.map(o => { return o.value }), "entry"
                    );
                  }

                }}
                options={optionGroup2}
                // options={selectedMultiSelect.data.map((o, i) => {
                //   return { id: i, value: o.firstName, label: o.firstName };
                // })}
                classNamePrefix="multiSelect"
                closeMenuOnSelect={false}
                components={animatedComponents}
              />
            </FormGroup>
          </Col>

          <Col lg="1">

            <FormGroup className="mb-0 templating-select select2-container">
              <Row>    <label className="control-label" style={whiteText}>Button</label> </Row>
              <Row>

                <button
                  type="button"
                  className="btn btn-primary waves-effect btn-label waves-light w-md"
                  onClick={() => handleFilterActions(selectedMultiSelect, selectedDateRange) }
                >
                  <i className="bx bx-search-alt label-icon"></i> Search
                          </button>
              </Row>



            </FormGroup>

          </Col>
          <Col lg="2">
            <FormGroup className="mb-0 templating-select select2-container">
              <Row><label className="control-label" style={whiteText}> Button</label> </Row>
              <Row>

                <button
                  type="button"
                  style={{ minWidth: "100px" }}
                  className="btn btn-success waves-effect btn-label waves-light w-md"
                  onClick={() => handleDownloadReport(selectedMultiSelect, selectedDateRange)}
                >
                  <i className="bx bx-download label-icon"></i>{" "}
                            Download CSV
                          </button>


              </Row>
              {/* <label className="control-label" style={whiteText}> Download CSV</label> */}



            </FormGroup>

          </Col>
          <Col lg="1">

            <FormGroup className="mb-0 templating-select select2-container">

              <Row><label className="control-label" style={whiteText}>Reset</label> </Row>
              <Row>

                <button
                  type="button"
                  className="btn btn-danger waves-effect btn-label waves-light w-md"
                  onClick={() => handleReset()}
                >
                  <i className="bx bx-reset label-icon "></i> Reset
                          </button>

              </Row>




            </FormGroup>

          </Col>




        </Row>


      </CardBody>
    </Card>


  )
}
