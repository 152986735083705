import React, { useEffect, useState, useRef } from "react"
import {
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import Header from "./Header"
import PurchaseDetails from "./PurchaseDetails"
import PaymentDetails from "./PaymentDetails"
import PaidInvoice from "./PaidInvoice"
import PaymentMethod from "./PaymentMethod"
import Footer from "./Footer"
import FooterBanner from "./FooterBanner"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"
import { withTranslation } from "react-i18next"
import {
  getPaymentRequestDetailPublic,
  setPaymentMethod,
  setDeviceFingerPrint,
  setMyfatoorahInitiateSessionData,
} from "store/actions"
import ExpiredInvoice from "./ExpiredInvoice"
import ServicesOffered from "./ServicesOffered"
import MoreInformation from "./MoreInformation"
import SweetAlert from "react-bootstrap-sweetalert"
import { isEmpty } from "lodash"

export const PaymentMethodContext = React.createContext()
//TODO: rtl

const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

const merchantDeviceFingerPrintInitial = {
  MerchantReference: "",
  DeviceFingerPrint: ""
}

const tokenizationDetailsInitial = {
  signature: "",
  service_command: "",
  access_code: "",
  merchant_identifier: "",
  merchant_reference: "",
  language: "en",
  card_number: "",
  expiry_date: "",
  card_security_code: "",
  card_holder_name: "",
  token_name: "",
  // "remember_me": "YES",
  // "return_url": "https://www.google.com/",
}

const paymentMethodsettings = {
  objectId: "",
  paymentType: "credit",
}

const Invoice = props => {
  const {
    match: { params },
    paymentRequestDetail,
    loading,
    paymentRedirectionLink,
    onGetPaymentRequestDetail,
    onSetDeviceFingerPrint,
    onSetMyfatoorahInitiateSessionData,
    initiatedSessionRedirectUrlResponse,
    error,
  } = props

  console.log(paymentRequestDetail)

  const myDeviceFingerPrintRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(loading)
  const [hasSuccess, setHasSuccess] = useState(false)
  const [pr, setPr] = useState()
  const dispatch = useDispatch()
  const [defaultLang, setDefaultLang] = useState(currentLanguage)
  const [redirectionLink, setRedirectionLink] = useState(undefined)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [tokenizationDetails, setTokenizationDetails] = useState(tokenizationDetailsInitial)
  const [loadingDf, setLoadingDf] = useState(true)
  const [merchantDeviceFingerPrintData, setMerchantDeviceFingerPrintData] = useState(merchantDeviceFingerPrintInitial)
  const [merchantRef, setMerchantRef] = useState("")

  const [initiatedSessionRedirectUrl, setInitiatedSessionRedirectUrl] = useState()
  const [myfatoorahInitiateSession, setMyfatoorahInitiateSession] = useState()



  useEffect(() => {
    onloadPaymentRequestDetails(params.id)
  }, [params, onGetPaymentRequestDetail])

 

  //load device fingerprint
  useEffect(() => {
    loadDeviceFingerPrint()
    
  }, [])

 
  useEffect(async() => {

    await sleep(2000)
    handleChange({
      DeviceFingerPrint: document.getElementById("device_fingerprint")?.value}
    )
  }, [])

  useEffect(() => {
    if(!isEmpty(initiatedSessionRedirectUrlResponse)){
   
      window.location.replace(initiatedSessionRedirectUrlResponse);
      //setInitiatedSessionRedirectUrl(initiatedSessionRedirectUrlResponse)
    }
  }, [initiatedSessionRedirectUrlResponse])



  useEffect(() => {
    if (merchantRef) {
      setMerchantRef(merchantRef)
    }

  }, [merchantRef])


  useEffect( () => {

    if (!isEmpty(merchantDeviceFingerPrintData.DeviceFingerPrint) && !isEmpty(merchantRef)) {
      setLoadingDf(false) 
    }

  }, [merchantDeviceFingerPrintData.DeviceFingerPrint, merchantRef, loadingDf])


  useEffect(() => {

    if (!isEmpty(merchantRef) && !isEmpty(merchantDeviceFingerPrintData.DeviceFingerPrint)) {
      saveDeviceFingerPrint()
    }
  }, [merchantRef, merchantDeviceFingerPrintData.DeviceFingerPrint])



  useEffect(() => {
    if (error !== undefined && !isEmpty(error) && error !== {}) {
      setErrorResponse(error)
    }
  }, [error])



  useEffect(() => {}, [errorResponse])



  useEffect(() => {
    if (paymentRequestDetail) {
      setPr(paymentRequestDetail)

      if (paymentRequestDetail?.apsCustomForm) {
        setTokenizationDetails(paymentRequestDetail?.apsCustomForm)
        setMerchantRef( paymentRequestDetail?.apsCustomForm?.merchant_reference)
        handleChange({ MerchantReference: paymentRequestDetail?.apsCustomForm?.merchant_reference})
      }

      if(paymentRequestDetail?.initiateSession){

        setMyfatoorahInitiateSession(paymentRequestDetail?.initiateSession)
      }

    }
  }, [paymentRequestDetail])


  useEffect(() => {
    if (
      pr?.paymentRequestDetails?.paymentRequestSettings?.language !== undefined
    ) {
      const configuredLang = pr?.paymentRequestDetails?.paymentRequestSettings?.language.toLowerCase()

      if (currentLanguage !== configuredLang) setDefaultLang(configuredLang)
    }
  }, [pr])

  useEffect(() => {
    if (defaultLang && defaultLang !== undefined) {
      localStorage.setItem("i18nextLng", defaultLang)
      localStorage.setItem("I18N_LANGUAGE", defaultLang)
    }
  }, [defaultLang])

  useEffect(() => {
    setIsLoaded(loading)
    checkForAnySuccessfulTransactions()
  }, [loading])

  useEffect(() => {
    setRedirectionLink(paymentRedirectionLink)
  }, [paymentRedirectionLink])

  useEffect(() => {
    if (redirectionLink && redirectionLink != "") {
      window.open(redirectionLink, "_self", "toolbar=0,location=0,menubar=0")
    }
  }, [redirectionLink])


  ////functions

  function handleChange(changes) {
    handleDeviceFingerPrintChange({ ...merchantDeviceFingerPrintData, ...changes })
  }

  function handleDeviceFingerPrintChange(changes) {
    setMerchantDeviceFingerPrintData(changes)
  }

  function sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  const saveDeviceFingerPrint = () => {
    var merchantReference = merchantRef
    var deviceFingerPrint = merchantDeviceFingerPrintData.DeviceFingerPrint

    const data = {
      merchantReference,
      deviceFingerPrint,
    }

    onSetDeviceFingerPrint(data)
  }

  const onloadPaymentRequestDetails = () => {
    if (params && typeof params.id === "string") {
      onGetPaymentRequestDetail(params.id)
    }
  }
  const loadDeviceFingerPrint = () => {
    let input = document.createElement("input")
    input.type = "hidden"
    input.id = "device_fingerprint"
    input.name = "device_fingerprint"
    input.value = ""

    document.body.appendChild(input)

    let snareScript = document.createElement("script")
    snareScript.src = "https://mpsnare.iesnare.com/snare.js"
    snareScript.async = true
    document.body.appendChild(snareScript)

    let variables = document.createElement("script")
    variables.innerHTML =
      'var io_bbout_element_id = "device_fingerprint"; var io_install_stm = false; var io_exclude_stm = 0; var io_install_flash = false; var io_enable_rip = true;'
    variables.async = true

    document.body.appendChild(variables)
  }

  function handleTokenizationDetailsChange(changes) {
    setTokenizationDetails(changes)
  }
  function handleCreditCard() {
    const data = {
      objectId: paymentRequestDetail.paymentRequestDetails?._id,
      paymentType: "credit",
    }
    if (data.objectId) dispatch(setPaymentMethod(data))
  }

  function handleDebitCard() {
    const data = {
      objectId: paymentRequestDetail?.paymentRequestDetails?._id,
      paymentType: "debit",
    }

    if (data.objectId) dispatch(setPaymentMethod(data))
  }

  function handleOoredoo() {
    const data = {
      objectId: paymentRequestDetail?.paymentRequestDetails?._id,
      paymentType: "omm",
    }
    dispatch(setPaymentMethod(data))
  }

  function checkForAnySuccessfulTransactions() {
    const hasSuccessStatus = paymentRequestDetail?.paymentHistoryDetails?.some(
      x => x.isSuccessful === true
    )
    if (hasSuccessStatus) {
      setHasSuccess(hasSuccessStatus)
    }
  }

  function renderMethodContent() {
    if (
      paymentRequestDetail?.paymentRequestDetails?.generatedLinkStatus !=
      "Expired"
    ) {
      return (
        <>
          <Row>
            <PaymentDetails
              amount={paymentRequestDetail?.paymentRequestDetails?.amount}
              currency={paymentRequestDetail?.paymentRequestDetails?.currency}
              termsConditionUrl={
                paymentRequestDetail?.merchantDetails?.termsConditionUrl
              }
            />
          </Row>

          {loadingDf == false && (
            <>
              <PaymentMethodContext.Provider value={PaymentMethodContextValue}>
                <PaymentMethod
                  paymentRequestDetails={
                    paymentRequestDetail?.paymentRequestDetails
                  }
                  tokenizationDetails={tokenizationDetails}

                  retrievedTokenDetailsCustom={
                    paymentRequestDetail?.apsCustomForm
                  }
                  retrievedTokenDetailsRedirect={
                    paymentRequestDetail?.apsRedirectForm
                  }

                  myfatoorahInitiateSession={
                    paymentRequestDetail?.initiateSession
                  }
                />
              </PaymentMethodContext.Provider>
            </>
          )}

          {loadingDf == true && <LoadingSpinnerThreeDots />}
        </>
      )
    } else {
      return (
        <>
          <Row className="mb-2 mt-2">
            <ExpiredInvoice
              amount={paymentRequestDetail?.paymentRequestDetails?.amount}
              currency={paymentRequestDetail?.paymentRequestDetails?.currency}
            />
          </Row>
        </>
      )
    }
  }

  ///context
  const PaymentMethodContextValue = {
    handleCreditCard,
    handleDebitCard,
    handleOoredoo,
    handleTokenizationDetailsChange,
    saveDeviceFingerPrint,
    onSetMyfatoorahInitiateSessionData,
  }
  return (
    <React.Fragment>
      {isLoaded && <LoadingSpinnerThreeDots />}
      {isLoaded === false && (
        <>
          <div
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: "100",
            }}
          >
            <LanguageDropdown />
          </div>

          {/*  <input type="hidden" name="device_fingerprint" id="device_fingerprint" value="" />


              <Helmet>
                  <script language="JavaScript">

                     window.io_bbout_element_id  = 'device_fingerprint',
                     window.io_install_stm       = false,
                     window.io_exclude_stm       = 0,
                     window.io_install_flash     = false,
                     window.io_enable_rip        = true;
                  </script>


                  <script src="https://mpsnare.iesnare.com/snare.js"></script>
               </Helmet> */}

          {errorResponse !== undefined && errorResponse !== {} ? (
            <>
              <SweetAlert
                error
                title=""
                onConfirm={() => {
                  setErrorResponse(undefined)
                  window.location.reload()
                }}
              >
                {errorResponse}
              </SweetAlert>
            </>
          ) : null}
          <div>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <div>
                <Container fluid>
                  <Card>
                    <CardBody>
                      <div className="justify-content-center container-alignment">
                        <Row>
                          <Header
                            paymentRequestDetail={
                              paymentRequestDetail?.paymentRequestDetails
                            }
                            merchantDetail={
                              paymentRequestDetail?.merchantDetails
                            }
                          />
                        </Row>

                        <hr></hr>
                        {paymentRequestDetail?.paymentRequestDetails
                          ?.paymentStatus === "Success" || hasSuccess ? (
                          <Row className="mb-1 mt-1">
                            <PaidInvoice
                              paidDetails={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.paidDetails
                              }
                              amount={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.amount
                              }
                              currency={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.currency
                              }
                            />
                          </Row>
                        ) : (
                          renderMethodContent()
                        )}
                      </div>
                      <div>
                        <hr></hr>
                        {paymentRequestDetail?.paymentRequestDetails
                          ?.isExpress === false && (
                          <Row>
                            <PurchaseDetails
                              source={
                                paymentRequestDetail?.paymentRequestDetails
                                  ?.itemSource
                              }
                              purchasedItems={
                                paymentRequestDetail?.purchasedItems
                              }
                            />
                          </Row>
                        )}
                        <Row>
                          <MoreInformation
                            servicesOffered={
                              paymentRequestDetail?.merchantDetails?.services
                            }
                            remarks={
                              paymentRequestDetail?.paymentRequestDetails
                                ?.remarks
                            }
                            user={paymentRequestDetail?.userDetails}
                            merchant={paymentRequestDetail?.merchantDetails}
                          />
                        </Row>
                        <Row>
                          <Footer
                            user={paymentRequestDetail?.userDetails}
                            merchant={paymentRequestDetail?.merchantDetails}
                          />
                        </Row>
                        <Row>
                          <FooterBanner
                            banner={
                              paymentRequestDetail?.merchantDetails?.banner
                            }
                          />
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Container>
              </div>
            </Col>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

Invoice.propTypes = {
  paymentRequestDetail: PropTypes.object,
  loading: PropTypes.bool,
  paymentRedirectionLink: PropTypes.string,
  match: PropTypes.object,
  onGetPaymentRequestDetail: PropTypes.func,
  error: PropTypes.object,
}

const mapStateToProps = pr => ({
  paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
  loading: pr.paymentRequests.loading,
  paymentRedirectionLink: pr.paymentRequests.paymentRedirectionLink,
  error: pr.paymentRequests.error,
  initiatedSessionRedirectUrlResponse:pr.paymentRequests.initiatedSessionRedirectUrlResponse

})

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestDetail: id => dispatch(getPaymentRequestDetailPublic(id)),
  onSetDeviceFingerPrint: data => dispatch(setDeviceFingerPrint(data)),
  onSetMyfatoorahInitiateSessionData: data => dispatch(setMyfatoorahInitiateSessionData(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Invoice)))
