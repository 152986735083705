import React from 'react'
import {

  Col,
  Row,
  Button
} from "reactstrap"

const Paypal = () => {
  return (
    <>
      <Col lg="12" style={{ background: "" }}>
        <Row>
          <Col style={{ "marginTop": "7rem" }}></Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-center" >
              <Button style={{ background: "blue", border: "none" }}>Paypal</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ "marginTop": "7rem" }}></Col>
        </Row>

      </Col>
    </>
  )


}

export default Paypal