import React from 'react'
import { Row, Col, Label } from "reactstrap"

const Header = (props) => {

    return (
        <div>
            {props?.status ?
                <Row className="paymentNotificationTitle paymentSuccess ">
                    <Col>
                        <Label className="mx-auto"><i className="fas fa-check fa-2x" /> SUCCESSFUL </Label>
                    </Col>
                </Row>
                :
                <Row className="paymentNotificationTitle paymentFailed ">
                    <Col>
                        <Label className="mx-auto"><i className="fas fa-times fa-2x" /> FAILED </Label>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default Header