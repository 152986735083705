import React, { useState } from "react"
import {
  Row,
  Col,
  Badge
} from "reactstrap"
import dateFormat from '../../../helpers/commonhelpers/dateFormat'

const CustomerColumns = (user) => {

  const isHidden = () => {
 
    if(user.role !== "SuperAdmin") 
      return true
    else return false
  }

  return(
    [
      {
        dataField: "merchantID",
        text: "Merchant Id",
        sort: true,
        hidden: isHidden()
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        text: " Email",
        dataField: "email",
        sort: true,
      },
      {
        dataField: "mobile",
        text: "Mobile",
        sort: true,
      },
      {
        dataField: "type",
        text: "Type",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === "Recurring") {
            return <Badge
              className={"font-size-12 badge-soft-primary"}
              color="primary"
              pill
            >
              {cellContent}
            </Badge>
          }
          if (cellContent === "New") {
            return <Badge
              className={"font-size-12 badge-soft-success"}
              color="sucess"
              pill
            >
              {cellContent}
            </Badge>
          }
        }
      },
      {
        dataField: "registrationDate",
        text: "Registration Date",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span>
             {dateFormat(cellContent)}
            </span>
          )
        }
      },
      {
        dataField: "isActive",
        text: "Active",
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === true) {
            return <Badge
              className={"font-size-12 badge-soft-success"}
              color="success"
              pill
            >
              {cellContent.toString()}
            </Badge>
          }
          if (cellContent === false) {
            return <Badge
              className={"font-size-12 badge-soft-danger"}
              color="danger"
              pill
            >
              {cellContent.toString()}
            </Badge>
          }
        }
      },
    //   {
    //     dataField: "_id",
    //     text: "Action",
    //     formatter: (cellContent, row) => {
    //       if(row.isActive === true){
    //         return <Row>
    //         <Col
    //           onClick={() => handleActionTableCustomer("update")}>
    //           <i className="mdi mdi-pencil font-size-18 mr-3 color-primary" id="edittooltip" />
    //         </Col>
    //         <Col
    //           onClick={() => handleActionTableCustomer("delete")}>
    //           <i className="mdi mdi-close font-size-18 mr-3 color-danger" id="deletetooltip" />
    //         </Col>
    //       </Row>
    //       }
    //       if(row.isActive === false){
    //         return <Row>
    //         <Col
    //           onClick={() => handleActionTableCustomer("update")}>
    //           <i className="mdi mdi-pencil font-size-18 mr-3 color-primary" id="edittooltip" />
    //         </Col>
    //         <Col
    //           onClick={() => handleActionTableCustomer("activate")}>
    //           <i className="fas fa-plus font-size-15 mr-3 color-active" id="deletetooltip" />
    //         </Col>
    //       </Row>
    //       }
    //     },
    //   },
    ]
  )
}

export default CustomerColumns
