import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Table, Button, Card, CardBody, Container, Row, Col, Label } from "reactstrap"

import PaymentResultDetails from './PaymentResultDetails'
import Banner from './Banner'
import Header from "./Header"
import LoadingSpinnerThreeDots from '../../helpers/commonhelpers/loadingSpinner'

import { getPaymentResponseDetail, getMerchantDetail } from "store/actions"



const PaymentResponse = (props) => {
  const {
    paymentResponseDetail,
    // merchantDetail,
    loading,
    match: { params },
    onGetPaymentResponseDetail,
    // onGetMerchantDetail
  } = props

  const [isLoaded, setIsLoaded] = useState(loading);

  useEffect(() => {
    if (params && params.trackId) {
      onGetPaymentResponseDetail(params.trackId)
    } else {

      

     }
  }, [params, onGetPaymentResponseDetail])


  // useEffect(() => {
  //  if(paymentResponseDetail){
  //   onGetMerchantDetail(paymentResponseDetail.merchantId)
  //  }
  // }, [paymentResponseDetail])

  // useEffect(() => {
  //   if(paymentResponseDetail){
 
  //    onGetMerchantDetail
  //   }
  //  }, [paymentResponseDetail])

  useEffect(() => {
    setIsLoaded(loading)

  }, [loading])


  return (
    <>
      <React.Fragment>
        <Container >
          {loading ?

            <LoadingSpinnerThreeDots />

            :
            <Row className="justify-content-center container-alignment">
              <Col md={12} >
                <Header status={paymentResponseDetail?.isSuccessful} />
                <PaymentResultDetails response={paymentResponseDetail} />
              
              
                <Banner banner={paymentResponseDetail.banner}/>
              </Col>
            </Row>
          }
        </Container>
      </React.Fragment>
    </>
  )
}



PaymentResponse.propTypes = {
  paymentResponseDetail: PropTypes.object,
  // merchantDetail: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  onGetPaymentResponseDetail: PropTypes.func,
  // onGetMerchantDetail: PropTypes.func,
}

const mapStateToProps = pr => (

  {
    paymentResponseDetail: pr.paymentResponses?.paymentResponseDetail,
    // merchantDetail: pr.Merchants.merchantDetail,
    loading: pr.paymentResponses?.loading
  })

const mapDispatchToProps = dispatch => ({
  onGetPaymentResponseDetail: trackId => dispatch(getPaymentResponseDetail(trackId)),
  // onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentResponse))
