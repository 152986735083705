
export const UPDATE_PAYMENT_HISTORY="UPDATE_PAYMENT_HISTORY"
export const UPDATE_PAYMENT_HISTORY_SUCCESS="UPDATE_PAYMENT_HISTORY_SUCCESS"
export const UPDATE_PAYMENT_HISTORY_FAIL="UPDATE_PAYMENT_HISTORY_FAIL"

export const SEND_NOTIFICATION_EMAIL="SEND_NOTIFICATION_EMAIL"
export const SEND_NOTIFICATION_EMAIL_SUCCESS="SEND_NOTIFICATION_EMAIL_SUCCESS"
export const SEND_NOTIFICATION_EMAIL_FAIL="SEND_NOTIFICATION_EMAIL_FAIL"


export const RESTART_STATE="RESTART_STATE"