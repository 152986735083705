import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Customer
import Customer from "./customer/reducer"

//Items
import Items from "./items/reducer"

//paymentrequests
import paymentRequests from "./paymentrequests/reducer"

//paymentrequests
import paymentResponses from "./paymentresponse/reducer"

//User
import Users from "./user/reducer"
//Merchant
import Merchants from "./merchant/reducer"

//Logs
import Logs from "./logs/reducer"

//PaymentGateway
import PaymentGateway from "./paymentgateway/reducer"

//PaymentHistory
import PaymentHistory from "./paymenthistory/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//MainSearch
import MainSearch from "./search/reducer"

//Options
import Options from "./options/reducer"

//Payment Request Archived
import PRArchived from "./paymentrequestsarchived/reducer"

//User Archive
import UserArchived from "./userarchived/reducer"

//Customer Archive
import CustomerArchived from "./customerarchived/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  paymentResponses,
  Customer,
  Merchants,
  Items,
  Users,
  paymentRequests,
  PaymentGateway,
  PaymentHistory,
  Logs,
  Dashboard,
  MainSearch,
  Options,
  PRArchived,
  UserArchived,
  CustomerArchived,
  // contacts,
})

export default rootReducer
