import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react"
import { Col, Form, FormGroup, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Contacts from "../Contacts"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"

export const FirstMerchantInfo = forwardRef((props, ref) => {
  const {
    handleMerchantChange,
    merchantDetails,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [pick_date, setPickDate] = useState("")
  const { settingFirstStepError } = useContext(MerchantCreateContext)

  // useEffect(() => {
  //     settingFirstStepError(errors)
  // }, [errors])

  function handleChange(changes) {
    handleMerchantChange({ ...merchantDetails, ...changes })
  }

  function handleContactChange(id, contact) {
    const newContacts = [...merchantDetails.Contacts]
    const index = newContacts.findIndex(i => i.id === id)
    newContacts[index] = contact
    handleChange({ Contacts: newContacts })
  }

  function handleContactAdd() {
    const newContact = {
      id: uuidv4(),
      Name: "",
      Mobile: "",
      Email: "",
      Position: "",
    }
    handleChange({ Contacts: [...merchantDetails.Contacts, newContact] })
  }

  function handleContactDelete(id) {
    handleChange({
      Contacts: merchantDetails.Contacts.filter(i => i.id !== id),
    })
  }

  //used to call this method from parent (not working and temporarily disabled)
  useImperativeHandle(ref, () => ({
    trigger() {
      settingFirstStepError(errors)
      document.getElementById("my-form_submit_1").click()
    },
  }))

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-merchantname-input14">Merchant Name</Label>
              <input
                type="text"
                value={merchantDetails.MerchantName}
                name="MerchantName"
                onChange={e => handleChange({ MerchantName: e.target.value })}
                className="form-control"
                id="basicpill-merchantname-input14"
                ref={register({ required: true })}
              />
              {errors.MerchantName && errors.MerchantName.type === "required" && (
                <>
                  <small className="color-danger">This is required</small>
                </>
              )}
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <Label>Merchant Type</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                name="Type"
                value={merchantDetails.Type}
                onChange={e => handleChange({ Type: e.target.value })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Travel Agency">Travel Agency</option>
                <option value="Online Retail">Online Retail</option>
                <option value="Others">Others</option>
              </select>
              {errors.Type && errors.Type.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {/* <Col lg="6">
                        <FormGroup>
                            <Label>Status</Label>
                            <select
                                ref={register({ required: true })}
                                className="custom-select"
                                value={merchantDetails.IsActive}
                                name="IsActive"
                                onChange={(e) => handleChange({ IsActive: parseBool(e.target.value) })}>

                                <option value="" selected="selected" hidden="hidden">Choose here</option>
                                <option value="true" >Active</option>
                                <option value="false">Inactive</option>

                            </select>
                            {errors.IsActive && errors.IsActive.type === "required" && <small className="color-danger">This is required</small>}
                        </FormGroup>
                    </Col> */}
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-website-input15">Website</Label>
              <input
                value={merchantDetails.Website}
                type="text"
                name="Website"
                onChange={e => handleChange({ Website: e.target.value })}
                className="form-control"
                id="basicpill-website-input15"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^https?:\/\/.+$/,
                    message: "invalid url",
                  },
                })}
              />
              <small className="color-danger">
                {errors.Website && errors.Website.message}
              </small>

              {/* {errors.Website && errors.Website.type === "required" && <small className="color-danger">This is required</small>} */}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-tc-input15">Terms & Condition</Label>
              <input
                value={merchantDetails.TermsConditionUrl}
                type="text"
                name="TermsConditionUrl"
                onChange={e =>
                  handleChange({ TermsConditionUrl: e.target.value })
                }
                className="form-control"
                id="basicpill-tc-input15"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^https?:\/\/.+$/,
                    message: "invalid url",
                  },
                })}
              />
              <small className="color-danger">
                {errors.TermsConditionUrl && errors.TermsConditionUrl.message}
              </small>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-bus-input17">
                Business Registration No
              </Label>
              <input
                value={merchantDetails.BusinessRegNumber}
                type="text"
                name="BusinessRegNumber"
                onChange={e =>
                  handleChange({ BusinessRegNumber: e.target.value })
                }
                className="form-control"
                id="basicpill-bus-input17"
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-tax-input16">Tax Registration No</Label>
              <input
                value={merchantDetails.TaxRegNumber}
                type="text"
                name="TaxRegNumber"
                onChange={e => handleChange({ TaxRegNumber: e.target.value })}
                className="form-control"
                id="basicpill-tax-input16"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-phoneno-input16">Telephone</Label>
              <input
                value={merchantDetails.PhoneNumber}
                type="text"
                name="PhoneNumber"
                onChange={e => handleChange({ PhoneNumber: e.target.value })}
                className="form-control"
                id="basicpill-phoneno-input16"
                ref={register({ required: true })}
              />
              {errors.PhoneNumber && errors.PhoneNumber.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-city-input16">City</Label>
              <input
                value={merchantDetails.City}
                type="text"
                name="City"
                onChange={e => handleChange({ City: e.target.value })}
                className="form-control"
                id="basicpill-city-input16"
                ref={register({ required: true })}
              />
              {errors.City && errors.City.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Country</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                name="Country"
                value={merchantDetails.Country}
                onChange={e => handleChange({ Country: e.target.value })}
              >
                <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option>
                <option value="Qatar">Qatar</option>
              </select>
              {errors.Country && errors.Country.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-PoBox-input16">Po Box</Label>
              <input
                value={merchantDetails.PoBox}
                type="text"
                name="PoBox"
                onChange={e => handleChange({ PoBox: e.target.value })}
                className="form-control"
                id="basicpill-PoBox-input16"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="basicpill-address-input2">Address</Label>
              <textarea
                value={merchantDetails.Address}
                name="Address"
                onChange={e => handleChange({ Address: e.target.value })}
                id="basicpill-address-input2"
                className="form-control"
                rows="2"
                ref={register({ required: true })}
              />
              {errors.Address && errors.Address.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
        </Row>

        <hr></hr>
        <b>Add Company Contacts</b>
        <p>For internal purposes only.</p>

        {merchantDetails.Contacts.map((contact, index) => (
          <Contacts
            index={index}
            key={contact.id}
            contact={contact}
            handleContactChange={handleContactChange}
            handleContactDelete={handleContactDelete}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
          />
        ))}

        <Button
          onClick={() => handleContactAdd()}
          color="success"
          className="mt-1"
        >
          Add New Contact
        </Button>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {activeTabProgress === 3 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button type="submit" color="primary" id="my-form_submit_1">
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>

        {/* <button style={{}} type='submit' hidden ref={ (button) => { this.activityFormButton = button } } >Submit</button> */}
        {/* <Button  positive form='my-form' content='Submit' value='Submit'  type="submit"/> */}
      </Form>
    </>
  )
})

export default FirstMerchantInfo
