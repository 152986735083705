import React, { useContext, useEffect, useState } from "react"
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  CardBody,
  Card,
} from "reactstrap"
import FileUpload from "../FileUpload"
import NotificationEmails from "../NotificationEmails"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import Services from "../Services"
import { MerchantCreateContext } from "../index"
import { logoutUser, returnStateLogsTransactionUpdate } from "store/actions"

import { OfficeIds } from "../OfficeIds"
//const axios = require('axios');

const images = [
  {
    type: "",
    formData: {},
  },
]

export const SecondConfigurations = props => {
  const {
    handleMerchantChange,
    merchantDetails,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const { settingSecondStepError } = useContext(MerchantCreateContext)
  const [formDataFile, setFormDataFile] = useState({})
  const [fileLogo, setFileLogo] = useState(null)
  const [fileBanner, setFileBanner] = useState(null)
  const [files, setFiles] = useState([])
  const [fileUrls, setFileUrls] = useState([])

  useEffect(() => {}, [formDataFile])
  useEffect(() => {
    // console.log(fileLogo)
  }, [fileLogo])
  useEffect(() => {
    // console.log(fileBanner)
  }, [fileBanner])

  const handleLogo = e => {
    e.preventDefault()
    handleChange({ Logo: e.target.value })
    setFileLogo(e.target.files[0])

    const formDataLogo = new FormData()
    formDataLogo.append("file", e.target.files[0])
    formDataLogo.append("upload_preset", "payalllink")
    formDataLogo.append("cloud_name", "DeveloperQa")

    handleFileUpload("logo", formDataLogo)

    // if (fileLogo !== "") {
    //   setFileLogo("")
    // }
  }

  const handleBanner = e => {
    e.preventDefault()
    handleChange({ Banner: e.target.value })
    setFileBanner(e.target.files[0])

    const formDataBanner = new FormData()
    formDataBanner.append("file", e.target.files[0])
    formDataBanner.append("upload_preset", "payalllink")
    formDataBanner.append("cloud_name", "DeveloperQa")

    handleFileUpload("banner", formDataBanner)

    // if (fileBanner !== "") {
    //   setFileBanner("")
    // }
  }
  function handleOfficeIdChange(id, officeId) {
    const newOfficeIds = [...merchantDetails.OfficeIds]
    const index = newOfficeIds.findIndex(i => i.id === id)
    newOfficeIds[index] = officeId
    handleChange({ OfficeIds: newOfficeIds })
  }

  function handleOfficeIdAdd() {
    const newOfficeId = {
      id: uuidv4(),
      OfficeId: "",
      Date: new Date().toISOString(),
    }
    handleChange({ OfficeIds: [...merchantDetails.OfficeIds, newOfficeId] })
  }

  function handleOfficeIdDelete(id) {
    handleChange({
      OfficeIds: merchantDetails.OfficeIds.filter(i => i.id !== id),
    })
  }

  // const handleFileUpload = async () => {

  //     //  if (!formDataFile.entries().next().done) {
  //     // try {

  //     // //https://payalllink.netlify.app/
  //     // //http://localhost:5000
  //     // await fetch("http://localhost:5000/upload", {
  //     //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //     //     mode: 'cors', // no-cors, *cors, same-origin
  //     //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //     //     credentials: 'same-origin', // include, *same-origin, omit
  //     //     // redirect: 'follow', // manual, *follow, error
  //     //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     //     body: formDataFile // body data type must match "Content-Type" header
  //     // })
  //     //     .then(response => checkJson(response))
  //     //     .then(result => {
  //     //         if (result.code === "LIMIT_FILE_SIZE") { console.error('Error:', "Follow Image Requirement!") }
  //     //         else {
  //     //             console.log('Success:', result)

  //     //             const logoData = result.filter(x => x.fieldname == "logo")
  //     //             const bannerData = result.filter(x => x.fieldname == "banner")

  //     //             handleChange({ Logo: logoData[0].filename, Banner: bannerData[0].filename })
  //     //         }
  //     //     })
  //     //     .catch(error => {
  //     //         console.error('Error:', error);
  //     //     });

  //     console.log("file sent")
  //     console.log(files)

  //     files.map(async (formDataFile) => {
  //         await fetch("https://api.cloudinary.com/v1_1/developerqa/image/upload", {
  //             method: "post",
  //             body: formDataFile?.formData
  //         })
  //             .then(resp => resp.json())
  //             .then(data => {

  //                 console.log(formDataFile?.type)

  //                 if (formDataFile?.type === "logo") {
  //                     console.log("hanlding logo")
  //                     handleChange({ Logo: data.url })
  //                 }

  //                 if (formDataFile?.type === "banner") {
  //                     console.log("hanlding banner")
  //                     handleChange({ Banner: data.url })
  //                 }

  //                setFileUrls([...fileUrls, data.url])
  //                 console.log(data.url)

  //             })
  //             .catch(err => console.log(err))
  //     })

  //     //setFiles([])

  // }

  const handleFileUpload = async (type, formData) => {
    await fetch("https://api.cloudinary.com/v1_1/developerqa/image/upload", {
      method: "post",
      body: formData,
    })
      .then(resp => resp.json())
      .then(data => {
        if (type === "logo") {
          handleChange({ Logo: data.url })
        }

        if (type === "banner") {
          handleChange({ Banner: data.url })
        }
      })
      .catch(err => console.log(err))
  }

  const addFile = data => {
    setFiles([...files, data])
  }

  const addUrl = data => {
    setFileUrls([...fileUrls, data])
  }

  function checkJson(response) {
    return response.text().then(function (text) {
      return text ? JSON.parse(text) : {}
    })
  }

  function handleChange(changes) {
    handleMerchantChange({ ...merchantDetails, ...changes })
  }

  function handleNotificationChange(id, notificationemail) {
    const newNotifications = [...merchantDetails.NotificationEmails]
    const index = newNotifications.findIndex(i => i.id === id)
    newNotifications[index] = notificationemail
    handleChange({ NotificationEmails: newNotifications })
  }

  function handleNotificationAdd() {
    const newNotification = {
      id: uuidv4(),
      Email: "",
      Date: new Date().toISOString(),
    }
    handleChange({
      NotificationEmails: [
        ...merchantDetails.NotificationEmails,
        newNotification,
      ],
    })
  }

  function handleNotificationDelete(id) {
    handleChange({
      NotificationEmails: merchantDetails.NotificationEmails.filter(
        i => i.id !== id
      ),
    })
  }

  function handleServiceChange(id, service) {
    const newServices = [...merchantDetails.Services]
    const index = newServices.findIndex(i => i.id === id)
    newServices[index] = service
    handleChange({ Services: newServices })
  }

  function handleServiceAdd() {
    const newService = {
      id: uuidv4(),
      Service: "",
    }

    handleChange({ Services: [...merchantDetails.Services, newService] })
  }

  function handleServiceDelete(id) {
    handleChange({
      Services: merchantDetails.Services.filter(i => i.id !== id),
    })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
      //handleFileUpload()
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="basicpill-merchantname-input14">
                    Logo{" "}
                    <small>
                      ( Requirements: 200px by 200px - less than 50kb - jpeg &
                      png only)
                    </small>
                  </Label>
                  {/* <FileUpload/> */}

                  <input
                    //value={merchantDetails.Logo}
                    className="form-control"
                    type="file"
                    id="formFile"
                    name="Logo"
                    onChange={e => handleLogo(e)}
                    ref={register({ required: true })}
                  />
                  {errors.Logo && errors.Logo.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                  {errors.fileLogo && errors.fileLogo.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="basicpill-merchantname-input14">
                    Marketing Banner{" "}
                    <small>
                      ( Requirements: 865px by 270px - less than 150kb - jpeg &
                      png only)
                    </small>
                  </Label>
                  {/* <FileUpload/> */}

                  <input
                    //value={merchantDetails.Banner}
                    className="form-control"
                    type="file"
                    id="formFile2"
                    name="Banner"
                    onChange={e => handleBanner(e)}
                    ref={register({ required: true })}
                  />
                  {errors.Banner && errors.Banner.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                <b>Notification Emails</b>
                <p>
                  Only the listed emails with receive the notifications.
                  <label className="color-danger ml-1">
                    {" "}
                    Maximum of 3 emails only.
                  </label>
                </p>

                {merchantDetails.NotificationEmails.slice(0, 3).map(
                  (notificationEmail, index) => (
                    <NotificationEmails
                      index={index}
                      key={notificationEmail.id}
                      notificationEmail={notificationEmail}
                      handleNotificationChange={handleNotificationChange}
                      handleNotificationDelete={handleNotificationDelete}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      register={register}
                      errors={errors}
                    />
                  )
                )}

                <Button
                  onClick={() => handleNotificationAdd()}
                  color="success"
                  className="mt-1"
                >
                  Add Notification Email
                </Button>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card>
              <CardBody>
                <b>Products/Services Offered</b>
                <p>
                  Please specify the products & services the merchant is
                  offering.
                  <div>( E.g Flights, Hotels, Visa Processing .... )</div>
                </p>

                {merchantDetails.Services.map((service, index) => (
                  <Services
                    index={index}
                    key={service.id}
                    service={service}
                    handleServiceChange={handleServiceChange}
                    handleServiceDelete={handleServiceDelete}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                  />
                ))}

                <Button
                  onClick={() => handleServiceAdd()}
                  color="success"
                  className="mt-1"
                >
                  Add Service
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                {merchantDetails.Type === "Travel Agency" ? (
                  <>
                    <b>Add OfficeIds</b>
                    <p>Enter Office Id</p>
                    {merchantDetails.OfficeIds.map((officeId, index) => (
                      <OfficeIds
                        index={index}
                        key={officeId.id}
                        officeId={officeId}
                        handleOfficeIdChange={handleOfficeIdChange}
                        handleOfficeIdDelete={handleOfficeIdDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                      />
                    ))}

                    <Button
                      onClick={() => handleOfficeIdAdd()}
                      color="success"
                      className="mt-1"
                    >
                      Add New OfficeId
                    </Button>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row className="mt-2"></Row> */}

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {activeTabProgress === 3 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <>
                <Button type="submit" color="primary" id="my-form_submit_2">
                  {" "}
                  Next{" "}
                </Button>
              </>
            )}
          </li>
        </ul>
      </Form>
    </div>
  )
}

export default SecondConfigurations
