import {
  GET_PAYMENTREQUEST_LIST,
  GET_PAYMENTREQUEST_LIST_FAIL,
  GET_PAYMENTREQUEST_LIST_SUCCESS,
  GET_PAYMENTREQUEST_LIST_REPORT,
  GET_PAYMENTREQUEST_LIST_REPORT_FAIL,
  GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL,
  GET_PAYMENTREQUEST_DETAIL_FAIL,
  GET_PAYMENTREQUEST_DETAIL_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL,
  SET_DEVICE_FINGERPRINT,
  SET_DEVICE_FINGERPRINT_SUCCESS,
  SET_DEVICE_FINGERPRINT_FAIL,
  SET_PAYMENT_REQUEST,
  SET_PAYMENT_REQUEST_SUCCESS,
  SET_PAYMENT_REQUEST_FAIL,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD_SUCCESS,
  SET_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMNET_REQUEST,
  UPDATE_PAYMNET_REQUEST_SUCCESS,
  UPDATE_PAYMNET_REQUEST_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL,
  SEND_PAYMENT_REQUEST_EMAIL_SUCCESS,
  SEND_PAYMENT_REQUEST_EMAIL_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
  SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL,
  SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
  SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL,
  RESTART_STATE,
  UPDATE_PAYMENT_REQUEST_BOOLEAN,
  UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS,
  UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL,
  GET_USER_PAYMENT_REQUESTS_BY_USERID,
  GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS,
  GET_USER_PAYMENT_REQUESTS_BY_USERID_FAIL,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  SET_PAYMENT_REQUEST_EXPRESS,
  SET_PAYMENT_REQUEST_EXPRESS_SUCCESS,
  SET_PAYMENT_REQUEST_EXPRESS_FAIL,


  SET_MYFATOORAH_INITIATED_SESSION_DATA,
  SET_MYFATOORAH_INITIATED_SESSION_DATA_SUCCESS,
  SET_MYFATOORAH_INITIATED_SESSION_DATA_FAIL,

  SET_PAYMENT_TYPES,

} from "./actionTypes"

const INIT_STATE = {
  paymentRequestList: [],
  paymentRequestListReport: [],
  paymentRequestDetail: {},
  error: {},
  loadingEmail: null,
  chosenPaymentType: {},
  paymentRedirectionLink: undefined,
  editPayReqBool: undefined,
  paymentTypes: [],
}

const paymentRequests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTREQUEST_LIST:
      return (state = {
        ...state,
        paymentRequestList: action.payload,
        loading: true,
      })

    case GET_PAYMENTREQUEST_LIST_SUCCESS:
      return {
        ...state,
        paymentRequestList: action.payload,
        loading: false,
      }

    case GET_PAYMENTREQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
      }

    case GET_PAYMENTREQUEST_LIST_REPORT:
      return (state = {
        ...state,
        paymentRequestListReport: action.payload,
      })

    case GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS:
      return {
        ...state,
        paymentRequestListReport: action.payload,
      }

    case GET_PAYMENTREQUEST_LIST_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAYMENTREQUEST_DETAIL:
      return {
        ...state,
        paymentRequestDetail: action.payload,
        loading: true,
      }

    case GET_PAYMENTREQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        paymentRequestDetail: action.payload,
        loading: false,
      }

    case GET_PAYMENTREQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PAYMENTREQUEST_DETAIL_PUBLIC:
      return {
        ...state,
        paymentRequestDetail: action.payload,
        loading: true,
      }

    case GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS:
      return {
        ...state,
        paymentRequestDetail: action.payload,
        loading: false,
      }

    case GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SET_DEVICE_FINGERPRINT:
      return (state = {
        ...state,
        deviceFingerPrint: action.payload,
        loading: true,
      })
    case SET_DEVICE_FINGERPRINT_SUCCESS:
      return (state = {
        ...state,
        deviceFingerPrint: action.payload,
        loading: false,
      })
    case SET_DEVICE_FINGERPRINT_FAIL:
      return (state = {
        ...state,
        deviceFingerPrint: action.payload,
        loading: false,
      })

    case SET_PAYMENT_REQUEST:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        paymentRequestCreated: undefined,
        loading: true,
      })
    case SET_PAYMENT_REQUEST_SUCCESS:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        paymentRequestCreated: true,
        loading: false,
      })
    case SET_PAYMENT_REQUEST_FAIL:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        paymentRequestCreated: false,
        loading: false,
      })

    case SET_PAYMENT_METHOD:
      return (state = {
        ...state,
        chosenPaymentMethod: action.payload,
        loading: true,
      })

    case SET_PAYMENT_METHOD_SUCCESS:
      return (state = {
        ...state,
        paymentRedirectionLink: action.payload,
        loading: false,
      })
    case SET_PAYMENT_METHOD_FAIL:
      return (state = {
        ...state,
        error: action.payload,
        loading: false,
      })
    case UPDATE_PAYMNET_REQUEST:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        loading: true,
      })
    case UPDATE_PAYMNET_REQUEST_SUCCESS:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        loading: false,
      })
    case UPDATE_PAYMNET_REQUEST_FAIL:
      return (state = {
        ...state,
        paymentRequestDetail: action.payload,
        loading: false,
      })
    case SEND_PAYMENT_REQUEST_EMAIL:
      return (state = {
        ...state,
        loadingEmail: undefined,
      })

    case SEND_PAYMENT_REQUEST_EMAIL_SUCCESS:
      return (state = {
        ...state,
        loadingEmail: true,
      })

    case SEND_PAYMENT_REQUEST_EMAIL_FAIL:
      return (state = {
        ...state,
        loadingEmail: false,
      })
    case SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS:
      return (state = {
        ...state,
        loading: true,
        emailStatusNonExpress: undefined,
      })
    case SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        emailStatusNonExpress: true,
      })
    case SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL:
      return (state = {
        ...state,
        loading: false,
        emailStatusNonExpress: false,
      })
    case SEND_PAYMENT_REQUEST_EMAIL_EXPRESS:
      return (state = {
        ...state,
        loading: true,
        emailStatusExpress: undefined,
      })
    case SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        emailStatusExpress: true,
      })
    case SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL:
      return (state = {
        ...state,
        loading: false,
        emailStatusExpress: false,
      })
    case UPDATE_PAYMENT_REQUEST_BOOLEAN:
      return (state = {
        ...state,
        editPayReqBool: undefined,
      })
    case UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS:
      return (state = {
        ...state,
        editPayReqBool: action.payload,
      })
    case UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL:
      return (state = {
        ...state,
        editPayReqBool: action.payload,
      })
    case RESTART_STATE:
      return (state = {
        ...state,
        editPayReqBool: undefined,
        emailStatusExpress: undefined,
        emailStatusNonExpress: undefined,
        loadingEmail: null,
        paymentRedirectionLink: "",
        paymentRequestList: [],
        paymentRequestListReport: [],
        paymentRequestCreated: undefined,
        filesUploaded: undefined,
        error: {},
      })
    case GET_USER_PAYMENT_REQUESTS_BY_USERID:
      return (state = {
        ...state,
        prList: action.payload,
        loading: true,
      })
    case GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS:
      return (state = {
        ...state,
        prList: action.payload,
        loading: false,
      })
    case GET_USER_PAYMENT_REQUESTS_BY_USERID_FAIL:
      return (state = {
        ...state,
        prList: action.payload,
        loading: false,
      })

    case FILE_UPLOAD:
      return (state = {
        ...state,
        files: action.payload,
        filesUploaded: undefined,
        loading: true,
      })
    case FILE_UPLOAD_SUCCESS:
      return (state = {
        ...state,
        files: action.payload,
        filesUploaded: true,
        loading: false,
      })
    case FILE_UPLOAD_FAIL:
      return (state = {
        ...state,
        error: action.payload,
        loading: false,
      })
    case SET_PAYMENT_REQUEST_EXPRESS:
      return (state = {
        ...state,
        paymentRequestDetailExpress: action.payload,
        loading: true,
      })
    case SET_PAYMENT_REQUEST_EXPRESS_SUCCESS:
      return (state = {
        ...state,
        paymentRequestDetailExpress: action.payload,
        prExpress: true,
        loading: false,
      })
    case SET_PAYMENT_REQUEST_EXPRESS_FAIL:
      return (state = {
        ...state,
        paymentRequestDetailExpress: action.payload,
        prExpress: false,
        loading: false,
      })


      case SET_MYFATOORAH_INITIATED_SESSION_DATA:
        return (state = {
          ...state,
          initiatedSession: action.payload,
          initiatedSessionloading: true,
        })
      case SET_MYFATOORAH_INITIATED_SESSION_DATA_SUCCESS:
        return (state = {
          ...state,
          initiatedSessionRedirectUrlResponse: action.payload,
          initiatedSessionloading: false,
        })
      case SET_MYFATOORAH_INITIATED_SESSION_DATA_FAIL:
        return (state = {
          ...state,
          initiatedSessionError: action.payload,
          initiatedSessionloading: false,
        })


    case SET_PAYMENT_TYPES:
      return (state = {
        ...state,
        paymentTypes: action.payload,
      })

    default:
      return state
  }
}

export default paymentRequests
