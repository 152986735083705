import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap"

import { map } from "lodash"

const Items = props => {
  const [parsedItem, setParsedItem] = useState({})
  useEffect(() => {
    var parsed = JSON.parse(
      props.purchasedItems ? props.purchasedItems : "null"
    )
    setParsedItem(parsed)
  }, [])

  return (
    <>
      <Card style={{ minHeight: "130px", overflow: "auto" }}>
        <CardBody>
          <Row>
            <Col lg="12">
              {props.source === "SELLCO" && (
                <div>
                  <CardTitle className="mb-4">
                    Item List Breakdown{" "}
                    <small>
                      {parsedItem?.plus?.ItemId !== undefined &&
                      parsedItem?.plus?.ItemId !== null
                        ? parsedItem?.plus?.ItemId
                        : null}
                      -
                      {parsedItem?.plus?.FileName !== undefined &&
                      parsedItem?.plus?.FileName !== null
                        ? parsedItem?.plus?.FileName
                        : null}
                    </small>
                  </CardTitle>
                </div>
              )}

              {props.source === "PORTAL" && parsedItem?.Items ? (
                <div>
                  <CardTitle className="mb-4">
                    Item List Breakdown <small>{parsedItem.ItemId}</small>
                  </CardTitle>

                  <Table>
                    <tr>
                      <th>Item Name</th>
                      <th>Qty </th>
                      <th>Amount </th>
                    </tr>
                    {map(parsedItem.Items, (item, index) => (
                      <tr key={index}>
                        <td>{item.ItemName}</td>
                        <td>{item.Qty}</td>
                        <td>{item.Amount}</td>
                      </tr>
                    ))}
                  </Table>
                </div>
              ) : (
                <div>
                  <span>No items listed.</span>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Items
