
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {

  GET_TRANSACTION_UPDATE_LOGS_LIST,
  GET_USER_LOGS,
  GET_USER_LOGS_LIST,
  GET_EMAIL_LOGS_LIST,
  GET_ERROR_LOGS_LIST,
  UPDATE_TRANSACTION_LOG,
  GET_PARSER_ERROR_LOG_LIST,
  GET_PARSER_EXECUTION_LOG_LIST,
  GET_PLACER_ERROR_LOG_LIST,
  GET_PLACER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_ERROR_LOG_LIST,
  SET_WHATSAPP_LOG

} from "./actionTypes"

import {
  getLogsTransactionUpdateListSuccess,
  getLogsTransactionUpdateListFail,
  getUserLogsByIdSuccess,
  getUserLogsByIdFail,
  getUserLogListSuccess,
  getUserLogListFail,
  getEmailLogListSuccess,
  getEmailLogListFail,
  getErrorLogListSuccess,
  getErrorLogListFail,
  updateTransactionLogsSuccess,
  updateTransactionLogsFail,
  getParserErrorLogListSuccess,
  getParserErrorLogListFail,
  getParserExecutionLogListSuccess,
  getParserExecutionLogListFail,
  getPlacerErrorLogListSuccess,
  getPlacerErrorLogListFail,
  getPlacerExecutionLogListSuccess,
  getPlacerExecutionLogListFail,
  setWhatAppLogSuccess,
  getSyncCheckerExecutionLogListSuccess,
  getSyncCheckerExecutionLogListFail,
  getSyncCheckerErrorLogListSuccess,
  getSyncCheckerErrorLogListFail,
  setWhatAppLogFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLogsTransactionUpdateList,
  getUserLogsById,
  getUserLogList,
  getEmailLogList,
  getErrorLogList,
  updateTransactionLogs,
  getParserErrorLogList,
  getParserExecutionLogList,
  getPlacerErrorLogList,
  getPlacerExecutionLogList,
  getSyncCheckerExecutionLogList,
  getSyncCheckerErrorLogList,
  setWhatsAppLog
} from "helpers/fakebackend_helper"

function* _getTransactionUpdateLogsList() {
  try {

    const response = yield call(getLogsTransactionUpdateList)

    if (response) {
      yield put(getLogsTransactionUpdateListSuccess(response))
    }
  }
  catch (error) {
    yield put(getLogsTransactionUpdateListFail(error))
  }
}

function* _updateTransactionLog({ payload: data }) {
  try {

    const response = yield call(updateTransactionLogs, data)
   
    if (response) {
      yield put(updateTransactionLogsSuccess(response))
    }
  }
  catch (error) {
    yield put(updateTransactionLogsFail(error))
  }
}


function* _getUserLogsById({ payload: user }) {
  try {
    const response = yield call(getUserLogsById, user)
    if (response) {
      yield put(getUserLogsByIdSuccess(response))
    }
    else {
      yield put(getUserLogsByIdFail(response))
    }
  }
  catch (error) {
    yield put(getUserLogsByIdFail(error))
  }
}

function* _getUserLogList({ payload: user }) {
  try {
    const response = yield call(getUserLogList, user)
    if (response) {
      yield put(getUserLogListSuccess(response))
    }
    else {
      yield put(getUserLogListFail(response))
    }
  }
  catch (error) {
    yield put(getUserLogListFail(error))
  }
}

function* _getEmailLogList({ payload: user }) {
  try {
    const response = yield call(getEmailLogList, user)
    if (response) {
      yield put(getEmailLogListSuccess(response))
    }
    else {
      yield put(getEmailLogListFail(response))
    }
  }
  catch (error) {
    yield put(getEmailLogListFail(error))
  }
}

function* _getErrorLogList({ payload: user }) {
  try {
    const response = yield call(getErrorLogList, user)
    if (response) {
      yield put(getErrorLogListSuccess(response))
    }
    else {
      yield put(getErrorLogListFail(response))
    }
  }
  catch (error) {
    yield put(getErrorLogListFail(error))
  }
}

function* _getParserErrorLogList({ payload: logs }) {
  try {
    const response = yield call(getParserErrorLogList, logs)
    if (response) {
      yield put(getParserErrorLogListSuccess(response))
    }
    else {
      yield put(getParserErrorLogListFail(response))
    }
  }
  catch (error) {
    yield put(getParserErrorLogListFail(error))
  }
}


function* _getParserExecutionLogList({ payload: logs }) {
  try {
    const response = yield call(getParserExecutionLogList, logs)
    if (response) {
      yield put(getParserExecutionLogListSuccess(response))
    }
    else {
      yield put(getParserExecutionLogListFail(response))
    }
  }
  catch (error) {
    yield put(getParserExecutionLogListFail(error))
  }
}





function* _getPlacerErrorLogList({ payload: logs }) {
  try {
    const response = yield call(getPlacerErrorLogList, logs)
    if (response) {
      yield put(getPlacerErrorLogListSuccess(response))
    }
    else {
      yield put(getPlacerErrorLogListFail(response))
    }
  }
  catch (error) {
    yield put(getPlacerErrorLogListFail(error))
  }
}


function* _getPlacerExecutionLogList({ payload: logs }) {
  try {
    const response = yield call(getPlacerExecutionLogList, logs)
    if (response) {
      yield put(getPlacerExecutionLogListSuccess(response))
    }
    else {
      yield put(getPlacerExecutionLogListFail(response))
    }
  }
  catch (error) {
    yield put(getPlacerExecutionLogListFail(error))
  }
}

function* _setWhatsAppLog({ payload: logs }) {
  try {
    const response = yield call(setWhatsAppLog, logs)
    if (response) {
      yield put(setWhatAppLogSuccess(response))
    }
    else {
      yield put(setWhatAppLogFail(response))
    }
  }
  catch (error) {
    yield put(setWhatAppLogFail(error))
  }
}

function* _getSyncCheckerExecutionLogList({ payload: logs }) {
  try {
    const response = yield call(getSyncCheckerExecutionLogList, logs)
    if (response) {
      yield put(getSyncCheckerExecutionLogListSuccess(response))
    }
    else {
      yield put(getSyncCheckerExecutionLogListFail(response))
    }
  }
  catch (error) {
    yield put(getSyncCheckerExecutionLogListFail(error))
  }
}


function* _getSyncCheckerErrorLogList({ payload: logs }) {
  try {
    const response = yield call(getSyncCheckerErrorLogList, logs)
    if (response) {
      yield put(getSyncCheckerErrorLogListSuccess(response))
    }
    else {
      yield put(getSyncCheckerErrorLogListFail(response))
    }
  }
  catch (error) {
    yield put(getSyncCheckerErrorLogListFail(error))
  }
}

function* logsSaga() {
  yield takeEvery(GET_TRANSACTION_UPDATE_LOGS_LIST, _getTransactionUpdateLogsList)
  yield takeEvery(GET_USER_LOGS, _getUserLogsById)
  yield takeEvery(GET_USER_LOGS_LIST, _getUserLogList)
  yield takeEvery(GET_EMAIL_LOGS_LIST, _getEmailLogList)
  yield takeEvery(GET_ERROR_LOGS_LIST, _getErrorLogList)
  yield takeEvery(UPDATE_TRANSACTION_LOG, _updateTransactionLog)
  yield takeEvery(GET_PARSER_ERROR_LOG_LIST, _getParserErrorLogList)
  yield takeEvery(GET_PARSER_EXECUTION_LOG_LIST, _getParserExecutionLogList)
  yield takeEvery(GET_PLACER_ERROR_LOG_LIST, _getPlacerErrorLogList)
  yield takeEvery(GET_PLACER_EXECUTION_LOG_LIST, _getPlacerExecutionLogList)
  yield takeEvery(GET_SYNC_CHECKER_EXECUTION_LOG_LIST, _getSyncCheckerExecutionLogList)
  yield takeEvery(GET_SYNC_CHECKER_ERROR_LOG_LIST, _getSyncCheckerErrorLogList)
  yield takeEvery(SET_WHATSAPP_LOG, _setWhatsAppLog)
}

export default logsSaga
