import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import { GET_COUNTRY_CODE } from "./actionTypes"

import { getCountryCodeSuccess, getCountryCodeFail } from "./actions"

//Include Both Helper File with needed methods
import { getCountryCode } from "helpers/fakebackend_helper"

function* _getCountryCode() {
  try {
    const response = yield call(getCountryCode)

    if (response) {
      yield put(getCountryCodeSuccess(response))
    } else {
      yield put(getCountryCodeFail(response))
    }
    // yield put(restartState())
  } catch (error) {
    yield put(getCountryCodeFail(error))
  }
}

function* paymentHistorySaga() {
  yield takeEvery(GET_COUNTRY_CODE, _getCountryCode)
}

export default paymentHistorySaga
