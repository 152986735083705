import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PAYMENTREQUEST_LIST_ARCHIVED,
  PATCH_PAYMENTREQUEST_ARCHIVE,
} from "./actionTypes"
import {
  getPaymentRequestListArchivedSuccess,
  getPaymentRequestListArchivedFail,
  patchPaymentRequestArchiveSuccess,
  patchPaymentRequestArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getPaymentRequestListArchived,
  patchPaymentRequestArchive,
} from "helpers/fakebackend_helper"

function* _getPaymentRequestListArchived({ payload: data }) {
  try {
    const response = yield call(getPaymentRequestListArchived, data)
    if (response) {
      yield put(getPaymentRequestListArchivedSuccess(response))
    } else {
      yield put(getPaymentRequestListArchivedFail(response))
    }
  } catch (error) {
    yield put(getPaymentRequestListArchivedFail(error))
  }
}

function* _patchPaymentRequestArchive({ payload: data }) {
  try {
    const response = yield call(patchPaymentRequestArchive, data)
    if (response) {
      yield put(patchPaymentRequestArchiveSuccess(response))
    } else {
      yield put(patchPaymentRequestArchiveFail(response))
    }
  } catch (error) {
    yield put(patchPaymentRequestArchiveFail(error))
  }
  yield put(returnState())
}

function* paymentrequestsArchiveSaga() {
  yield takeEvery(
    GET_PAYMENTREQUEST_LIST_ARCHIVED,
    _getPaymentRequestListArchived
  )
  yield takeEvery(PATCH_PAYMENTREQUEST_ARCHIVE, _patchPaymentRequestArchive)
}

export default paymentrequestsArchiveSaga
