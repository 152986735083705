import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects"
import {
  getUserListByMerchantId,
  setUser,
  deleteUser,
  deactivateUser,
  updateNewUserPass,
  updateUserPass,
  updateUser,
  getUserDetail,
  getUserList,
  getAdminUserByMerchantId,
} from "helpers/fakebackend_helper"

import {
  GET_USER_LIST,
  GET_USER_LIST_BY_MERCHANTID,
  SET_USER,
  DELETE_USER,
  SET_NEW_USER_PASSWORD,
  UPDATE_USER,
  UPDATE_PASSWORD,
  GET_USER,
  DEACTIVATE_USER,
  RETURN_STATE,
  GET_ADMIN_USER_BY_MERCHANTID,
} from "./actionTypes"

import {
  getUserListByMerchantIdSuccess,
  getUserListByMerchantIdFail,
  getUserListSuccess,
  getUserListFail,
  setUserSuccess,
  setUserFail,
  deleteUserSuccess,
  deleteUserFail,
  deactivateUserSuccess,
  deactivateUserFail,
  updateNewUserPassSuccess,
  updateNewUserPassFail,
  updateUserSuccess,
  updateUserFail,
  updateUserPassSuccess,
  updateUserPassFail,
  returnState,
  getUserDetailSuccess,
  getUserDetailFail,
  getAdminUserByMerchantIdSuccess,
  getAdminUserByMerchantIdFail,
} from "./action"

function* _getUserList() {
  try {
    const response = yield call(getUserList)
    if (response) {
      yield put(getUserListSuccess(response))
    } else {
      yield put(getUserListFail(response))
    }
  } catch (error) {
    yield put(getUserListFail(error))
  }
}

function* _setUser({ payload: user }) {
  try {
    const response = yield call(setUser, user)

    if (response) {
      yield put(setUserSuccess(response))
    } else {
      yield put(setUserFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(setUserFail(error))
  }
}

function* _getAdminUserByMerchantId({ payload: id }) {
  try {
    const response = yield call(getAdminUserByMerchantId, id)

    if (response) {
      yield put(getAdminUserByMerchantIdSuccess(response))
    } else {
      yield put(getAdminUserByMerchantIdFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(getAdminUserByMerchantIdFail(error))
  }
}

function* _deleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    if (response === true) {
      yield put(deleteUserSuccess(response))
    } else {
      yield put(deleteUserFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* _deactivateUser({ payload: user }) {
  try {
    const response = yield call(deactivateUser, user)
    if (response) {
      yield put(deactivateUserSuccess(response))
    } else {
      yield put(deactivateUserFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(deactivateUserFail(error))
  }
}

function* _updateNewUserPass({ payload: user }) {
  try {
    const response = yield call(updateNewUserPass, user)
    if (response === true) {
      yield put(updateNewUserPassSuccess(response))
    } else {
      yield put(updateNewUserPassFail(false))
    }
    yield put(returnState())
  } catch (error) {
    yield put(updateNewUserPassFail(error))
  }
}

function* _updateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    if (response) {
      yield put(updateUserSuccess(response))
    } else {
      yield put(updateUserFail(response))
    }
    yield put(returnState())
  } catch (error) {
    yield put(editUserFail(error))
  }
}

function* _updateUserPass({ payload: user }) {
  try {
    const response = yield call(updateUserPass, user)
    if (response === true) {
      yield put(updateUserPassSuccess(response))
    } else {
      yield put(updateUserPassFail(response.message))
    }
    yield put(returnState())
  } catch (error) {
    yield put(updateUserPassFail(error))
  }
}
function* _getUserDetail({ payload: user }) {
  try {
    const response = yield call(getUserDetail, user)
    if (response) {
      yield put(getUserDetailSuccess(response))
    } else {
      yield put(getUserDetailFail(response))
    }
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* _getUserListByMerchantId({ payload: merchantId }) {
  try {
    const response = yield call(getUserListByMerchantId, merchantId)
    if (response) {
      yield put(getUserListByMerchantIdSuccess(response))
    }
  } catch (error) {
    yield put(getUserListByMerchantIdFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USER_LIST, _getUserList)
  yield takeEvery(SET_USER, _setUser)
  yield takeEvery(GET_ADMIN_USER_BY_MERCHANTID, _getAdminUserByMerchantId)
  yield takeEvery(DELETE_USER, _deleteUser)
  yield takeEvery(UPDATE_PASSWORD, _updateUserPass)
  yield takeEvery(SET_NEW_USER_PASSWORD, _updateNewUserPass)
  yield takeEvery(UPDATE_USER, _updateUser)
  yield takeEvery(GET_USER, _getUserDetail)
  yield takeEvery(GET_USER_LIST_BY_MERCHANTID, _getUserListByMerchantId)
  yield takeEvery(DEACTIVATE_USER, _deactivateUser)
}

export default userSaga
