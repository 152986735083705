import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Label,
} from "reactstrap"
import Moment from 'moment';
import ReactTooltip from 'react-tooltip'

const Summary = (props) => {

  const { prSummary, user } = props
  const [merchant, setMerchant] = useState()
  const [dateRangeStart, setDateRangeStart] = useState()
  const [dateRangeEnd, setDateRangeEnd] = useState()
  const [dropdown, setDropDown] = useState(false)
  const [currentYear, setCurrentYear] = useState()

  useEffect(()=>{
    
  },[])

  useEffect(() => {
    setMerchant(props.selectedMerchantName)
  }, [props.selectedMerchantName])

  useEffect(() => {
  }, [merchant])

  useEffect(() => { }, [dropdown])

  useEffect(() => {
    if (Array.isArray(props.selectedDateRange)) {
      if (props.selectedDateRange.length === 2) {
        setDateRangeStart(Moment(props.selectedDateRange[0]).format("DD/MM/YYYY"))
        setDateRangeEnd(Moment(props.selectedDateRange[1]).format("DD/MM/YYYY"))
      }
      if (props.selectedDateRange.length === 1) {
        setDateRangeStart(Moment(props.selectedDateRange[0]).format("DD/MM/YYYY"))
        setDateRangeEnd(Moment(props.selectedDateRange[0]).format("DD/MM/YYYY"))
      }
    }
    if (props.selectedDateRange === undefined) {
      setDateRangeStart(undefined)
      setDateRangeEnd(undefined)
    }
  }, [props.selectedDateRange])

  useEffect(() => { }, [dateRangeStart])
  useEffect(() => { }, [dateRangeEnd])


  const reports = [
    {
      title: "Paid Payment Requests",
      iconClass: "bx bx-comment-check",
      description: prSummary?.noPaidPr
    },
    {
      title: "Successful Amount Processed",
      iconClass: "bx bx-money",
      description: prSummary?.totalRevenue
    },
    {
      title: "Total Requests Sent",
      iconClass: "bx bx-mail-send",
      description: prSummary?.noPrSent,
    }, {
      title: "Failed Payment Requests",
      iconClass: "mdi mdi-close",
      description: prSummary?.noFailedPr
    },
    {
      title: "Unpaid Payment Requests",
      iconClass: "bx bx-window-close",
      description: prSummary?.noUnpaidPr
    },
  ]

  const reportsUser = [
    {
      title: "Paid",
      iconClass: "bx bx-comment-check",
      description: prSummary?.noPaidPr
    },
    {
      title: "Unpaid",
      iconClass: "bx bx-message-alt-x",
      description: prSummary?.noUnpaidPr
    },
    {
      title: "Failed",
      iconClass: "bx bx-window-close",
      description: prSummary?.noFailedPr,
    },
  ]

  const reportsSuperAdmin = [
    {
      title: "Paid Payment Requests",
      iconClass: "bx bx-comment-check",
      description: prSummary?.noPaidPr
    },
    {
      title: "Successful Amount Processed",
      iconClass: "bx bx-money",
      description: prSummary.totalRevenue !== undefined ? prSummary.totalRevenue.toLocaleString(undefined, { maximumFractionDigits: 2 }) : prSummary?.totalRevenue
    },
    {
      title: "Total Requests Sent",
      iconClass: "bx bx-mail-send",
      description: prSummary?.noPrSent,
    },
    {
      title: "Failed Payment Requests",
      iconClass: "mdi mdi-close",
      description: prSummary?.noFailedPr
    },
    {
      title: "Unpaid Payment Requests",
      iconClass: "bx bx-window-close",
      description: prSummary?.noUnpaidPr
    },
    {
      title: "Total Commission",
      iconClass: "bx bx-dollar",
      description: prSummary.totalCommission !== undefined ? prSummary.totalCommission.toLocaleString(undefined, { maximumFractionDigits: 2 }) : prSummary?.totalCommission
    },
  ]


  return (
    <>
      <ReactTooltip
        place="bottom"
        type="dark"
        effect="solid"
      />



      {user.role === "User" &&
        <>
          <Row className="w-100  my-2">
            <Col md="5" className="mx-4">
              <small><Row>*Summary results of {dateRangeStart !== undefined ? dateRangeStart : " Jan "}{new Date().getFullYear()} to {dateRangeEnd !== undefined ? dateRangeEnd : " Dec "}{new Date().getFullYear()}</Row></small>
            </Col>
            <Col md="2"></Col>
            <Col md="3" className="mx-4">
              <div className="d-flex" >
                <small>*Summary of your generated transactions.</small>
              </div>
            </Col>

          </Row>


          {reportsUser.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        {report.title}
                      </p>
                      <h4 className="mb-0">{report.description}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={
                            "bx " + report.iconClass + " font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      }

      {user.role !== "User" &&
        <>
          <Row className="w-100 my-2">
            <Col md="5">
              <div className="d-flex  mx-4 mb-1" >
                {user.role === "SuperAdmin" ?
                  <small><Row>*Summary results of {merchant !== undefined ? merchant : " All Merchants"} on {dateRangeStart !== undefined ? dateRangeStart : " Jan "}{new Date().getFullYear()} to {dateRangeEnd !== undefined ? dateRangeEnd : " Dec "}{new Date().getFullYear()}</Row></small>
                  : null}
                {user.role === "Admin" ?
                  <small><Row>*Summary results of {dateRangeStart !== undefined ? dateRangeStart : " Jan "}{new Date().getFullYear()} to {dateRangeEnd !== undefined ? dateRangeEnd : " Dec "}{new Date().getFullYear()}</Row></small>
                  : null}
              </div>
            </Col>

            <Col md="3" >
              <div className="d-flex mx-1 dashboard-float" >
                <small >*Summary of your generated transactions.</small>
              </div>
            </Col>

            <Col md="4" className=" mr-0 pr-0 text-align-right">
              {dropdown === false && user.role !== "User" ?
                <Label
                  data-tip="<div><small>Click to see more payment request information.</small></div> "
                  data-html={true}
                  className=" color-primary pointer mx-1 dashboard-float"
                  onClick={() => setDropDown(true)}
                >
                  See more
                </Label>
                :
                <Label
                  data-tip="<div><small>Click to close.</small></div> "
                  data-html={true}
                  className=" color-primary pointer mx-1 dashboard-float"
                  onClick={() => setDropDown(false)}
                >
                  See less
                </Label>
              }
            </Col>
          </Row>
          {user.role === "SuperAdmin" ?
            reportsSuperAdmin.map((report, key) => (
              <>
                {key < 3 ?
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                  :
                  dropdown === true ?
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>

                    : null}
              </>
            )) :
            reports.map((report, key) => (
              <>
                {key < 3 ?
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                  :
                  dropdown === true ?
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>

                    : null}
              </>
            ))}

        </>
      }
    </>
  )
}

export default Summary