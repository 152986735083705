import React, { useState, useEffect } from 'react'
import { Page, Document, pdfjs  } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const file = "/help/PayAllLink_User_Guide_User_V2.pdf"
//const file ="https://paymentlink.s3.ap-south-1.amazonaws.com/help/PayAllLink_User_Guide_User_V2.pdf"

const TravelAgency_User = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div >
      <Document
        file={file}
      //  options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >

        
        {Array.from(new Array(numPages), (el, index) => (
        <Page scale={2.5} key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
        

      </Document>


    </div>
  );
}

export default TravelAgency_User