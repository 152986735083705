import React from "react"
import moment from "moment"

export default function dateFormat(date) {
  return <div>{moment(date).format("DD MMM YYYY, h:mm:ss a")} </div>
}

export const dateFormatString = date => {
  var date = moment(date).format("DD MMM YYYY, h:mm:ss a")
  return date
}
