import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, Row, Card, CardBody, Label, Spinner } from "reactstrap"
import { getUserLogList, getMerchantList, getUserList } from "store/actions"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import UserLogsCol from "./UserLogsColumns"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import { IsEmpty } from "react-lodash"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import { CSVLink } from "react-csv"
const headers = [
  { label: "Reference Id", key: "refId" },
  { label: "User Id", key: "userId" },
  { label: "Date", key: "date" },
  { label: "Reference Page", key: "refPage" },
  { label: "Action", key: "action" },
  { label: "Ip Address", key: "ip" },
  { label: "Device Name", key: "deviceName" },
]
function UserLogsTable(props) {
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [userLogs, setUserLogs] = useState({})
  const [merchantList, setMerchantList] = useState([])
  const [userList, setUserList] = useState([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [usersActivityDownload, setUsersActivityDownload] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const pageOptionsUserLogs = {
    sizePerPage: 15,
    totalSize: userLogs.length,
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeUserLogs()
    },
  }
  let searchText = ""
  const [userLoading, setUserLoading] = useState(props.loading)

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport
  useEffect(() => {
    if (user.role === "SuperAdmin") {
      dispatch(getUserLogList(null))
      dispatch(getMerchantList())
      dispatch(getUserList())
    } else {
      window.location = "/page-404"
    }
  }, [])

  useEffect(() => {
    setUserLoading(true)
    if (Array.isArray(props.allUserLogs)) {
      setUserLogs(props.allUserLogs)
    }
  }, [props.allUserLogs])

  useEffect(() => {
    setUserLoading(false)
  }, [userLogs])

  useEffect(() => {
    if (props.loading !== undefined) {
      setUserLoading(props.loading)
    }
  }, [props.loading])

  useEffect(() => {
    if (props.merchantList !== undefined) {
      setMerchantList(props.merchantList)
    }
  }, [props.merchantList])

  useEffect(() => {
    setDownloadLoading(true)
    handleDownloadUserActivityLogs()
  }, [merchantList, userList, searchResult])

  useEffect(() => {
    if (props.userList !== undefined) {
      setUserList(props.userList)
    }
  }, [props.userList])

  useEffect(() => {}, [userLoading])
  useEffect(() => {
    if (usersActivityDownload && usersActivityDownload.length !== 0) {
      setDownloadLoading(false)
    }
  }, [usersActivityDownload])

  useEffect(() => {
    console.log(downloadLoading)
    if (downloadLoading === true) {
      handleDownloadUserActivityLogs()
    }
  }, [downloadLoading])
  //////////////////////////////////////////////////////////////////////////////////////
  const handleTableChangeUserLogs = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setUserLogs(
        userLogs.filter(logs =>
          Object.keys(logs).some(key =>
            logs[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const handleDownloadUserActivityLogs = () => {
    if (
      Array.isArray(userLogs) &&
      merchantList?.length !== 0 &&
      userLogs?.length !== 0 &&
      searchResult?.length === 0 &&
      userList?.length !== 0
    ) {
      const logsToDownloadList = new Array()
      userLogs.map((u, i) => {
        if (u.merchId === null && u.userId === null) {
          const logsToDownload = {
            ReferenceId: u.refId,
            MerchantId: "N/A",
            MerchantName: "N/A",
            UserId: "N/A",
            Username: "N/A",
            Email: "N/A",
            Date: dateFormatString(u.date),
            ReferencePage: u.refPage,
            Action: u.action,
            IPAddress: u.ip,
            DeviceName: u.deviceName,
          }
          logsToDownloadList.push(logsToDownload)
        }
        //if (u.merchId !== null && u.userId !== null) {
        merchantList.map((m, index) => {
          if (m.merchantId === u.merchId) {
            userList.map((user, ind) => {
              if (user.userId === u.userId) {
                const logsToDownload = {
                  ReferenceId: u.refId !== null ? u.refId : "N/A",
                  MerchantId: u.merchId,
                  MerchantName: m.merchantName,
                  UserId: u.userId,
                  Username: user.userName,
                  Email: user.email,
                  Date: dateFormatString(u.date),
                  ReferencePage: u.refPage,
                  Action: u.action,
                  IPAddress: u.ip,
                  DeviceName: u.deviceName,
                }
                logsToDownloadList.push(logsToDownload)
              }
            })
          }
        })
        // }
      })
      //console.log(logsToDownloadList)
      setUsersActivityDownload(logsToDownloadList)
    }
    if (
      Array.isArray(userLogs) &&
      merchantList?.length !== 0 &&
      userLogs?.length !== 0 &&
      searchResult?.length !== 0 &&
      userList?.length !== 0
    ) {
      const logsToDownloadList = new Array()
      searchResult.map((u, i) => {
        if (u.merchId === null && u.userId === null) {
          const logsToDownload = {
            ReferenceId: u.refId,
            MerchantId: "N/A",
            MerchantName: "N/A",
            UserId: "N/A",
            Username: "N/A",
            Email: "N/A",
            Date: dateFormatString(u.date),
            ReferencePage: u.refPage,
            Action: u.action,
            IPAddress: u.ip,
            DeviceName: u.deviceName,
          }
          logsToDownloadList.push(logsToDownload)
        }
        //if (u.merchId !== null && u.userId !== null) {
        merchantList.map((m, index) => {
          if (m.merchantId === u.merchId) {
            userList.map((user, ind) => {
              if (user.userId === u.userId) {
                const logsToDownload = {
                  ReferenceId: u.refId !== null ? u.refId : "N/A",
                  MerchantId: u.merchId,
                  MerchantName: m.merchantName,
                  UserId: u.userId,
                  Username: user.userName,
                  Email: user.email,
                  Date: dateFormatString(u.date),
                  ReferencePage: u.refPage,
                  Action: u.action,
                  IPAddress: u.ip,
                  DeviceName: u.deviceName,
                }
                logsToDownloadList.push(logsToDownload)
              }
            })
          }
        })
        // }
      })
      // console.log(logsToDownloadList)
      setUsersActivityDownload(logsToDownloadList)
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Label> User Activity Logs</Label>
            {userLoading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <IsEmpty
                value={userLogs}
                yes={() => (
                  <Row>
                    <Col>No list</Col>
                  </Row>
                )}
                no={() => (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptionsUserLogs)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={userLogs || []}
                        columns={UserLogsCol(merchantList, userList)}
                        bootstrap4
                        search={{
                          afterSearch: newResult => {
                            setSearchResult(newResult)
                          },
                        }}
                        exportCSV={{
                          fileName: "UserActivityList.csv",
                          separator: ",",
                          noAutoBOM: false,
                          exportAll: false,
                          onlyExportFiltered: true,
                        }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2  mx-2">
                              <Col sm="4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="width-search"
                                      placeholder="Search"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-right">
                                  {downloadLoading ? (
                                    <Row className=" float-right">
                                      <Spinner
                                        type="border"
                                        className="mr-1"
                                        color="primary"
                                      />
                                    </Row>
                                  ) : (
                                    <CSVLink
                                      color="warning"
                                      filename={"LogsUserActivitieList.csv"}
                                      className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                      data={usersActivityDownload}
                                      separator={","}
                                      key="_id"
                                      target="_blank"
                                    >
                                      <i className="bx bx-download label-icon mr-1" />
                                      Download User Activities Logs
                                    </CSVLink>
                                  )}
                                  {/* <ExportCSVButton
                                    color="warning"
                                    className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                    {...toolkitProps.csvProps}
                                  >
                                    <i className="bx bx-download label-icon mr-1" />
                                    Download User Activities
                                  </ExportCSVButton> */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    hover={true}
                                    striped={false}
                                    classes={
                                      "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                    }
                                    //rowEvents={rowEvents}
                                    keyField="_id"
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChangeUserLogs(
                                      searchText
                                    )}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const mapStateToProps = state => {
  return {
    loading: state.Logs.loading,
    allUserLogs: state.Logs.allUserLogs,
    merchantList: state.Merchants.merchantList,
    userList: state.Users.usersList,
  }
}
export default connect(mapStateToProps)(UserLogsTable)
