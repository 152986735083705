import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../index"
import { useForm } from "react-hook-form"
import localCards from "../../../helpers/commonhelpers/localCards.json"
import { Helmet } from "react-helmet"
import { submit } from "redux-form"

const CardForm_MyFatoorahEmbedded = props => {
  const { myfatoorahInitiateSession, paymentRequestDetails } = props

  const { onSetMyfatoorahInitiateSessionData } = useContext(
    PaymentMethodContext
  )

  useEffect(() => {
    const script = document.createElement("script")

    script.src = myfatoorahInitiateSession?.initiateSessionUrl
    script.async = true

    script.onload = function handleScriptLoaded() {
      console.log("script has loaded")
      var config = {
        countryCode: myfatoorahInitiateSession?.countryCode,
        sessionId: myfatoorahInitiateSession?.sessionId,
        cardViewId: "card-element",
        style: {
          direction: "ltr",
          cardHeight: 230,
          input: {
            color: "black",
            fontSize: "13px",
            fontFamily: "sans-serif",
            inputHeight: "32px",
            inputMargin: "0px",
            borderColor: "c7c7c7",
            borderWidth: "1px",
            borderRadius: "8px",
            boxShadow: "",
            placeHolder: {
              holderName: "Name On Card",
              cardNumber: "Number",
              expiryDate: "MM / YY",
              securityCode: "CVV",
            },
          },
          label: {
            display: false,
            color: "black",
            fontSize: "13px",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            text: {
              holderName: "Card Holder Name",
              cardNumber: "Card Number",
              expiryDate: "Expiry Date",
              securityCode: "Security Code",
            },
          },
          error: {
            borderColor: "red",
            borderRadius: "8px",
            boxShadow: "0px",
          },
        },
      }

      myFatoorah.init(config)
    }

    script.onerror = function handleScriptError() {
      console.log("error loading script")
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const submit = () => {
    myFatoorah
      .submit()
      .then(function (response) {
        console.log(response)
        var model = {
          SessionId: response.SessionId,
          TrackId: myfatoorahInitiateSession.trackId,
        }
        onSetMyfatoorahInitiateSessionData(model)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // console.log("hey")
  // const script = document.createElement("script")

  // script.src = myfatoorahInitiateSession?.initiateSessionUrl
  // script.async = true

  // script.onload = myFatoorah.submit().then(function(result){console.log(result)})
  //   script.onload = function handleScriptLoaded() {

  //     console.log("yow")
  //    // console.log(myFatoorah.submit())
  //     // On success

  //     let userToken = await myFatoorah.submit()
  // console.log(userToken)
  //     userToken.then(function(result) {
  //       console.log(result) // "Some User token"
  //    })

  //   }

  //  document.body.appendChild(script)

  //    myFatoorah
  //     .submit()
  //     .then( function (response) {console.log(response.data)})
  //     .catch(function (error) {
  //       console.log(error)
  //       })

  //     console.log(myFatoorah.submitCallbackSuccess())
  //     console.log(myFatoorah.submitCallbackError())

  //}

  const payNow = () => {
    fetch(
      "https://demo.myfatoorah.com/embedded/v1/en/KWT/InitiatePaymentSession",
      {
        method: "post",
        body:
          "cardModel[CardHolderName]=asd&cardModel[Number]=5454545454545454&cardModel[ExpiryMonth]=02&cardModel[ExpiryYear]=32&cardModel[SecurityCode]=222&sessionId=c31d6127-7e0f-4218-8383-929aa0364e43&isSaveCard=false&tokenId=0",
        headers: {
          // 'Accept': 'application/x-www-form-urlencoded',
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      }
    )
      .then(response => console.log(response.data))
      .catch(function (error) {
        console.log("error---", error)
      })
  }

  const pay = () => {
    const script = document.createElement("script")

    script.src = myfatoorahInitiateSession?.initiateSessionUrl
    script.async = true

    script.onload = function handleScriptLoaded() {
      console.log("yow")
      myFatoorah.submit()

      //-----------------------------

      const output = document.getElementById("output")

      window.addEventListener("message", event => {
        // extract the data from the message event
        const { data } = event
        if (isJsonString(data) === true) {
          console.log(data)

          var jsonData = JSON.parse(data)

          console.log(jsonData.data)
          console.log(jsonData.data.Data.SessionId)

          var model = {
            SessionId: jsonData.data.Data.SessionId,
            TrackId: myfatoorahInitiateSession.trackId,
          }

          onSetMyfatoorahInitiateSessionData(model)
        
        }
      });

      // iframe.addEventListener("load", () => {
      //   iWindow = iframe.contentWindow;
      // });
      
    }

    document.body.appendChild(script)
  }


  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  return (
    <>
      <div className="p-1">
        <div style={{ height: "150px" }}>
          <div id="card-element"></div>
        </div>

        <Button
          onClick={() => pay()}
          style={{
            width: "auto",
            height: "40px",
            ["font-size"]: "1rem",
            background: "#34C38F",
            border: "none",
          }}
        >
          <i className="fas fa-lock mr-1" /> {props.t("Pay Now")}
        </Button>
      </div>
    </>
  )
}

CardForm_MyFatoorahEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(CardForm_MyFatoorahEmbedded))
