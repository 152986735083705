import React, { useState } from "react"
import CreditCardButton from '../CardInput/CreditCardButton'
import DebitCard from '../CardInput/DebitCard'
import OoredooButton from "../CardInput/OoredooButton"
import Paypal from '../CardInput/Paypal'

import {
  Col,
} from "reactstrap"


const PaymentMethodButton = (props) => {


  return (<>



    <Col lg={8}>

      {props.paymentRequestDetails?.paymentRequestSettings.paymentType != null &&

        <>
          {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("credit") &&
            <CreditCardButton />
          }

          {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("debit") &&
            <DebitCard />
          }

          {props.paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("omm") &&
            <OoredooButton />
          }
        </>
      }
    </Col>






  </>)


}

export default PaymentMethodButton