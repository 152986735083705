import {
  GET_MERCHANT_DETAIL,
  GET_MERCHANT_DETAIL_FAIL,
  GET_MERCHANT_DETAIL_SUCCESS,
  GET_MERCHANT_LIST,
  GET_MERCHANT_LIST_FAIL,
  GET_MERCHANT_LIST_SUCCESS,
  GET_MERCHANT_LIST_REPORT,
  GET_MERCHANT_LIST_REPORT_SUCCESS,
  GET_MERCHANT_LIST_REPORT_FAIL,
  GET_MERCHANT_IDS,
  GET_MERCHANT_IDS_SUCCESS,
  GET_MERCHANT_IDS_FAIL,
  SET_MERCHANT,
  SET_MERCHANT_SUCCESS,
  SET_MERCHANT_FAIL,
  REGISTER_MERCHANT,
  REGISTER_MERCHANT_SUCCESS,
  REGISTER_MERCHANT_FAIL,
  GET_MERCHANT_DETAIL_EXTERNAL,
  GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS,
  GET_MERCHANT_DETAIL_EXTERNAL_FAIL,
  RETURN_STATE,
} from "./actionTypes"

export const getMerchantList = () => ({
  type: GET_MERCHANT_LIST,
})

export const getMerchantListSuccess = merchants => ({
  type: GET_MERCHANT_LIST_SUCCESS,
  payload: merchants,
})

export const getMerchantListFail = error => ({
  type: GET_MERCHANT_LIST_FAIL,
  payload: error,
})

export const getMerchantListReport = () => ({
  type: GET_MERCHANT_LIST_REPORT,
})

export const getMerchantListReportSuccess = merchants => ({
  type: GET_MERCHANT_LIST_REPORT_SUCCESS,
  payload: merchants,
})

export const getMerchantListReportFail = error => ({
  type: GET_MERCHANT_LIST_REPORT_FAIL,
  payload: error,
})

export const getMerchantDetail = merchantId => ({
  type: GET_MERCHANT_DETAIL,
  merchantId,
})

export const getMerchantDetailSuccess = merchantDetail => ({
  type: GET_MERCHANT_DETAIL_SUCCESS,
  payload: merchantDetail,
})

export const getMerchantDetailFail = error => ({
  type: GET_MERCHANT_DETAIL_FAIL,
  payload: error,
})
export const getAllMerchantIds = () => ({
  type: GET_MERCHANT_IDS,
})

export const getAllMerchantIdsSuccess = merchant => ({
  type: GET_MERCHANT_IDS_SUCCESS,
  payload: merchant,
})

export const getAllMerchantIdsFail = merchant => ({
  type: GET_MERCHANT_IDS_FAIL,
  payload: merchant,
})

export const setMerchant = merchant => ({
  type: SET_MERCHANT,
  payload: merchant,
})

export const setMerchantSuccess = merchantDetail => ({
  type: SET_MERCHANT_SUCCESS,
  payload: merchantDetail,
})

export const setMerchantFail = error => ({
  type: SET_MERCHANT_FAIL,
  payload: error,
})

export const returnStateMerchant = merchant => ({
  type: RETURN_STATE,
  payload: merchant,
})

export const registerMerchant = merchant => ({
  type: REGISTER_MERCHANT,
  payload: merchant,
})

export const registerMerchantSuccess = merchantDetail => ({
  type: REGISTER_MERCHANT_SUCCESS,
  payload: merchantDetail,
})
export const registerMerchantFail = error => ({
  type: REGISTER_MERCHANT_FAIL,
  payload: error,
})

export const getMerchantDetialExternal = merchantDetail => ({
  type: GET_MERCHANT_DETAIL_EXTERNAL,
  payload: merchantDetail,
})
export const getMerchantDetialExternalSuccess = merchantDetail => ({
  type: GET_MERCHANT_DETAIL_EXTERNAL_SUCCESS,
  payload: merchantDetail,
})

export const getMerchantDetialExternalFail = error => ({
  type: GET_MERCHANT_DETAIL_EXTERNAL_FAIL,
  payload: error,
})
