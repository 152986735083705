import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects"
import {
  setCustomer,
  getCustomerList,
  getCustomerDetail,
  updateCustomer,
  getCustomerListByMerchantID,
  delCustomer,
} from "helpers/fakebackend_helper"

import {
  SET_CUSTOMER,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER,
  GET_CUSTOMERS_BY_MERCHANTID,
  UPDATE_CUSTOMER_EXPRESS,
  SET_CUSTOMER_EXPRESS,
  //TODO:change here
  DELETE_CUSTOMER,
  SELECT_CUSTOMER,
} from "./actionTypes"

import {
  getCustomerDetailSuccess,
  getCustomerDetailFail,
  setCustomerSuccess,
  setCustomerFail,
  getCustomerListSuccess,
  getCustomerListFail,
  updateCustomerSuccess,
  updateCustomerFail,
  updateCustomerExpressSuccess,
  updateCustomerExpressFail,
  //TODO:change here
  deleteCustomerSuccess,
  deleteCustomerFail,
  restartState,
  selectCustomerSuccess,
  selectCustomerFail,
  setCustomerExpressSuccess,
  setCustomerExpressFail,
} from "./action"
import { connect } from "react-redux"

function* _getCustomerList() {
  try {
    const response = yield call(getCustomerList)
    if (response) {
      yield put(getCustomerListSuccess(response))
    } else {
      yield put(getCustomerListFail(response))
    }
  } catch (error) {
    yield put(getCustomerListFail(error))
  }
}

function* _getCustomerListByMerchantID({ payload: customer }) {
  try {
    const response = yield call(getCustomerListByMerchantID, customer)
    if (response) {
      yield put(getCustomerListSuccess(response))
    } else {
      yield put(getCustomerListFail(response))
    }
  } catch (error) {
    yield put(getCustomerListFail(error))
  }
}

function* _getCustomerDetail({ payload: customer }) {
  try {
    const response = yield call(getCustomerDetail, customer)
    if (response) {
      yield put(getCustomerDetailSuccess(response))
    } else {
      yield put(getCustomerDetailFail(response))
    }
  } catch (error) {
    yield put(getCustomerDetailFail(error))
  }
}

function* _setCustomer({ payload: customer }) {
  try {
    const response = yield call(setCustomer, customer)
    if (response === true) {
      yield put(setCustomerSuccess(response))
    } else {
      yield put(setCustomerFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setCustomerFail(error))
  }
}
function* _setCustomerExpress({ payload: customer }) {
  try {
    const response = yield call(setCustomer, customer)
    if (response === true) {
      yield put(setCustomerExpressSuccess(response))
    } else {
      yield put(setCustomerExpressFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setCustomerExpressFail(error))
  }
}
function* _updateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)
    if (response) {
      yield put(updateCustomerSuccess(response))
    } else {
      yield put(updateCustomerFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(updateCustomerFail(error))
  }
}

function* _updateCustomerExpress({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)
    if (response) {
      yield put(updateCustomerExpressSuccess(response))
    } else {
      yield put(updateCustomerExpressFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(updateCustomerExpressFail(error))
  }
}
function* _delCustomer({ payload: customer }) {
  try {
    const response = yield call(delCustomer, customer)
    if (response === true) {
      yield put(deleteCustomerSuccess(response))
    } else {
      yield put(deleteCustomerFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(deleteCustomerFail(error))
  }
}

function* _selectCustomer({ payload: customer }) {
  try {
    if (customer !== undefined) {
      yield put(selectCustomerSuccess(customer))
    } else {
      yield put(selectCustomerFail(customer))
    }
  } catch (error) {
    yield put(selectCustomerFail(error))
  }
}

function* customerSaga() {
  yield takeEvery(SET_CUSTOMER, _setCustomer)
  yield takeEvery(GET_CUSTOMER_LIST, _getCustomerList)
  yield takeEvery(GET_CUSTOMER_DETAIL, _getCustomerDetail)
  yield takeEvery(UPDATE_CUSTOMER, _updateCustomer)
  yield takeEvery(UPDATE_CUSTOMER_EXPRESS, _updateCustomerExpress)
  yield takeEvery(GET_CUSTOMERS_BY_MERCHANTID, _getCustomerListByMerchantID)
  yield takeEvery(DELETE_CUSTOMER, _delCustomer)
  yield takeEvery(SELECT_CUSTOMER, _selectCustomer)
  yield takeEvery(SET_CUSTOMER_EXPRESS, _setCustomerExpress)
}

export default customerSaga
