import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PAYMENTREQUEST_LIST,
  GET_PAYMENTREQUEST_LIST_REPORT,
  GET_PAYMENTREQUEST_DETAIL,
  GET_PAYMENTREQUEST_DETAIL_PUBLIC,
  SET_DEVICE_FINGERPRINT,
  SET_PAYMENT_REQUEST,
  SET_PAYMENT_METHOD,
  SEND_PAYMENT_REQUEST_EMAIL,
  UPDATE_PAYMNET_REQUEST,
  SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
  SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
  UPDATE_PAYMENT_REQUEST_BOOLEAN,
  GET_USER_PAYMENT_REQUESTS_BY_USERID,
  FILE_UPLOAD,
  SET_PAYMENT_REQUEST_EXPRESS,
  SET_MYFATOORAH_INITIATED_SESSION_DATA,

} from "./actionTypes"
import {
  getPaymentRequestListSuccess,
  getPaymentRequestListFail,
  getPaymentRequestListReportSuccess,
  getPaymentRequestListReportFail,
  getPaymentRequestDetailSuccess,
  getPaymentRequestDetailFail,
  getPaymentRequestDetailPublicSuccess,
  getPaymentRequestDetailPublicFail,
  setDeviceFingerPrintSuccess,
  setDeviceFingerPrintFail,
  setPaymentRequestSuccess,
  setPaymentRequestFail,
  setPaymentMethodSuccess,
  setPaymentMethodFail,
  sendPaymentRequestEmailSuccess,
  sendPaymentRequestEmailFail,
  setPaymentRequestEmailNonExpressSuccess,
  setPaymentRequestEmailNonExpressFail,
  setPaymentRequestEmailExpressSuccess,
  setPaymentRequestEmailExpressFail,
  updatePaymentRequestSuccess,
  updatePaymentRequestFail,
  updatePaymentRequestBooleanSuccess,
  updatePaymentRequestBooleanFalse,
  restartState,
  getPayReqByUserIdSuccess,
  getPayReqByUserIdFail,
  fileUploadSuccess,
  fileUploadFail,
  setPaymentRequestExpressSuccess,
  setPaymentRequestExpressFail,
  setMyfatoorahInitiateSessionDataSuccess,
  setMyfatoorahInitiateSessionDataFail,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getPaymentRequestList,
  getPaymentRequestsDetails,
  getPaymentRequestsDetailsPublic,
  setDeviceFingerPrint,
  setPaymentRequest,
  setPaymentMethod,
  setMyfatoorahInitiateSessionData,
  updatePaymentRequest,
  sendPaymentRequestEmail,
  getPRByUserId,
  getPaymentRequestListReport,
  uploadFileAttachment,
} from "helpers/fakebackend_helper"

function* _getPaymentRequestList({ merchantId }) {
  try {
    const response = yield call(getPaymentRequestList, merchantId)
    if (response) {
      yield put(getPaymentRequestListSuccess(response))
    }
  } catch (error) {
    yield put(getPaymentRequestListFail(error))
  }
  yield put(restartState())
}

function* _getPaymentRequestDetail({ id }) {
  try {
    const response = yield call(getPaymentRequestsDetails, id)

    yield put(getPaymentRequestDetailSuccess(response))
  } catch (error) {
    yield put(getPaymentRequestDetailFail(error))
  }
}

function* _getPaymentRequestDetailPublic({ payload: id }) {
  try {
    const response = yield call(getPaymentRequestsDetailsPublic, id)

    if (
      response === undefined ||
      response?.message === "Payment Request Not Found."
    ) {
      yield put(getPaymentRequestDetailPublicFail("Payment Request Not Found."))
    } else {
      yield put(getPaymentRequestDetailPublicSuccess(response))
    }
    //yield put(getPaymentRequestDetailPublicSuccess(response))
  } catch (error) {
    yield put(getPaymentRequestDetailPublicFail(error))
  }
}

function* _setDeviceFingerPrint({ payload: data }) {
  try {
    const response = yield call(setDeviceFingerPrint, data)
    if (response) {
      yield put(setDeviceFingerPrintSuccess(response))
    } else {
      yield put(setDeviceFingerPrintFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setDeviceFingerPrintFail(error))
  }
}

function* _setPaymentRequest({ payload: paymentRequest }) {
  try {
    const response = yield call(setPaymentRequest, paymentRequest)
    if (response) {
      yield put(setPaymentRequestSuccess(response))
    } else {
      yield put(setPaymentRequestFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestFail(error))
  }
}

function* _setPaymentMethod({ payload: chosenPaymentMethod }) {
  try {
    const response = yield call(setPaymentMethod, chosenPaymentMethod)

    if (
      response?.message === "Payment Request Already Paid." ||
      response?.message === "Payment Request does not exist!" ||
      response?.message === "Inactive Payment Request"
    ) {
      yield put(setPaymentMethodFail(response.message))
    } else {
      yield put(setPaymentMethodSuccess(response))
    }
  } catch (error) {
    yield put(setPaymentMethodFail(error))
  }
}

function* _sendPaymentRequestEmail({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)

    if (response === true) {
      yield put(sendPaymentRequestEmailSuccess(response))
    } else {
      yield put(sendPaymentRequestEmailFail(response))
    }
  } catch (error) {
    yield put(sendPaymentRequestEmailFail(error))
  }
  yield put(restartState())
}

function* _sendPaymentRequestEmailNonExpress({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)
    if (response == true) {
      yield put(setPaymentRequestEmailNonExpressSuccess(response))
    } else {
      yield put(setPaymentRequestEmailNonExpressFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestEmailNonExpressFail(error))
  }
}

function* _sendPaymentRequestEmailExpress({ payload: data }) {
  try {
    const response = yield call(sendPaymentRequestEmail, data)
    if (response == true) {
      yield put(setPaymentRequestEmailExpressSuccess(response))
    } else {
      yield put(setPaymentRequestEmailExpressFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestEmailExpressFail(error))
  }
}

function* _updatePaymentRequestBoolean({ payload: data }) {
  try {
    yield put(updatePaymentRequestBooleanSuccess(data))
  } catch (error) {
    yield put(updatePaymentRequestBooleanFalse(error))
  }
}

function* _updatePaymentRequest({ payload: paymentRequest }) {
  try {
    const response = yield call(updatePaymentRequest, paymentRequest)
    if (response) {
      yield put(updatePaymentRequestSuccess(response))
    } else {
      yield put(updatePaymentRequestFail(response))
    }
  } catch (error) {
    yield put(updatePaymentRequestFail(error))
  }
}

function* _getPayReqByUserId({ payload: data }) {
  try {
    const response = yield call(getPRByUserId, data)
    if (response) {
      yield put(getPayReqByUserIdSuccess(response))
    } else {
      yield put(getPayReqByUserIdFail(response))
    }
  } catch (error) {
    yield put(getPayReqByUserIdFail(error))
  }
}

// function* setSearchValue({payload:data}){
//   try{
//     yield put(setMainSearch(data))
//   }
//   catch(error){

//   }
// }

function* _getPaymentRequestsListReport({ merchantId }) {
  try {
    const response = yield call(getPaymentRequestListReport, merchantId)

    if (response) {
      yield put(getPaymentRequestListReportSuccess(response))
    }
  } catch (error) {
    yield put(getPaymentRequestListReportFail(error))
  }

  yield put(restartState())
}

function* _fileUpload({ payload: files }) {
  try {
    const response = yield call(uploadFileAttachment, files)
    if (response) {
      yield put(fileUploadSuccess(response))
    }
    if (response?.message === "Something Went Wrong") {
      yield put(fileUploadFail("File(s) not attached!"))
    }
    if (
      response?.message ===
      "File type not allowed should be pdf, jpg, png, txt or jpeg"
    ) {
      yield put(fileUploadFail("File(s) not attached!"))
    }
    yield put(restartState())
  } catch (error) {
    yield put(fileUploadFail(error))
  }
}

function* _setPaymentRequestExpress({ payload: paymentRequest }) {
  try {
    const response = yield call(setPaymentRequest, paymentRequest)
    if (response) {
      yield put(setPaymentRequestExpressSuccess(response))
    } else {
      yield put(setPaymentRequestExpressFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setPaymentRequestExpressFail(error))
  }
}


function* _setMyFatoorahInitiatedSessionData({ payload: data }) {
  try {
    const response = yield call(setMyfatoorahInitiateSessionData, data)
    console.log(response)
    if (response) {
      yield put(setMyfatoorahInitiateSessionDataSuccess(response))
    } else {
      yield put(setMyfatoorahInitiateSessionDataFail(response))
    }
    yield put(restartState())
  } catch (error) {
    yield put(setMyfatoorahInitiateSessionDataFail(error))
  }
}

function* paymentrequestsSaga() {
  yield takeEvery(GET_PAYMENTREQUEST_LIST, _getPaymentRequestList)
  yield takeEvery(GET_PAYMENTREQUEST_DETAIL, _getPaymentRequestDetail)
  yield takeEvery(
    GET_PAYMENTREQUEST_DETAIL_PUBLIC,
    _getPaymentRequestDetailPublic
  )
  yield takeEvery(SET_DEVICE_FINGERPRINT, _setDeviceFingerPrint)
  yield takeLatest(SET_PAYMENT_REQUEST, _setPaymentRequest)
  yield takeEvery(SET_PAYMENT_METHOD, _setPaymentMethod)
  yield takeEvery(UPDATE_PAYMNET_REQUEST, _updatePaymentRequest)
  yield takeEvery(SEND_PAYMENT_REQUEST_EMAIL, _sendPaymentRequestEmail)
  yield takeEvery(
    SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS,
    _sendPaymentRequestEmailNonExpress
  )
  yield takeEvery(
    SEND_PAYMENT_REQUEST_EMAIL_EXPRESS,
    _sendPaymentRequestEmailExpress
  )
  yield takeEvery(UPDATE_PAYMENT_REQUEST_BOOLEAN, _updatePaymentRequestBoolean)
  yield takeEvery(GET_USER_PAYMENT_REQUESTS_BY_USERID, _getPayReqByUserId)
  yield takeEvery(GET_PAYMENTREQUEST_LIST_REPORT, _getPaymentRequestsListReport)
  yield takeEvery(FILE_UPLOAD, _fileUpload)
  yield takeEvery(SET_PAYMENT_REQUEST_EXPRESS, _setPaymentRequestExpress)
  yield takeEvery(SET_MYFATOORAH_INITIATED_SESSION_DATA, _setMyFatoorahInitiatedSessionData)
}

export default paymentrequestsSaga
