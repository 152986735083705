import React from "react"
import PaymentMethodButton from './PaymentMethodOptions/PaymentMethodButtons'
import PaymentMethodCardForm from './PaymentMethodOptions/PaymentMethodCardForm'

import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
    Card,
    Col,
    CardBody,
    Row
} from "reactstrap"

const PaymentMethod = (props) => {

    const { paymentRequestDetails, 
        tokenizationDetails, 
        retrievedTokenDetailsCustom, 
        retrievedTokenDetailsRedirect,
        myfatoorahInitiateSession } = props






    return (
        <>



            <div className="d-flex justify-content-center" >
                <Col lg="8" style={{ "marginTop": "0.5rem" }}>
                        <Row>
                        {/* <div style={{ "textAlign": "center" }}>  <small><b>  {props.t("SELECT PAYMENT METHOD")}</b></small>  </div> */}
                        {/* <PaymentMethodButton paymentRequestDetails={paymentRequestDetails} /> */}
                        </Row>
                        <Row>
                        <PaymentMethodCardForm
                            paymentRequestDetails={paymentRequestDetails}
                            tokenizationDetails={tokenizationDetails}
                            retrievedTokenDetailsCustom={retrievedTokenDetailsCustom}
                            retrievedTokenDetailsRedirect={retrievedTokenDetailsRedirect}
                            myfatoorahInitiateSession={myfatoorahInitiateSession}
                        />

                    </Row>
                </Col>


                {/* <Col lg="2" style={{ "marginTop": "0.5rem" }}></Col> */}

            </div>
        </>
    )
}


PaymentMethod.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(PaymentMethod))


// IMPORTANT NOTE:

// INACTIVE LINK 
