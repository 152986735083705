import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  UPDATE_PAYMENT_HISTORY,
  SEND_NOTIFICATION_EMAIL,
} from "./actionTypes"

import {
  updatePaymentHistorySuccess,
  updatePaymentHistoryFail,
  sendNotificationEmailSuccess,
  sendNotificationEmailFail,
  restartState
} from "./actions"

//Include Both Helper File with needed methods
import {
  updatePaymentHistory,
  sendNotificationEmail,
} from "helpers/fakebackend_helper"



function* _updatePaymentHistory ({payload:data}){
  try{
  
    const response = yield call(updatePaymentHistory,data)
    

    if(response){
      yield put(updatePaymentHistorySuccess(response))
    }
    else{
      yield put(updatePaymentHistoryFail(response))
    }
    yield put(restartState())
  }
  catch(error){
    yield put(updatePaymentHistoryFail(error))
  }
}

function* _sendNotificationEmail ({payload:data}){
  try{
  
    const response = yield call(sendNotificationEmail,data)
    

    if(response){
      yield put(sendNotificationEmailSuccess(response))
    }
    else{
      yield put(sendNotificationEmailFail(response))
    }
    yield put(restartState())
  }
  catch(error){
    yield put(sendNotificationEmailFail(error))
  }
}


function* paymentHistorySaga() {
  yield takeEvery(UPDATE_PAYMENT_HISTORY, _updatePaymentHistory)
  yield takeEvery(SEND_NOTIFICATION_EMAIL, _sendNotificationEmail)

}

export default paymentHistorySaga
