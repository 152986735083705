import {

    UPDATE_PAYMENT_HISTORY,
    UPDATE_PAYMENT_HISTORY_SUCCESS,
    UPDATE_PAYMENT_HISTORY_FAIL,
    SEND_NOTIFICATION_EMAIL,
    SEND_NOTIFICATION_EMAIL_SUCCESS,
    SEND_NOTIFICATION_EMAIL_FAIL,
    RESTART_STATE
} from "./actionTypes"

const INIT_STATE = {
    loading: false,
    loadingEmail: false,
    paymentHistoryList: [],
    paymentHistory: {},
    notificationEmail: {}
}



const paymentHistory = (state = INIT_STATE, action) => {
    switch (action.type) {

        case UPDATE_PAYMENT_HISTORY:
            state = {
                ...state,
                paymentHistory: action.payload,

                loading: true
            }
            break
        case UPDATE_PAYMENT_HISTORY_SUCCESS:
            state = {
                ...state,
                paymentHistory: action.payload,
                loading: false
            }
            break
        case UPDATE_PAYMENT_HISTORY_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false
            }
            break
        case SEND_NOTIFICATION_EMAIL:
            state = {
                ...state,
                notificationEmail: action.payload,
                loadingEmail: true
            }
            break
        case SEND_NOTIFICATION_EMAIL_SUCCESS:
            state = {
                ...state,
                notificationEmail: action.payload,
                loadingEmail: false
            }
            break
        case SEND_NOTIFICATION_EMAIL_FAIL:
            state = {
                ...state,
                errorEmail: action.payload,
                loadingEmail: false
            }
            break

        case RESTART_STATE:
            state = {
                loadingEmail: false,
                notificationEmail: {}
            }
            break
        default:
            return state
    }
    return state
}

export default paymentHistory
