import React from "react"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

export const OfficeIds = props => {
  const {
    officeId,
    handleOfficeIdChange,
    handleOfficeIdDelete,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
  } = props

  const inputOfficeId = "OfficeId" + index

  function handleChange(changes) {
    handleOfficeIdChange(officeId.id, { ...officeId, ...changes })
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="5">
            <FormGroup>
              <input
                name={inputOfficeId}
                value={officeId.OfficeId}
                onChange={e => handleChange({ OfficeId: e.target.value })}
                type="text"
                className="form-control"
                placeholder="Enter an OfficeId"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />

              <small className="color-danger">
                {errors[inputOfficeId] && errors[inputOfficeId].message}
              </small>
            </FormGroup>
            <Input
              hidden
              disabled
              value={officeId.Date}
              name="Date"
              type="text"
              className="form-control"
              placeholder={" "}
            />
          </Col>
          <Col lg="2">
            <FormGroup>
              <Button
                onClick={e => handleOfficeIdDelete(officeId.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                Delete
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default OfficeIds
