import { call, put, takeEvery, takeLatest,take } from "redux-saga/effects"
import {
  setItem,
  getItemDetail,
  updateItem
} from "helpers/fakebackend_helper"

import { 
  SET_ITEM,
  GET_ITEM_DETAIL,
  UPDATE_ITEM
 } from "./actionTypes"
import {
  setItemSuccess,
  setItemFail,
  getItemDetailSuccess,
  getItemDetailFail,
  updateItemSuccess,
  updateItemFail,
  resetState
} from "./actions"
function* _setItem({payload:items}){
    try{
       const response= yield call(setItem,items)
      if(response){
        yield put(setItemSuccess(response))
      }
      else{
        yield put(setItemFail(response))
      }
      yield put(resetState())
    }
    catch(error){
      yield put(setItemFail(response))
    }
}
function* _getItemDetail({payload:items}){
  try{
    const response= yield call(getItemDetail,items)
   if(response){
     yield put(getItemDetailSuccess(response))
   }
   else{
    yield put(getItemDetailFail(response))
   }
 }
 catch(error){
  yield put(getItemDetailFail(error))
 }
}

function* _updateItem({payload:items}){
  try{
    const response= yield call(updateItem,items)
   if(response){
     yield put(updateItemSuccess(response))
   }
   else{
    yield put(updateItemFail(response))
   }
 }
 catch(error){
  yield put(updateItemFail(response))
 }
}

function* itemSaga() {
    yield takeEvery(SET_ITEM, _setItem)
    yield takeEvery(GET_ITEM_DETAIL,_getItemDetail)
    yield takeEvery(UPDATE_ITEM,_updateItem)

  }
  
  export default itemSaga
  