import React, { useContext, useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
  Col,
  Button,
  Row
} from "reactstrap"
import { PaymentMethodContext } from '../index'

const DebitCard = (props) => {

  const {retrievedTokenDetailsRedirect} = props
  const { handleDebitCard } = useContext(PaymentMethodContext)

  const [retreivedToken, setRetreivedToken] = useState(retrievedTokenDetailsRedirect)

  useEffect(() => {
    if (retrievedTokenDetailsRedirect)
      setRetreivedToken(retrievedTokenDetailsRedirect)
  }, [retrievedTokenDetailsRedirect])


  return (
    <>

      <Col lg="12" style={{ "marginTop": "1rem" }}>

          <form action={retreivedToken.formApi}  method ='post' name='frm' id="frm">
          <input type='hidden' name='signature' value={retreivedToken.signature}  />
          <input type='hidden' name='command' value={retreivedToken.command} />
          <input type='hidden' name='access_code' value={retreivedToken.access_code} /> 
          <input type='hidden' name='merchant_identifier' value={retreivedToken.merchant_identifier} /> 
          <input type='hidden' name='merchant_reference' value={retreivedToken.merchant_reference} /> 
          <input type='hidden' name='language' value={retreivedToken.language}/>
          <input type='hidden' name='customer_email' value={retreivedToken.customer_email} />
          <input type='hidden' name='customer_ip' value={retreivedToken.customer_ip} />
          <input type='hidden' name='payment_option' value={retreivedToken.payment_option} />
          <input type='hidden' name='amount' value={retreivedToken.amount} />
          <input type='hidden' name='currency' value={retreivedToken.currency} />
      
       

          <Button className="paymethod"
            // style={{ width: "100%", padding: "15px", "fontSize": "1rem", background: "#34C38F", border: "none" }}
            // onClick={handleDebitCard}
            type="submit"
            >
            <img
              src="../qpay.png"
              alt=""
              className="img-fluid img_qpay"

            />
            {" "} {props.t("Debit Card")}</Button>
            </form>
      </Col>
    </>
  )


}

DebitCard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DebitCard))
