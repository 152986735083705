import React, { useContext } from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
  Col,
  Button
} from "reactstrap"

import { PaymentMethodContext } from '../index'

const OoredooButton = (props) => {

  const { handleOoredoo } = useContext(PaymentMethodContext)
  return (
    <>
      <Col lg="12" >
        <Col style={{ "marginTop": "1rem" }}></Col>
        <Button className="paymethod"
          onClick={handleOoredoo}>
           
           
             <img
            src="../omm.png"
            alt=""
            className="img-fluid img_omm"
        
          />
            
            
            {" "}{props.t("Ooredoo Money")}</Button>
      </Col>

     
    </>
  )


}

OoredooButton.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}


export default withRouter(withTranslation()(OoredooButton))
