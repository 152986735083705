import React, { useState, useEffect } from 'react'
import Layout from "./ProfileLayout"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Row, Container } from 'reactstrap'
import { useDispatch } from "react-redux"
import { connect } from 'react-redux'
import { getUserDetail, getUserLogsById, getPayReqByUserId, getMerchantDetail } from "store/actions"

import AuthUser from "components/AuthUser/AuthUser"
export const ValuesContext = React.createContext()

function index(props) {

    let userId = ""
    const [userLogin, setUserLogin] = useState(AuthUser())
    const [user, setUser] = useState()
    const [logs, setLogs] = useState()
    const [prList, setPRList] = useState()
    const [merchantDetail, setMerchantDetail] = useState()
    const dispatch = useDispatch();

    if (props.match && props.match.params && props.match.params.id) {
        userId = props.match.params.id

    }
    else {
        userId = userLogin.userId
    }
    useEffect(() => {
        if (userId !== undefined) {
            dispatch(getUserDetail(userId))
            dispatch(getUserLogsById(userId))
            dispatch(getPayReqByUserId(userId))
            dispatch(getMerchantDetail(userLogin?.merchantId))
        }
    }, [])

    useEffect(() => {
        if (props.userDetails !== undefined) {
            setUser(props.userDetails)
        }
    }, [props.userDetails])

    useEffect(() => {
    }, [user])

    useEffect(() => {
        if (props.userLogs !== undefined) {
            setLogs(props.userLogs)
        }
    }, [props.userLogs])

    useEffect(() => {
    }, [logs])

    useEffect(() => {
        if (props.prList !== undefined) {
            setPRList(props.prList)
        }
    }, [props.prList])

    useEffect(() => {
    }, [prList])

    useEffect(() => {
        if (props.merchantDetail !== undefined) {
            setMerchantDetail(props.merchantDetail)
        }
    }, [props.merchantDetail])

    return (


        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {props.match && props.match.params && props.match.params.id ?
                        <Breadcrumbs title="USER" breadcrumbItem="PROFILE" />
                        :  <Breadcrumbs breadcrumbItem=" DASHBOARD" />}
                    <Row>
                        <Col >
                            <ValuesContext.Provider value={{ user, logs, prList, merchantDetail }}>
                                <Layout />
                            </ValuesContext.Provider>
                        </Col>

                    </Row>



                </Container>
            </div>
        </React.Fragment>

    )
}
const mapStateToProps = state => {
    return {
        loading: state.Users.loading,
        userDetails: state.Users.user,
        userLogs: state.Logs.logs,
        prList: state.paymentRequests.prList,
        merchantDetail: state.Merchants.merchantDetail,
    }
}
export default connect(mapStateToProps)(index)
