import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePws from "../pages/Authentication/ChangePassword"
import SetPassowrd from "../pages/Authentication/SetPassowrd"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Payment Request
import PaymentRequestList from "../pages/PaymentRequestList/index"
import PaymentRequestOverview from "../pages/PaymentRequestOverview/index"
import PaymentRequestCreate from "../pages/PaymentRequestCreate/index"
import PaymentRequestCreateV2 from "../pages/PaymentRequestCreate_V2/index"
import PaymentRequestExpress from "../pages/PaymentRequestExpress/index"
// import PaymentRequestInvoice from "../pages/PaymentRequestInvoice/index";tess
import PaymentRequestInvoice from "../pages/PaymentRequestInvoice_Aps/index"

//Payment Request Archived
import PaymentRequestArchived from "../pages/PaymentRequestListArchived/index"
//User Archived
import UserArcvhiced from "../pages/UserListArchived/index"
//Customer
import CustomerPage from "../pages/Customers/index"
//Customer Archived
import CusomterArchived from "../pages/CustomerArchived/index"

//ResponsePage
import PaymentResponsePage from "../pages/PaymentResponsePage/index"

//Merchant
import MerchantCreate from "../pages/MerchantCreate/index"
import CustomerUpdatePopUp from "../pages/Customers/CustomerAddPopUp"
import MerchantOverview from "../pages/MerchantOverview/index"
import MerchantList from "../pages/MerchantList/index"
import MerchantCreateExternal from "../pages/MerchantCreateExternal/index"
import MerhcantPDF from "../pages/MerchantCreateExternal/MerhantPDF"

import UserList from "../pages/UserList/index"
import Profile from "../pages/UserProfile/index"
import Page404 from "../components/Utility/Pages404"
import Page500 from "../components/Utility/Pages500"
import Maintenance from "../components/Utility/Maintenance"
import ComingSoon from "../components/Utility/ComingSoon"
import PaymentGateway from "../pages/PaymentGateways/index"
import PayGateOverview from "../pages/PaymentGateways/PayGateOverview/PayGateOverview"
//LOGS
import UserActivities from "../pages/LogsUserActivites/index"
import EmailNotifications from "../pages/LogsEmailNotifications/index"
import TransactionUpdate from "../pages/LogsTransactionUpdate/index"
import ErrorLogs from "../pages/LogsError/index"
import SessionTimeout from "../components/SessionTimeout/index"
import PlacerErrorLogs from "../pages/LogsErrorProjectPlacer/index"
import PlacerExecutionLogs from "../pages/LogsExecutionProjectPlacer/index"
import ParserErrorLogs from "../pages/LogsErrorCentralParser/index"
import ParserExecutionLogs from "../pages/LogsExecutionCentralParser/index"
import SyncCheckerExecutionLogs from "../pages/LogsExecutionSyncChecker/index"
import SyncCheckerErrorLogs from "../pages/LogsErrorSyncChecker/index"
//Help
import UserGuide from "../pages/Help/UserGuide/index"
import VideoTutorials from "../pages/Help/VideoTutorial/index"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/paymentrequest/List", component: PaymentRequestList },
  { path: "/paymentrequest/Create", component: PaymentRequestCreateV2 },
  { path: "/paymentrequest/Express", component: PaymentRequestExpress },
  { path: "/paymentrequest/Overview/:id", component: PaymentRequestOverview },
  { path: "/customers", component: CustomerPage },
  { path: "/merchant/create", component: MerchantCreate },
  { path: "/customers/update", component: CustomerUpdatePopUp },
  { path: "/users/list", component: UserList },
  { path: "/merchant/overview/:id", component: MerchantOverview },
  { path: "/merchant/list/", component: MerchantList },
  { path: "/profile/:id", component: Profile },
  { path: "/logs/user/activity", component: UserActivities },
  { path: "/logs/email/notification", component: EmailNotifications },
  { path: "/logs/error", component: ErrorLogs },
  { path: "/logs/transactionsupdate", component: TransactionUpdate },
  { path: "/paymentgateway", component: PaymentGateway },
  { path: "/paymentgateway/overview/:id", component: PayGateOverview },
  { path: "/help/userguide", component: UserGuide },
  { path: "/help/videotutorials", component: VideoTutorials },
  { path: "/sessiontimeout", component: SessionTimeout },
  { path: "/logs/placer/error", component: PlacerErrorLogs },
  { path: "/logs/placer/execution", component: PlacerExecutionLogs },
  { path: "/logs/parser/error", component: ParserErrorLogs },
  { path: "/logs/parser/execution", component: ParserExecutionLogs },
  { path: "/logs/syncchecker/execution", component: SyncCheckerExecutionLogs },
  { path: "/logs/syncchecker/error", component: SyncCheckerErrorLogs },
  { path: "/paymentrequest/archived", component: PaymentRequestArchived },
  { path: "/users/archived", component: UserArcvhiced },
  { path: "/customers/archived", component: CusomterArchived },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { component: () => <Redirect to="/page-404" /> },
]

const authRoutes = [
  // { path: "/logout", component: Logout },
  // { path: "/login", component: Login },
  // { path: "/password/forgot", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/paymentresult/:trackId", component: PaymentResponsePage },
  // { path: "/invoice/:id", component: PaymentRequestInvoice },//tess
  // { path: "/invoice/:id", component: PaymentRequestInvoice },
  // { path: "/password/change", component: ChangePws },
  // { path: "/password/set/:email/:token", component: SetPassowrd },
  // { path: "/page-404", component: Page404 },
  { path: "/maintenance", component: Maintenance },
  // { path: "/comingsoon", component: ComingSoon },
  // { path: "/page-500", component: Page500 },
  // { path: "/merchant/register", component: MerchantCreateExternal },
  // { path: "/merchant/pdf/:id", component: MerhcantPDF },

  // this route should be at the end of all other routes
]

export { userRoutes, authRoutes }
