import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import { ToastContainer } from 'react-toastify';
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "components/Common/Breadcrumb"
import SweetAlert from "react-bootstrap-sweetalert"
import PaymentRequestDetails from './PaymentRequestDetails'
import PaymentHistory from './PaymentHistory'
import Activities from './PaymentRequestActivities'
import PaymentRequestItems from './PaymentRequestItems'
import Actions from './Actions'
import { getPaymentRequestDetail, sendPaymentRequestEmail } from "store/actions"
import LoadingSpinnerThreeDots from '../../helpers/commonhelpers/loadingSpinner'
export const PaymentActionsContext = React.createContext()

toastr.options = {
  positionClass: "toast-top-right",
  closeButton: false,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 300,

}

const PaymentRequestOverview = props => {
  const {
    paymentRequestDetail,
    loading,
    loadingEmail,
    match: { params },
    onGetPaymentRequestDetail,

  } = props



  const [isLoaded, setIsLoaded] = useState(loading);
  const [isLoadedEmail, setIsLoadedEmail] = useState(loading);
  const [isEmailSuccess, setIsEmailSuccess] = useState(null);

  const dispatch = useDispatch()

  useEffect(() => {
    if (params && params.id) {
      onGetPaymentRequestDetail(params.id)

    } else {
      // onGetPaymentRequestDetail(0) //remove this after full integration
    }

  }, [params, onGetPaymentRequestDetail])

  useEffect(() => {
    setIsLoaded(loading)

  }, [loading])

  useEffect(() => {

    if (loadingEmail && loadingEmail === true) {

      toastr.options = {
        timeOut: 5000
      }
      toastr.success("", "Email Success")
    }

    if (loadingEmail === undefined) {

      toastr.options = {
        timeOut: 1000

      }
      toastr.info("", "Email Sending")
    }

    if (loadingEmail === false) {

      toastr.options = {
        timeOut: 5000
      }
      toastr.error("", "Opps Email Not Sent")
    }

  }, [loadingEmail])



  function handleSendPaymentLinkEmail(recipient) {

    var data = {
      id: paymentRequestDetail?.paymentRequestDetails?._id,
      recipient: paymentRequestDetail?.paymentRequestDetails?.customerDetails.email,
      actiontype: "Resending Payment Request"
    }


    if (!isEmpty(recipient) && recipient !== "") {
      data.recipient = recipient
      if (paymentRequestDetail?.paymentRequestDetails?.customerDetails.email !== recipient) {
        data.actiontype = "Resending Payment Request (New Email)"
      }
    }


    if (data.id) {
      dispatch(sendPaymentRequestEmail(data))

    }
  }

  function setEmailStatus(status) {

    setIsEmailSuccess(status)
  }


  function alertEmailMessage() {


    if (isEmailSuccess) {

      return (<SweetAlert
        title="Email Resent Successfully"
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setIsLoadedEmail(false)
        }}
        onCancel={() => {
          setIsLoadedEmail(false)
        }}
      > </SweetAlert>
      )

    } else {

      return (<SweetAlert
        title="Something went wrong. Email Not Sent"
        error

        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setIsLoadedEmail(false)
        }}
        onCancel={() => {
          setIsLoadedEmail(false)
        }}> </SweetAlert>
      )

    }
  }


  const PaymentActionsContextValue = {

    handleSendPaymentLinkEmail

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Breadcrumbs title="Payment Request List " breadcrumbItem=" Overview" />

          {isLoaded ?
            <LoadingSpinnerThreeDots />
            :
            !isEmpty(props.paymentRequestDetail) && (
              <>
                <Row>
                  <Col lg="12">

                    <PaymentActionsContext.Provider value={PaymentActionsContextValue}>
                      <Actions
                        paymentrequestDetails={paymentRequestDetail?.paymentRequestDetails} isEmailLoaded={isLoadedEmail} />
                    </PaymentActionsContext.Provider>
                  </Col>

                </Row>
                <Row>
                  <Col lg="8">
                    <PaymentRequestDetails paymentrequestDetails={paymentRequestDetail?.paymentRequestDetails}
                      userDetails={paymentRequestDetail?.userDetails}
                    />
                  </Col>

                  <Col lg="4">
                    <Activities userLogs={paymentRequestDetail?.userLogs} />

                  </Col>

                </Row>

                <Row>
                  <Col lg="8">
                    <PaymentHistory paymentHistoryDetails={paymentRequestDetail?.paymentHistoryDetails} />
                  </Col>

                  <Col lg="4">
                    <PaymentRequestItems source={paymentRequestDetail?.paymentRequestDetails?.itemSource}
                      purchasedItems={paymentRequestDetail?.purchasedItems} />
                  </Col>


                </Row>
              </>
            )

          }
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />


      {/* {EmailAlert(emailStatus,setEmailStatus)} */}

      {/* {isEmailSuccess===true ?
           <SweetAlert
           title="Email Resent Successfully"
           success
           confirmBtnBsStyle="success"
           cancelBtnBsStyle="danger"
           onConfirm={() => {
            setIsEmailSuccess(null)
           }}
          
         > </SweetAlert>
        :
       null
      }


      {isEmailSuccess===false ?
            <SweetAlert
            title="Something went wrong. Email Not Sent"
            error
  
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setIsEmailSuccess(null)
            }}
           > </SweetAlert>
        :
       null
      } */}



    </React.Fragment>
  )
}

PaymentRequestOverview.propTypes = {
  paymentRequestDetail: PropTypes.object,
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool,
  match: PropTypes.object,
  onGetPaymentRequestDetail: PropTypes.func,
}

const mapStateToProps = pr => (
  {
    paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
    loading: pr.paymentRequests.loading,
    loadingEmail: pr.paymentRequests.loadingEmail,
  })

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestDetail: id => dispatch(getPaymentRequestDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentRequestOverview))
