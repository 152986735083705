import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  Modal,
  Container,
  Card,
  CardBody,
  ModalBody,
  Label,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCustomersByMerchantID,
  updateCustomer,
  deleteCustomer,
  getCustomerList,
  getMerchantList,
  getCountryCode,
  patchCustomerArchive,
} from "../../store/actions.js"
import { useDispatch } from "react-redux"
import { IsEmpty } from "react-lodash"
import { connect } from "react-redux"
import CustomerColumns from "./CustomerColumns"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import CustomerAddPopUp from "./CustomerAddPopUp"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AuthUser from "components/AuthUser/AuthUser"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import { ToastContainer } from "react-toastify"
import CustomerUpdatePopUp from "./CustomerUpdatePopUp"
import { CSVLink } from "react-csv"

import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
const headers = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Mobile", key: "mobile" },
  { label: "Registration Date", key: "registrationDate" },
  { label: "Type", key: "type" },
  { label: "Merchant Id", key: "merchantID" },
  { label: "IsActive", key: "isActive" },
]

function index(props) {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const { ExportCSVButton } = CSVExport
  const [{ modal_large, step, customerDetails }, setPaymentRequest] = useState({
    MobilePlus: "+974",
    Name: "",
    Email: "",
    Mobile: "",
    SearchCustomer: "",
    Type: "New",
    modal_large: false,
    step: 0,
    newCustomer: false,
  })
  let form = null
  let modalHeader = ""
  const [customerList, setCustomerList] = useState([])
  const [loading, setLoading] = useState(props.loading)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_activate, setconfirm_alert_activate] = useState(false)
  const [confirm_alert_delete, setconfirm_alert_delete] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [newCustomer, setNewCustomer] = useState("")
  const [updateCustomerDetails, setUpdateCustomer] = useState("")
  const [merchantList, setMerchantList] = useState([])
  const [customerStatus, setCustomerStatus] = useState(false)
  const [userPassowrd, setUserPassowrd] = useState("")
  const [countryCodes, setCountryCodes] = useState([])
  const [customerDownload, setCustomerDownload] = useState([])
  const [searchResult, setSearchResult] = useState([])
  let searchText = ""
  const pageOptions = {
    sizePerPage: 10,
    totalSize: customerList.length, // replace later with size(customerList),
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChange()
    },
  }
  const { SearchBar } = Search
  //Use Effects--------------------------------------------------------------------------------------------
  //Use Effect for getting customer list with Merchant ID
  useEffect(() => {
    UserRole()
    dispatch(getCountryCode())
  }, [])

  useEffect(() => {}, [props.loading])
  //Use Effect for setting customerList with props
  useEffect(() => {
    setLoading(props.loading)
    setCustomerList(props.customersList)
  }, [props.customersList, props.loading])

  useEffect(() => {
    if (props.merchantList !== undefined) {
      setMerchantList(props.merchantList)
    }
  }, [props.merchantList])

  useEffect(() => {
    if (
      user.role === "Admin" &&
      customerList?.length !== 0 &&
      searchResult?.length === 0
    ) {
      const usersDownloadList = new Array()
      customerList?.map((c, index) => {
        const userDownload = {
          Name: c.name,
          Email: c.email,
          Mobile: c.mobile.replace("+", ""),
          Type: c.type,
          RegistrationDate: dateFormatString(c.registrationDate),
          Active: c.isActive,
        }
        usersDownloadList.push(userDownload)
      })

      //console.log(usersDownloadList)
      setCustomerDownload(usersDownloadList)
    }
    if (
      user.role === "Admin" &&
      customerList?.length !== 0 &&
      searchResult?.length !== 0
    ) {
      const usersDownloadList = new Array()
      searchResult?.map((c, index) => {
        const userDownload = {
          Name: c.name,
          Email: c.email,
          Mobile: c.mobile.replace("+", ""),
          Type: c.type,
          RegistrationDate: dateFormatString(c.registrationDate),
          Active: c.isActive,
        }
        usersDownloadList.push(userDownload)
      })

      //console.log(usersDownloadList)
      setCustomerDownload(usersDownloadList)
    }
  }, [customerList, searchResult])

  useEffect(() => {
    //console.log(merchantList)
    if (
      user.role === "SuperAdmin" &&
      merchantList?.length !== 0 &&
      customerList?.length !== 0 &&
      searchResult?.length === 0
    ) {
      const usersDownloadList = new Array()
      customerList.map((c, i) => {
        return (
          <>
            {merchantList.map((m, index) => {
              if (m.merchantId === c.merchantID) {
                const userDownload = {
                  MerchantId: m.merchantId,
                  MerchantName: m.merchantName,
                  Name: c.name,
                  Email: c.email,
                  Mobile: c.mobile.replace("+", ""),
                  Type: c.type,
                  RegistrationDate: dateFormatString(c.registrationDate),
                  Active: c.isActive,
                }
                usersDownloadList.push(userDownload)
              }
            })}
          </>
        )
      })
      //console.log(usersDownloadList)
      setCustomerDownload(usersDownloadList)
    }
    if (
      user.role === "SuperAdmin" &&
      merchantList?.length !== 0 &&
      customerList?.length !== 0 &&
      searchResult?.length !== 0
    ) {
      const usersDownloadList = new Array()
      searchResult.map((c, i) => {
        return (
          <>
            {merchantList.map((m, index) => {
              if (m.merchantId === c.merchantID) {
                const userDownload = {
                  MerchantId: m.merchantId,
                  MerchantName: m.merchantName,
                  Name: c.name,
                  Email: c.email,
                  Mobile: c.mobile.replace("+", ""),
                  Type: c.type,
                  RegistrationDate: dateFormatString(c.registrationDate),
                  Active: c.isActive,
                }
                usersDownloadList.push(userDownload)
              }
            })}
          </>
        )
      })
      //console.log(usersDownloadList)
      setCustomerDownload(usersDownloadList)
    }
  }, [merchantList, searchResult])

  useEffect(() => {
    if (newCustomer !== "") {
      if (newCustomer === true) {
        UserRole()
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("Customer has been created.")
        setNewCustomer("")
      }
      if (newCustomer === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("Customer is not created.")
        setNewCustomer("")
      }
    }
  }, [newCustomer, props.loading])

  useEffect(() => {
    if (props.newCustomer !== undefined) {
      if (props.newCustomer === true) {
        setNewCustomer(true)
      } else {
        setNewCustomer(false)
      }
    }
  }, [props.newCustomer])
  useEffect(() => {
    if (updateCustomerDetails !== undefined) {
      if (updateCustomerDetails === true) {
        UserRole()
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("Customer has been updated.")
        setUpdateCustomer("")
      }
      if (updateCustomerDetails === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("Customer is not updated.")
        setUpdateCustomer("")
      }
    }
  }, [updateCustomerDetails, props.loading])

  useEffect(() => {
    if (props.cusotmerUpdate !== undefined && customerStatus === false) {
      if (props.cusotmerUpdate === true) {
        setUpdateCustomer(true)
      } else {
        setUpdateCustomer(false)
      }
    }
  }, [props.cusotmerUpdate])

  useEffect(() => {}, [customerDetails])

  useEffect(() => {
    if (step === 1) {
      tog_large()
    }
  }, [step])

  useEffect(() => {
    if (modal_large === false) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 0,
      }))
    }
  }, [modal_large])

  useEffect(() => {
    if (props.cusotmerUpdate !== undefined && customerStatus === true) {
      if (props.customer.isActive === false) {
        setsuccess_dlg(true)
        setdynamic_title("Deactivated")
        setdynamic_description("Customer has been deactivated.")
        UserRole()
      } else {
        setsuccess_dlg(true)
        setdynamic_title("Activated")
        setdynamic_description("Customer has been activated.")
        UserRole()
      }
      setCustomerStatus(false)
    }
  }, [props.cusotmerUpdate, customerStatus])

  useEffect(() => {
    if (props.customerDeletedResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("Customer has been deleted.")
      UserRole()
    }
    if (props.customerDeletedResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("Customer has not been deleted.")
      UserRole()
    }
  }, [props.customerDeletedResult])

  useEffect(() => {
    if (props.customerArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("Customer has been archived.")
      UserRole()
    }
    if (props.customerArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("Customer has not been archived.")
      UserRole()
    }
  }, [props.customerArchiveResult])
  useEffect(() => {}, [userPassowrd])

  useEffect(() => {
    //console.log(props.countryCodes)
    if (Array.isArray(props.countryCodes) && props.countryCodes?.length !== 0) {
      setCountryCodes(props.countryCodes)
    }
  }, [props.countryCodes])

  useEffect(() => {
    //console.log(countryCodes)
  }, [countryCodes])

  useEffect(() => {}, [customerDownload])
  //Functions--------------------------------------------------------------------------------------------------------
  //Function for setting data state changes for this component and props
  const storeDataOnForm = event => {
    const { name, value } = event.target
    setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
  }

  const UserRole = () => {
    if (user.role !== "SuperAdmin") {
      const data = {
        type: "all",
        merchantId: user.merchantId,
      }
      setLoading(props.loading)
      dispatch(getCustomersByMerchantID(data))
    }
    if (user.role === "SuperAdmin") {
      dispatch(getCustomerList())
      dispatch(getMerchantList())
    }
  }

  //Function for opening modal
  function tog_large() {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      modal_large: !modal_large,
    }))
    removeBodyCss()
  }

  //Function for closing the modal
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  //Function for click customer in row event
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        customerDetails: row,
      }))
    },
  }
  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setCustomerList(
        props.customersList.filter(customer =>
          Object.keys(customer).some(key =>
            customer[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  //TODO:change here
  const handleDeactivateCustomer = () => {
    setconfirm_alert(false)
    setCustomerStatus(true)
    const newCustomerDetails = {
      _id: customerDetails._id,
      Name: customerDetails.name,
      Email: customerDetails.email,
      Mobile: customerDetails.mobile,
      RegistrationDate: customerDetails.registrationDate,
      Type: customerDetails.type,
      MerchantId: customerDetails.merchantId,
      IsActive: false,
    }
    if (newCustomerDetails._id !== undefined) {
      dispatch(updateCustomer(newCustomerDetails))
    }
  }

  const handleActivateCustomer = () => {
    setconfirm_alert_activate(false)
    setCustomerStatus(true)
    const newCustomerDetails = {
      _id: customerDetails._id,
      Name: customerDetails.name,
      Email: customerDetails.email,
      Mobile: customerDetails.mobile,
      RegistrationDate: customerDetails.registrationDate,
      Type: customerDetails.type,
      MerchantId: customerDetails.merchantId,
      IsActive: true,
    }
    if (newCustomerDetails._id !== undefined) {
      dispatch(updateCustomer(newCustomerDetails))
    }
  }

  const handleDeleteCustomer = () => {
    setconfirm_alert_delete(false)

    let deleteCustomerCofirmation = {
      Password: userPassowrd,
      Email: customerDetails._id,
    }

    if (
      deleteCustomerCofirmation.Password !== "" &&
      deleteCustomerCofirmation.Email !== undefined
    ) {
      dispatch(deleteCustomer(deleteCustomerCofirmation))
      setUserPassowrd("")
    }
    if (deleteCustomerCofirmation.Password === "") {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("Customer has not been deleted.")
      UserRole()
    }
  }

  const handleArchiveCustomer = value => {
    //console.log(value)
    if (value !== "" && value !== undefined) {
      dispatch(patchCustomerArchive(value))
    }
  }
  const handleActionTableCustomer = value => {
    if (value === "update") {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 1,
      }))
    }
    if (value === "deactivate") {
      setconfirm_alert(true)
    }
    if (value === "activate") {
      setconfirm_alert_activate(true)
    }

    if (value === "delete") {
      setconfirm_alert_delete(true)
    }
  }

  switch (step) {
    case 0:
      form = (
        <CustomerAddPopUp
          newCustomerAdded
          countryCodes={countryCodes}
          onChange={value => setPaymentRequest(value)}
        />
      )
      modalHeader = (
        <Row className="ml-1 my-3">
          <Label>ADD NEW CUSTOMER</Label>
        </Row>
      )
      break
    case 1:
      form = (
        <CustomerUpdatePopUp
          countryCodes={countryCodes}
          customerDetails={customerDetails}
          onChange={value => setPaymentRequest(value)}
        />
      )
      modalHeader = (
        <Row className="ml-1 my-3">
          <Label>UPDATE CUSTOMER</Label>
        </Row>
      )
      break
    default:
      break
  }

  return (
    <React.Fragment>
      {confirm_alert ? (
        <SweetAlert
          title="Deactivate Customer"
          warning
          showCancel
          confirmButtonText="Confirm"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDeactivateCustomer()
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Deactivate the selected customer.
        </SweetAlert>
      ) : null}

      {confirm_alert_activate ? (
        <SweetAlert
          title="Activate Customer"
          warning
          showCancel
          confirmButtonText="Confirm"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleActivateCustomer()
          }}
          onCancel={() => setconfirm_alert_activate(false)}
        >
          Activate the selected customer.
        </SweetAlert>
      ) : null}
      {confirm_alert_delete ? (
        <SweetAlert
          title="Delete Customer"
          warning
          showCancel
          confirmButtonText="Confirm"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDeleteCustomer()
          }}
          onCancel={() => setconfirm_alert_delete(false)}
        >
          Please enter the password to delete the selected customer.
          <input
            type="password"
            className="form-control"
            value={userPassowrd}
            onChange={e => {
              setUserPassowrd(e.target.value)
            }}
          />
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          timeout={1000}
          success
          showConfirm={false}
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {error_dlg ? (
        <SweetAlert
          timeout={1000}
          error
          showConfirm={false}
          title={dynamic_title}
          onConfirm={() => {
            seterror_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      <div className="page-content ">
        <Container fluid={true}>
          <Breadcrumbs title="MANAGE" breadcrumbItem=" CUSTOMERS" />
          <Modal
            size="lg"
            isOpen={modal_large}
            toggle={() => {
              tog_large()
            }}
          >
            <div className="modal-header">
              {modalHeader}
              <h5 className="modal-title mt-0" id="myLargeModalLabel"></h5>
              <button
                onClick={() => {
                  setPaymentRequest(formRequest => ({
                    ...formRequest,
                    modal_large: false,
                  }))
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span caria-hidden="true" className="color-danger my-auto">
                  {" "}
                  x
                </span>
              </button>
            </div>
            <ModalBody>{form}</ModalBody>
          </Modal>
          <Card>
            <CardBody>
              {loading ? (
                <Row className="spinner-custom mx-auto my-auto pb-3">
                  <LoadingSpinnerThreeDots />
                </Row>
              ) : (
                <IsEmpty
                  value={customerList}
                  yes={() => (
                    <div className="text-sm-right">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                        onClick={() => {
                          setPaymentRequest(formRequest => ({
                            ...formRequest,
                            step: 0,
                          }))
                          tog_large()
                        }}
                      >
                        <i className="mdi mdi-account-multiple-plus mr-1" />
                        New Customer
                      </Button>
                    </div>
                  )}
                  no={() => (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={customerList || []}
                          columns={CustomerColumns(
                            handleActionTableCustomer,
                            merchantList,
                            user,
                            handleArchiveCustomer
                          )}
                          bootstrap4
                          search={{
                            afterSearch: newResult => {
                              setSearchResult(newResult)
                            },
                          }}
                          exportCSV={{
                            fileName: "CustomerList.csv",
                            separator: ",",
                            noAutoBOM: false,
                            exportAll: false,
                            onlyExportFiltered: true,
                          }}
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2  mx-2 ">
                                <Col>
                                  <div className="search-box mr-2 mb-2 d-inline-block ">
                                    <div className="position-relative ">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        className="width-search"
                                        placeholder="Search Customer/Email/Mobile"
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col>
                                  <div className="text-sm-right">
                                    {/* <CSVLink
                                      color="warning"
                                      filename={"CustomersList.csv"}
                                      className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                      data={customerList}
                                      headers={headers}
                                      key="_id"
                                      target="_blank"
                                      separator={"\t"}
                                    >
                                      <i className="bx bx-download label-icon mr-1" />
                                      Download Customers
                                    </CSVLink> */}
                                    {/* <ExportCSVButton
                                      color="warning"
                                      className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                      {...toolkitProps.csvProps}
                                    >
                                      <i className="bx bx-download label-icon mr-1" />
                                      Download Customers
                                    </ExportCSVButton> */}
                                    {customerDownload &&
                                    customerDownload.length !== 0 ? (
                                      <CSVLink
                                        color="warning"
                                        filename={"CustomerList.csv"}
                                        className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                        data={customerDownload}
                                        separator=","
                                        key="_id"
                                        target="_blank"
                                      >
                                        <i className="bx bx-download label-icon mr-1" />
                                        Download Customer
                                      </CSVLink>
                                    ) : null}

                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      onClick={() => {
                                        setPaymentRequest(formRequest => ({
                                          ...formRequest,
                                          step: 0,
                                        }))
                                        tog_large()
                                      }}
                                    >
                                      <i className="mdi mdi-account-multiple-plus mr-1" />
                                      New Customer
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                      bordered={false}
                                      hover={true}
                                      striped={false}
                                      classes={
                                        "table table-centered pointer table-nowrap table-hover"
                                      }
                                      rowEvents={rowEvents}
                                      keyField="_id"
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange(
                                        searchText
                                      )}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                />
              )}
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    cusotmerUpdate: state.Customer.update,
    customer: state.Customer.customer,
    loading: state.Customer.loading,
    customersList: state.Customer.customerList,
    newCustomer: state.Customer.customerCreated,
    customerDeletedResult: state.Customer.customerDeleted,
    merchantList: state.Merchants.merchantList,
    countryCodes: state.Options.countryCodes,
    customerArchiveResult: state.CustomerArchived.customerArchiveResult,
  }
}

export default connect(mapStateToProps)(index)
